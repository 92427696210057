import { FunctionComponent, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { filteredSortedOrders, clamp } from '../../helpers/utils';

import { IOrder, IOrders } from '../../types/IOrders';

import { colours, transitions } from '../../assets/css/variables';
import closeIcon from '../../assets/images/icons/CloseGrey.svg';

import { Button, ButtonType, ButtonIcon } from '../Button/Button';
import { TextTag } from '../TextTag/TextTag';

type TelehealthSearchProps = {
  orders: Array<any>
  setQuery: Function
  setCategory: Function
}


export const TelehealthSearch: FunctionComponent<TelehealthSearchProps> = ({ orders, setQuery, setCategory }) => {
  const [inputValue, setInputValue] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [activeResultIndex, setActiveResultIndex] = useState(-1);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);

  const numResults = 4;

  // use useEffect to add and remove mouse event listeners on mount/unmount
  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  })

  // computed variables
  const topResults = (): IOrders => {
    return filteredSortedOrders(orders, undefined, inputValue).slice(0, numResults);
  }

  // methods
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.target.value;
    setInputValue(currentValue);
    setShowResults(currentValue !== '');
    setActiveResultIndex(-1);
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    setShowResults(inputValue !== '');
    setActiveResultIndex(-1);
  }

  const handleShowResult = (index?: number): void => {
    if (index !== undefined) {
      // filtering for specific result
      const order = topResults()[index];
      const query = `${order.delivery_firstname} ${order.delivery_lastname} ${order.platform_order_no}`;
      setInputValue(query);
      setQuery(query);
      setCategory(order.status_code);
    } else {
      // filtering for all results
      setQuery(inputValue);
    }

    // hide dropdown
    setShowResults(false);
    setActiveResultIndex(-1);
    blurInput();
  }

  const focusInput = (): void => {
    (inputRef.current! as HTMLInputElement).focus();
  }
  
  const blurInput = (): void => {
    (inputRef.current! as HTMLInputElement).blur();
  }
  
  const clearInput = (): void => {
    setInputValue('');
    setQuery('');
    setShowResults(false);
    setActiveResultIndex(-1);
  }

  const incrementActiveResultIndex = (value: number): void => {
    const newIndex = clamp(activeResultIndex + value, -1, topResults().length);
    setActiveResultIndex(newIndex);
  }

  const handleClickOutside = (event: PointerEvent): void => {
    if (
      showResults && inputRef && resultsRef &&
      !(inputRef.current as unknown as HTMLElement).contains(event.target as Node) &&
      !(resultsRef.current as unknown as HTMLElement).contains(event.target as Node)
    ) {
      setShowResults(false);
      setActiveResultIndex(-1);
    }
  }

  const handleKeyDown = (event: KeyboardEvent): void => {
    const hasFocus = inputRef.current === document.activeElement;
    
    if (hasFocus) {
      // requires focus
      switch (event.key) {
        case 'Enter':
          if (activeResultIndex !== -1) handleShowResult(activeResultIndex === 0 ? undefined : activeResultIndex - 1);
          break;
      }

      if (showResults) {
        // requires results showing
        switch (event.key) {
          case 'ArrowUp':
            incrementActiveResultIndex(-1);
            event.preventDefault();
            break;
          case 'ArrowDown':
            incrementActiveResultIndex(1);
            event.preventDefault();
            break;
        }
      }
    } else {
      // requires no focus
      switch (event.key) {
        case '/':
          focusInput();
          event.preventDefault();
          break;
      }
    }

    // always available
    switch (event.key) {
      case 'Escape':
        clearInput();
        blurInput();
        break;
    }
  }

  return (
    <StyledSearch className={`${showResults ? 'open' : 'closed'}`}>
      <div className="Search_bar">
        <input
          type="text"
          placeholder="Search by Patient Name or Number..."
          value={inputValue}
          onChange={onChange}
          onFocus={onFocus}
          ref={inputRef}
        />
        <Button type={ButtonType.Search} icon={ButtonIcon.Search} onClick={handleShowResult}/>
        <img className={`Search_clear ${inputValue === '' ? 'hidden' : ''}`} src={closeIcon} alt="Clear search" onClick={clearInput} draggable="false" />
      </div>
      <div className="Search_results" ref={resultsRef}>
        <div className={`Search_result showAll ${activeResultIndex === 0 ? 'active' : ''}`} onClick={() => handleShowResult()}>
          <p className="bold">{`Show all results for '${inputValue}'`}</p>
        </div>
        {topResults().map((order: IOrder, i: number) => {
          return (
            <div className={`Search_result ${activeResultIndex === i+1 ? 'active' : ''}`} onClick={() => handleShowResult(i)} key={`searchResult-${order.platform_order_no}`}>
              <p className="Search_orderCustomer semibold">
                <span>{order.delivery_firstname} {order.delivery_lastname}</span>
                <span className="divider">|</span>
                <span>{order.platform_order_no}</span>
                <TextTag text={`In ${order.status_code as string === 'incoming' ? 'Incoming Patients' : order.status_code as string === 'in_queue' ? 'Patient Queue' : 'Previous Patients'}`} />
              </p>
              {/* <p className="Search_orderItems medium">
                <span>{order.items.length} {order.items.length === 1 ? 'item' : 'items'}</span>
                <span className="divider">|</span>
                <span>{order.total}</span>
              </p> */}
            </div>
          )
        })}
      </div>
    </StyledSearch>
  );
}

const StyledSearch = styled.div`
  position: relative;
  height: 44px;
  margin: 21px 0 31px 0;

  .Search_bar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    display: flex;

    input {
      flex-grow: 1;
      height: 44px;
      border-radius: 4px;
      background-color: white;
      border: none;
      padding: 12px;
      border: 1px solid transparent;
    }

    .Button_search {
      width: 53px !important;
      height: 44px !important;
      margin-left: 7px;
    }

    .Search_clear {
      position: absolute;
      cursor: pointer;
      right: 70px;
      top: 10px;
      transition: opacity ${transitions.default};

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .Search_results {
    position: absolute;
    width: calc(100% - 60px);
    z-index: 2;
    background-color: white;
    box-shadow: 0 0 5px 0 rgba(159, 159, 159, 0.25);
    top: 34px;
    border-radius: 0 0 6px 6px;
    transition: opacity ${transitions.default};
    overflow: hidden;

    &:hover {
      .Search_result {
        &.active {
          background-color: white;

          .Search_orderItems:after {
            background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
          }

          &:hover {
            background-color: #DADADA;

            .Search_orderItems:after {
              background: linear-gradient(to left, #DADADA, rgba(255, 255, 255, 0));
            }
          }
        }
      }
    }

    .Search_result {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1px 15px;
      cursor: pointer;

      p {
        font-size: 0.6875rem; // 11px
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 11px;
        padding-bottom: 11px;

        span:not(:last-child) {
          margin-right:12px;
        }

        &.Search_orderItems {
          position: relative;
          white-space: nowrap;
          padding-left: 10px;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -20px;
            width: 20px;
            height: 100%;
            background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
          }
        }

        &.Search_orderCustomer {
          flex-grow: 1;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &:hover,
      &.active {
        background-color: #DADADA;

        .Search_orderItems:after {
          background: linear-gradient(to left, #DADADA, rgba(255, 255, 255, 0));
        }
      }

      &.showAll {
        padding-top: 10px;
      }
    }
  }

  &.open {
    .Search_bar {
      input {
        border-bottom: 1px solid ${colours.lightGrey};
        border-top: 1px solid transparent;
      }
    }
  }

  &.closed {
    .Search_results {
      opacity: 0;
      pointer-events: none;
    }
  }
`