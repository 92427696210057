import { Timestamp } from "./Timestamp"

export enum NotificationType {
  Email = 'email',
  Sms = 'sms',
  Push = 'push'
}

export interface INotification {
  id: number,
  created_at: Timestamp
  updated_at: Timestamp
  message: string,
  contact: string,
  notification_type_code: NotificationType,
  provider_sent_at: Timestamp
  provider_ref: string,
  total_cost: number,
  tax: number,
  partner_id: number,
  user_id: number
}