import { FunctionComponent, useState, ChangeEvent } from 'react';
import styled from 'styled-components';

import { IOrder, OrderDeliveryType, OrderStatus } from '../../types/IOrders';
import { IRequestBodyCreateOrderNote, IRequestBodyUpdateOrder } from '../../types/IRequests';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { useDummyData } from '../../context/DummyDataProvider';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { TextArea } from '../TextArea/TextArea';
import { Badge } from '../Badge/Badge';


type EditOrderCategoryModalProps = {
  show: boolean
  order: IOrder
  onClose?: Function
  onRefresh: Function
}

export const EditOrderCategoryModal: FunctionComponent<EditOrderCategoryModalProps> = ({ show, order, onClose, onRefresh }) => {
  const apiHelper: ApiHelper = useApi();
  const dummyData: any = useDummyData();
  
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  // initial values
  const [notes, setNotes] = useState('');

  // computed
  const validChanges = (): boolean => {
    return notes !== '';
  }

  // methods
  const saveChanges = async () => {
    setErrorMessage('');
    await handleSetOrderStatus();
    await handleCreateNote();
  }

  const successDuration = 3000;
  const handleSetOrderStatus = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        dummyData.mutations.setOrderStatus(order.id, OrderStatus.Archived);
        resolve('');
      } else {
        const requestBody: IRequestBodyUpdateOrder = {
          status_code: OrderStatus.Archived
        }
        setIsLoading(true);
        apiHelper.updateOrder(order.id, requestBody).then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error applying manual override: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  }

  const handleCreateNote = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        // dummyData.mutations.setOrderStatus(order.id, orderStatus);
        resolve('');
      } else {
        const requestBody: IRequestBodyCreateOrderNote = {
          note: `Manual override (order archived): ${notes.trim()}`,
          order_id: order.id,
          partner_id: order.partner_id,
          is_visible: true
        }
        setIsLoading(true);
        apiHelper.createOrderNote(requestBody).then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.status === 200) {
            onRefresh();
            setShowSuccess(true);
            setTimeout(() => {
              handleClose();
            }, successDuration);
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error creating override note: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      // reset state
      setNotes('');
      // reset success message
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledEditOrderCategoryModal className="EditOrderCategory">
      <Modal show={show}>
        {!showSuccess ?
          <>
            <Heading heading="What is your reason for this manual override?" />
            <div className="EditOrderCategory_details divider">
              <Badge type={order.delivery_type_code} />
              {(order.delivery_type_code === OrderDeliveryType.Standard || order.delivery_type_code === OrderDeliveryType.Express) && <Badge type={'courier_australiaPost'} />}
              {order.delivery_type_code === OrderDeliveryType.SameDay && <Badge type={'courier_doordash'} />}
              <OrderDetails order={order} address />
            </div>

            <Alert type={AlertType.Important}>
              <p>This action will manually override the order and move it to <span className="extrabold">Closed Orders</span></p>
            </Alert>
            <TextArea id="notes-input" small value={notes} maxLength={512} onChange={(e: ChangeEvent) => setNotes((e.target as HTMLTextAreaElement).value)} />
            {errorMessage &&
              <div style={{ marginTop: '20px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="https://apt-support.freshdesk.com/support/tickets/new" target="_blank" rel="noreferrer">here</a>.</p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} tall text="Save Changes" onClick={saveChanges} loading={isLoading} disabled={notes === ''} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        :
          <>
            <Alert type={AlertType.PositiveSecondary} successModal>
              <p>This order has been moved to <span className="extrabold">Closed Orders &gt; Completed Orders</span></p>
            </Alert>
          </>
        }
      </Modal>
    </StyledEditOrderCategoryModal>
  );
}

const StyledEditOrderCategoryModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .EditOrderCategory_details {
      padding-bottom: 26px;
      margin-bottom: 36px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .Heading {
      margin-bottom: 32px;
    }

    .Alert_important {
      margin-bottom: 31px;
    }

    textarea {
      margin-bottom: 0;
    }

    .Button_primary {
      margin-top: 50px;
      margin-bottom: 23px;
    }
  }
`