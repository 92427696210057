import { FunctionComponent, useState, ChangeEvent, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { boxShadows, transitions } from '../../assets/css/variables';

import { InputField } from '../InputField/InputField';

import closeIcon from '../../assets/images/icons/Close.svg';


type CustomParcelInputProps = {
  values?: Array<number | undefined>,
  onChange?: Function,
  onClose?: Function
}

export const CustomParcelInput: FunctionComponent<CustomParcelInputProps> = ({ values, onChange, onClose }) => {
  const [activeInput, setActiveInput] = useState('');

  const handleChange = (index: number, event: ChangeEvent): void => {
    if (values && onChange) {
      const newValues = values.slice();
      newValues[index] = parseFloat((event.target as HTMLInputElement).value);
      onChange(newValues);
    }
  }

  return (
    <StyledCustomParcelInput className="CustomParcelInput">
      <img src={closeIcon} alt="" className="CustomParcelInput_closeButton button" onClick={onClose as MouseEventHandler} draggable="false" />

      {/* diagram SVG */}
      {/* included inline so that elements can be toggled */}
      <svg width="294" height="232" viewBox="0 0 294 232" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M147 134.493C147 134.124 147.203 133.785 147.528 133.611L257.528 74.7869C258.195 74.4307 259 74.9134 259 75.6688V157.507C259 157.876 258.797 158.215 258.472 158.389L148.472 217.213C147.805 217.569 147 217.087 147 216.331V134.493Z" fill="#E7E7E7"/>
        <path d="M139 134.323C139 133.94 138.782 133.591 138.438 133.424L1.43787 66.7003C0.773631 66.3768 2.5034e-06 66.8605 2.5034e-06 67.5993V149.677C2.5034e-06 150.06 0.218228 150.409 0.56214 150.576L137.562 217.3C138.226 217.623 139 217.14 139 216.401V134.323Z" fill="#E7E7E7"/>
        <path d="M4.77103 61.0114C4.03422 60.6572 4.01067 59.6165 4.7307 59.2294L114.431 0.24101C114.713 0.0895771 115.05 0.0819482 115.338 0.220484L254.229 66.9886C254.966 67.3428 254.989 68.3835 254.269 68.7706L144.569 127.759C144.287 127.91 143.95 127.918 143.662 127.78L4.77103 61.0114Z" fill="#E7E7E7"/>
        <path d="M40.95 220V202.375H44.175V217.275H52.475V220H40.95Z" fill="#BBBBBB"/>
        <path d="M222.85 226L216.625 208.375H219.975L224.775 222.825H223.775L228.95 208.375H231.35L236.15 222.825H235.225L240.25 208.375H243.4L237.125 226H234.45L229.8 212.45H230.35L225.525 226H222.85Z" fill="#BBBBBB"/>
        <path d="M276.95 134V116.375H280.175V123.725H289.2V116.375H292.425V134H289.2V126.4H280.175V134H276.95Z" fill="#BBBBBB"/>
        <g id="length-active" className={activeInput === 'length' ? 'visible' : 'hidden'}>
          <path d="M139 214.626C139 214.243 138.782 213.894 138.438 213.726L1.43787 147.003C0.773636 146.68 0 147.163 0 147.902V149.677C0 150.06 0.218231 150.409 0.562134 150.577L137.562 217.3C138.226 217.623 139 217.14 139 216.401V214.626Z" fill="black"/>
          <path d="M40.95 220V202.375H44.175V217.275H52.475V220H40.95Z" fill="black"/>
        </g>
        <g id="width-active" className={activeInput === 'width' ? 'visible' : 'hidden'}>
          <path d="M147 214.493C147 214.124 147.203 213.785 147.528 213.611L257.528 154.787C258.195 154.431 259 154.913 259 155.669V157.507C259 157.876 258.797 158.215 258.472 158.389L148.472 217.213C147.805 217.569 147 217.086 147 216.331V214.493Z" fill="black"/>
          <path d="M222.85 226L216.625 208.375H219.975L224.775 222.825H223.775L228.95 208.375H231.35L236.15 222.825H235.225L240.25 208.375H243.4L237.125 226H234.45L229.8 212.45H230.35L225.525 226H222.85Z" fill="black"/>
        </g>
        <g id="height-active" className={activeInput === 'height' ? 'visible' : 'hidden'}>
          <path d="M256 76.118C256 75.7393 256.214 75.393 256.553 75.2236L257.553 74.7236C258.218 74.3912 259 74.8747 259 75.618V157.488C259 157.867 258.786 158.213 258.447 158.383L257.447 158.883C256.782 159.215 256 158.732 256 157.988V76.118Z" fill="black"/>
          <path d="M276.95 134V116.375H280.175V123.725H289.2V116.375H292.425V134H289.2V126.4H280.175V134H276.95Z" fill="black"/>
        </g>
      </svg>

      <div className="CustomParcelInput_inputs">
        <div><InputField type="number" regex={/^[\d.]+$/} id="input-length" label="Length (L)" placeholder="cm" min={0} max={105} step="any" value={values ? values[0] : undefined} onChange={(e: ChangeEvent) => handleChange(0, e)} onFocus={() => setActiveInput('length')} onBlur={() => setActiveInput('')}/></div>
        <p className="bold">X</p>
        <div><InputField type="number" regex={/^[\d.]+$/} id="input-width" label="Width (W)" placeholder="cm" min={0} max={105} step="any" value={values ? values[1] : undefined} onChange={(e: ChangeEvent) => handleChange(1, e)} onFocus={() => setActiveInput('width')} onBlur={() => setActiveInput('')}/></div>
        <p className="bold">X</p>
        <div><InputField type="number" regex={/^[\d.]+$/} id="input-height" label="Height (H)" placeholder="cm" min={0} max={105} step="any" value={values ? values[2] : undefined} onChange={(e: ChangeEvent) => handleChange(2, e)} onFocus={() => setActiveInput('height')} onBlur={() => setActiveInput('')}/></div>
      </div>
    </StyledCustomParcelInput>
  );
}

const StyledCustomParcelInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 41px 41px 41px;
  border-radius: 6px;
  border: 1px solid black;
  ${boxShadows.default}

  .CustomParcelInput_closeButton {
    position: absolute;
    padding: 8px;
    top: 11px;
    right: 11px;
  }

  svg {
    g {
      opacity: 1;
      transition: opacity ${transitions.default};

      &.hidden {
        opacity: 0;
      }
    }
  }

  .CustomParcelInput_inputs {
    display: flex;
    align-items: flex-end;
    margin-top: 35px;

    p {
      user-select: none;
      text-align: center;
      color: #999999;
      min-width: 51px;
      margin: 0 0 22px 0;
      font-size: 0.875rem; // 14px
    }
  }
`