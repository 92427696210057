import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { OrderDeliveryType } from '../../types/IOrders';

import expressIcon from '../../assets/images/badges/express.svg';
import standardIcon from '../../assets/images/badges/standard.svg';
import clickcollectIcon from '../../assets/images/badges/clickcollect.svg';
import samedayIcon from '../../assets/images/badges/sameday.svg';
import customIcon from '../../assets/images/badges/custom.svg';

import archivedIcon from '../../assets/images/badges/archived.svg';

import ausPostIcon from '../../assets/images/badges/auspost.svg';
import doorDashIcon from '../../assets/images/badges/doordash.svg';

import expressConsultIcon from '../../assets/images/badges/telehealth_express_consult.svg';
import scriptRenewalIcon from '../../assets/images/badges/telehealth_script_renewal.svg';

import inStorePrescriptionIcon from '../../assets/images/badges/scriptQueue_inStore.svg';

export type BadgeType = OrderDeliveryType
  | 'order_archived'
  | 'courier_australiaPost'
  | 'courier_australiaPost_pickup'
  | 'courier_australiaPost_dropoff'
  | 'courier_doordash'
  | 'courier_doordash_pickup'
  | 'telehealth_express_consultation'
  | 'telehealth_script_renewal'
  | 'scriptQueue_inStore'

type BadgeProps = {
  type: BadgeType,
  small?: boolean,
  iconOnly?: boolean,
}

const badgeContent = [
  {
    id: OrderDeliveryType.Express,
    label: 'Express Delivery',
    icon: expressIcon
  },
  {
    id: OrderDeliveryType.Standard,
    label: 'Standard Delivery',
    icon: standardIcon
  },
  {
    id: OrderDeliveryType.Pickup,
    label: 'Click & Collect',
    icon: clickcollectIcon
  },
  {
    id: OrderDeliveryType.SameDay,
    label: 'Same-Day Delivery',
    icon: samedayIcon
  },
  {
    id: OrderDeliveryType.Custom,
    label: 'Custom Courier',
    icon: customIcon
  },
  // archived order
  {
    id: 'order_archived',
    label: 'Manual Override',
    icon: archivedIcon
  },
  // courier badges
  {
    id: 'courier_australiaPost',
    label: 'Australia Post',
    icon: ausPostIcon
  },
  {
    id: 'courier_australiaPost_pickup',
    label: 'Australia Post Pickup',
    icon: ausPostIcon
  },
  {
    id: 'courier_australiaPost_dropoff',
    label: 'Australia Post Drop Off',
    icon: ausPostIcon
  },
  {
    id: 'courier_doordash',
    label: 'DoorDash',
    icon: doorDashIcon
  },
  {
    id: 'courier_doordash_pickup',
    label: 'DoorDash Pickup',
    icon: doorDashIcon
  },
  // telehealth badges
  {
    id: 'telehealth_express_consultation',
    label: 'Express Consultation',
    icon: expressConsultIcon
  },
  {
    id: 'telehealth_script_renewal',
    label: 'Script Renewal',
    icon: scriptRenewalIcon
  },
  // script queue badges
  {
    id: 'scriptQueue_inStore',
    label: 'In-Store Prescription',
    icon: inStorePrescriptionIcon
  }
];

function getBadgeContent(type: OrderDeliveryType | string): any {
  return badgeContent.find(badgeType => badgeType.id === type);
}

export const Badge: FunctionComponent<BadgeProps> = ({ type, small, iconOnly }) => {
  return (
    <StyledBadge type={type} className={`Badge ${small ? 'small' : ''}`}>
      <img
        className="Badge_icon"
        src={getBadgeContent(type).icon}
        alt={getBadgeContent(type).label}
      />
      {!iconOnly && <p className="Badge_label medium">{getBadgeContent(type).label}</p>}
    </StyledBadge>
  );
}

Badge.defaultProps = {
  small: true
}

const StyledBadge = styled.div<{ type: OrderDeliveryType | string }>`
  position: relative;
  width: fit-content;
  height: 34px;
  border-radius: 43px;

  display: flex;
  align-items: center;

  padding: 4px 3px;
  background-clip: padding-box;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
  }

  ${props => handleType(props.type)};

  .Badge_label {
    font-size: 0.875rem; // 14px
    margin: 0 10px;
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
  }

  .Badge_icon {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background: white;
  }

  &.small {
    height: 24px;
    padding: 4px 2px;
    
    .Badge_label {
      font-size: 0.6875rem; // 11px
      margin: 0 8px 0 6px;
    }

    .Badge_icon {
      width: 20px;
      height: 20px;
    }
  }
`

const handleType = (type: OrderDeliveryType | string): string => {
  switch (type) {
    // delivery type
    case OrderDeliveryType.Express:
      return `
      background-color: white;
      background-image: linear-gradient(to bottom, #FABD4A38, #F6732938);
      
      .Badge_label {
        background-color: #F67329;
        background-image: linear-gradient(to bottom, #FABD4A -50%, #F67329);
      }

      &:after {
        background: linear-gradient(135deg, #FABD4A, #F67329);
      }
      `
    case OrderDeliveryType.Standard:
      return `
        background-color: white;
        background-image: linear-gradient(to bottom, #6DDD961F, #5ECDE61F);
        
        .Badge_label {
          background-color: #6BD9C7;
          background-image: linear-gradient(to bottom, #6EDD98, #68D4F8);
        }
    
        &:after {
          background: linear-gradient(135deg, #6DDD96, #67D3F7);
        }
      `
    case OrderDeliveryType.Pickup:
      return `
        background-color: white;
        background-image: linear-gradient(to bottom, #AE44B42E, #675DD92E);
        
        .Badge_label {
          background-color: #8E50C6;
          background-image: linear-gradient(to bottom, #AE45B5, #6E5CD8);
        }
    
        &:after {
          background: linear-gradient(135deg, #AD45B6, #6E5CD8);
        }
      `
    case OrderDeliveryType.SameDay:
      return `
        background-color: white;
        background-image: linear-gradient(to bottom, #AE44B42E, #CF124B2E);
        
        .Badge_label {
          background-color: #BF2B7F;
          background-image: linear-gradient(to bottom, #AE45B5, #CF124B);
        }
    
        &:after {
          background: linear-gradient(135deg, #AD45B6, #CF124B);
        }
      `
    case OrderDeliveryType.Custom:
      return `
        background-color: white;
        background-image: linear-gradient(135deg, #A982E82E, #4046D82E);
        
        .Badge_label {
          background-color: #BF2B7F;
          background-image: linear-gradient(135deg, #73AAEB, #1239C5);
        }
    
        &:after {
          background: linear-gradient(135deg, #73AAEB, #1239C5);
        }
      `
    // archived
    case 'order_archived':
      return `
        // BACKGROUND
        background-color: white;
        background-image: linear-gradient(135deg, #F6AFD52E, #EB06062E);
        
        .Badge_label {
          // TEXT
          background-image: linear-gradient(135deg, #F6AFD5 -100%, #EB0606);
        }
    
        &:after {
          // BORDER
          background: linear-gradient(135deg, #F6AFD5 -50%, #EB0606);
        }
      `
    // couriers
    case 'courier_australiaPost':
    case 'courier_australiaPost_pickup':
    case 'courier_australiaPost_dropoff':
      return `
        background-color: white;
        background-image: linear-gradient(#CA343226, #CA343226);
        
        .Badge_label {
          background-color: #CA3432;
        }
    
        &:after {
          background: #CA3432;
        }
      `
    case 'courier_doordash':
    case 'courier_doordash_pickup':
      return `
        background-color: white;
        background-image: linear-gradient(#FF300826, #FF300826);
        
        .Badge_label {
          background-color: #FF3008;
        }
    
        &:after {
          background: #FF3008;
        }
      `
    // telehealth appointments
    case 'telehealth_express_consultation':
      return `
        background-color: white;
        background-image: linear-gradient(135deg, #77B6452E, #5CD88E2E);
        
        .Badge_label {
          background-color: #BF2B7F;
          background-image: linear-gradient(135deg, #82E8AB, #40D84F);
        }
    
        &:after {
          background: linear-gradient(135deg, #82E8AB, #40D84F);
        }
      `
    case 'telehealth_script_renewal':
      return `
        background-color: white;
        background-image: linear-gradient(135deg, #FFC7022E, #FFA8002E);
        
        .Badge_label {
          background-color: #FFB801;
          background-image: linear-gradient(135deg, #FFC702, #FFA800);
        }
    
        &:after {
          background: linear-gradient(135deg, #FFC702, #FFA800);
        }
      `
    case 'scriptQueue_inStore':
      return `
        background-color: white;
        background-image: linear-gradient(to bottom, #5ECDE61F, #6E94DD1F);
        
        .Badge_label {
          background-color: #6E94DD;
          background-image: linear-gradient(to bottom, #68D4F8, #6E94DD);
        }
    
        &:after {
          background: linear-gradient(135deg, #67D3F7, #6E94DD);
        }
      `
    default:
      return '';
  }
};