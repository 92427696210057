import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IOrder, OrderDeliveryType } from '../../types/IOrders';
import { IOrderNote } from '../../types/IOrderNotes';
import { Timestamp } from '../../types/Timestamp';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { parseTimestamp, formatTimestamp } from '../../helpers/utils';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { Badge } from '../Badge/Badge';

import tagCheckIcon from '../../assets/images/tags/Check.svg';


type OrderHistoryItem = {
  text: string
  timestamp: Timestamp
  tags?: Array<string>
  action?: {
    label: string
    handler: Function
  }
}

type OrderHistoryModalProps = {
  show: boolean
  order: IOrder
  onClose?: Function
}

export const OrderHistoryModal: FunctionComponent<OrderHistoryModalProps> = ({ show, order, onClose }) => {
  const apiHelper: ApiHelper = useApi();

  // computed
  const historyItems = (): Array<OrderHistoryItem> => {
    // hardcoded items
    const items: Array<OrderHistoryItem> = [
      {
        text: 'Order placed',
        timestamp: order.created_at,
        tags: ['Confirmation email sent']
      }
    ];

    // completed date
    // if (order.completed_at !== null) {
    //   if (order.delivery_type_code === OrderDeliveryType.Pickup) {
    //     items.push({
    //       text: 'Order collected by customer',
    //       timestamp: order.completed_at
    //     })
    //   }
    // }

    // blend in order notes
    const notes: Array<OrderHistoryItem> = order.notes.map((note: IOrderNote) => {
      return {
        text: note.note,
        timestamp: note.updated_at || note.created_at
      } as OrderHistoryItem;
    });
    items.push(...notes);

    // sort by timestamp
    return items.sort((a, b) => {
      return parseTimestamp(a.timestamp) < parseTimestamp(b.timestamp) ? -1 : 1;
    });
  }

  // methods
  const handleClose = (): void => {
    if (onClose) onClose();
  }

  return (
    <StyledOrderHistoryModal className="OrderHistoryModal">
      <Modal show={show}>
        <Heading heading="Order History" />
        <div className="OrderHistoryModal_details divider">
          <Badge type={order.delivery_type_code} />
          {(order.delivery_type_code === OrderDeliveryType.Standard || order.delivery_type_code === OrderDeliveryType.Express) && <Badge type={'courier_australiaPost'} />}
          {order.delivery_type_code === OrderDeliveryType.SameDay && <Badge type={'courier_doordash'} />}
          <OrderDetails order={order} phone email bold={false} />
        </div>
        <div className="OrderHistoryModal_history">
          
          
          {historyItems().map((item: OrderHistoryItem, i: number) => {
            return (
              <div className="OrderHistoryModal_item" key={`item-${i}`}>
                <p className="item_text medium">{item.text}</p>
                <p className="item_timestamp semibold">
                  <span>{formatTimestamp(item.timestamp, 'time12')}</span>
                  <span style={{ margin: '0 8px' }}>|</span>
                  <span>{formatTimestamp(item.timestamp, 'date')}</span>
                </p>
                {item.tags &&
                  <div className="item_tags">
                    {item.tags.map((tag: string, j: number) => {
                      return (
                        <p className="item_tag" key={`item-${i}_tag-${j}`}>
                          <img src={tagCheckIcon} alt="" draggable="false" />
                          {tag}
                        </p>
                      )
                    })}
                  </div>
                }
              </div>
            )
          })}

        </div>
        <Button type={ButtonType.Secondary} text="Close" onClick={handleClose} />
      </Modal>
    </StyledOrderHistoryModal>
  );
}

const StyledOrderHistoryModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    /* .Badge {
      position: absolute;
      top: 60px;
      right: 50px;
    } */

    .OrderHistoryModal_details {
      margin-top: 28px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .OrderHistoryModal_history {
      padding: 50px 0;

      .OrderHistoryModal_item {
        position: relative;
        margin-left: 11px;
        padding: 0 0 26px 20px;
        border-left: 1px solid #D6D6D6;

        &:before {
          content: "";
          position: absolute;
          top: 6px;
          left: -4px;
          transform: translateX(-0.5px);
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: #D6D6D6;
        }

        &:first-child {
          &:before {
            box-shadow: 0 -8px 0 0 white;
          }
        }
        
        &:last-child {
          &:after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: -4px;
            transform: translateX(-0.5px);
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: #D6D6D6;
          }
        }

        &:first-child {
          &:before {
            box-shadow: 0 -8px 0 0 white;
          }
        }

        p {
          margin: 0;
        }

        .item_text {
          word-wrap: break-word;
        }

        .item_timestamp {
          font-size: 0.75rem; // 12px
          color: #898989;
          margin: 10px 0;
        }

        .item_tags {
          display: flex;
          align-items: center;
          margin-top: 18px;

          p {
            display: flex;
            align-items: center;
            font-family: "Mulish Bold";
            height: 24px;
            font-size: 0.625rem; // 10px
            margin: 0 22px 0 0;

            img {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
`