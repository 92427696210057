import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { SelectionButton } from '../SelectionButton/SelectionButton';


export enum SelectionGroupInputType {
  Radio = "radio",
  Checkbox = "checkbox"
}

export enum SelectionGroupType {
  List = "list",
  DeliveryList = "delivery_list",
  Grid = "grid"
}

export type SelectionGroupOption = {
  id: string;
  value: string | number;
  label: string;
  subtitle?: string;
  provider?: string;
  price?: string;
  disabled?: boolean;
  imageSrc?: string;
  iconSrc?: string;
  colour?: string;
  length?: number;
  width?: number;
  height?: number;
};

type SelectionGroupProps = {
  inputType: SelectionGroupInputType
  groupType: SelectionGroupType
  options: Array<SelectionGroupOption>
  selected: string | Array<string> | number | Array<number> // string for radio, array for checkbox
  onChange: Function
  largeLabels?: boolean
}

export const SelectionGroup: FunctionComponent<SelectionGroupProps> = ({ inputType, groupType, options, selected, onChange, largeLabels }) => {
  const handleChange = (value: any): void => {
    if (inputType === SelectionGroupInputType.Radio) {
      if (selected !== value) {
        onChange(value);
      } else {
        onChange('');
      }
    } else if (inputType === SelectionGroupInputType.Checkbox) {
      if (value === 'none') {
        // if selected value is keyword `none`, remove all from selection except none
        onChange(['none']);
      } else {
        // otherwise, toggle item in existing array
        const newVal: Array<string> = (selected as Array<string>).slice();
        const index = newVal.indexOf(value);
        if (index === -1) {
          newVal.push(value);
        } else {
          newVal.splice(index, 1);
        }
        // remove 'none' item if present
        const noneIndex = newVal.indexOf('none');
        if (noneIndex !== -1) {
          newVal.splice(noneIndex, 1);
        }
        onChange(newVal);
      }
    }
  }

  return (
    <StyledSelectionGroup className={`SelectionGroup_${groupType}`}>
      {options.map((option: any, i: number) => {
        return (
          <SelectionButton
            key={option.id}
            type={inputType}
            compressed={groupType === SelectionGroupType.List}
            deliveryList={groupType === SelectionGroupType.DeliveryList}
            selected={Array.isArray(selected) ? selected.includes(option.value as never) : selected === option.value}
            id={option.id}
            value={option.value}
            label={option.label}
            subtitle={option.subtitle}
            price={option.price}
            disabled={option.disabled}
            imageSrc={option.imageSrc}
            iconSrc={option.iconSrc}
            colour={option.colour}
            largeLabels={largeLabels}
            onChange={handleChange}
          />
        )
      })}
    </StyledSelectionGroup>
  );
}

const StyledSelectionGroup = styled.div`
  display: grid;
  grid-gap: 25px 14px;
  gap: 25px 14px;

  &.SelectionGroup_list {
    grid-template-columns: 1fr;
  }

  &.SelectionGroup_delivery_list {
    grid-template-columns: 1fr;
    grid-gap: 11px;
    gap: 11px;
  }

  &.SelectionGroup_grid {
    grid-template-columns: 1fr 1fr;

    div:last-child:nth-child(odd) {
      grid-column: span 2;
    }
  }
`