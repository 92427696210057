import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import styled from 'styled-components';

import { IUser } from "../types/IUsers";
import { ILocation } from "../types/ILocations";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";

import dropdownIcon from "../assets/images/icons/DropdownCaret.svg";
import calendarIcon from "../assets/images/icons/Calendar.svg";
import placeholderGraph from "../assets/images/graphics/placeholder-report-graph.svg";


type ReportsPageProps = {
  loggedin: boolean,
  user: IUser | null,
  location: ILocation | null,
  onSelectLocation: Function,
  onSelectUser: Function,
  onLogout: Function
}

const dummyTiles = {
  financial: [
    {
      label: 'Gross Revenue',
      value: '$19,280',
      width: 'large'
    },
    {
      label: 'Retail Revenue',
      value: '$3,856',
      width: 'large'
    },
    {
      label: 'Prescription Revenue',
      value: '$15,424',
      width: 'large'
    },
    {
      label: 'Average Basket Value',
      value: '$80.10',
      width: 'small'
    },
    {
      label: 'Refunds',
      value: '15',
      width: 'small'
    },
    {
      label: 'Cost Per Acqusition',
      value: '$15.20',
      width: 'small'
    },
    {
      label: 'Shipping Costs',
      value: '$1,650',
      width: 'small'
    }
  ],
  patient: [
    {
      label: 'New Customers',
      value: '178',
      width: 'small'
    },
    {
      label: 'Returning Customers',
      value: '63',
      width: 'small'
    },
    {
      label: 'New Scripts',
      value: '103',
      width: 'small'
    },
    {
      label: 'Script Renewals',
      value: '14',
      width: 'small'
    },
    {
      label: 'Telehealth Appointments',
      value: '42',
      width: 'small'
    },
    {
      label: 'Satisfaction Score',
      value: '4.3 / 5',
      width: 'small'
    },
    {
      label: 'Unique Sessions',
      value: '11,520',
      width: 'small'
    },
    {
      label: 'Conversion Rate',
      value: '2.1%',
      width: 'small'
    },
    {
      label: 'Total Cart Abandonment',
      value: '69%',
      width: 'large'
    },
    {
      label: 'Bounce Rate',
      value: '62%',
      width: 'large'
    },
    {
      label: 'Average time on site',
      value: '42 seconds',
      width: 'large'
    }
  ],
  shipping: [
    {
      label: 'Total Shipments',
      value: '110',
      width: 'large'
    },
    {
      label: 'Total Click-and-Collect',
      value: '131',
      width: 'large'
    },
    {
      label: 'ATFOTP',
      value: '00:23',
      width: 'large'
    }
  ]
}

export const ReportsPage: FunctionComponent<ReportsPageProps> = ({ loggedin, user, location, onSelectLocation, onSelectUser, onLogout }) => {
  return (
    <>
      {!loggedin || !location ? 
        <Navigate to="/login" />
      : 
        <>
          <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
            <Heading heading="Performance Reports" />
            
            <StyledReportsPage>
              <div className="Reports_selectContainer">
                <p>
                  <span className="medium">{location.name}</span>
                  <img src={dropdownIcon} alt="" />
                </p>
                <p>
                  <span className="medium">01 March – 31 March 2023</span>
                  <img src={calendarIcon} alt="" />
                </p>
              </div>

              <section>
                <label className="Reports_sectionLabel">Financial</label>
                <div className="Reports_tile highlight" style={{ marginBottom: '30px' }}>
                  <label>Transactions</label>
                  <div className="Reports_tileContent">
                    <p className="bold" style={{ height: '46px', paddingTop: '12px' }}>241</p>
                    <img src={placeholderGraph} alt="" />
                  </div>
                </div>

                <div className="Reports_tileGrid">
                  {dummyTiles.financial.map((tile: any, i: number) => {
                    return (
                      <div className={`Reports_tile ${tile.width}`} key={tile.label}>
                        <label>{tile.label}</label>
                        <div className="Reports_tileContent">
                          <p className="bold">{tile.value}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </section>

              <section>
                <label className="Reports_sectionLabel">Patient Behaviour</label>
                <div className="Reports_tileGrid">
                  {dummyTiles.patient.map((tile: any, i: number) => {
                    return (
                      <div className={`Reports_tile ${tile.width}`} key={tile.label}>
                        <label>{tile.label}</label>
                        <div className="Reports_tileContent">
                          <p className="bold">{tile.value}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </section>

              <section>
                <label className="Reports_sectionLabel">Shipping & Click-and-Collect</label>
                <div className="Reports_tileGrid">
                  {dummyTiles.shipping.map((tile: any, i: number) => {
                    return (
                      <div className={`Reports_tile ${tile.width}`} key={tile.label}>
                        <label>{tile.label}</label>
                        <div className="Reports_tileContent">
                          <p className="bold">{tile.value}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </section>
            </StyledReportsPage>
          </Body>
        </>
      }
    </>
  );
}

const StyledReportsPage = styled.div`
  .Reports_selectContainer {
    display: flex;
    gap: 12px;

    p {
      font-size: 0.875rem; // 14px
      background: rgba(255, 255, 255, 0.5);
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 14px;
      cursor: pointer;
      margin-bottom: 0;
    }
  }

  section {
    padding: 20px 50px 28px 50px;
    margin: 42px 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 6px;

    .Reports_sectionLabel {
      display: block;
      font-size: 1rem; // 16px
      margin-bottom: 1.25rem; // 20px
    }

    .Reports_tile {
      border: 1px solid black;
      border-radius: 5px;
      background-color: white;

      label {
        display: block;
        width: 100%;
        color: white;
        text-align: center;
        font-size: 0.5rem; // 8px
        background-color: black;
        padding: 5px 0;
      }

      .Reports_tileContent {
        min-height: 47px;
        padding: 9px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
          font-size: 1.125rem; // 18px
        }
      }

      &.small {
        grid-column: span 3;
      }
      
      &.large {
        grid-column: span 4;
      }

      &.highlight {
        border-color: #FFA24B;

        label {
          background-color: #FFA24B;
        }

        .Reports_tileContent {
          p {
            color: #FFA24B;
          }
        }
      }
    }

    .Reports_tileGrid {
      display: grid;
      grid-gap: 22px 10px;
      gap: 22px 10px;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto;
    }
  }
`