import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { transitions, colours } from '../../assets/css/variables';

import { Category, Subcategory } from '../../types/DashboardCategories';
import { UserRoleCode } from "../../types/IUsers";
import { CourierType } from '../../types/IOrders';

import menuIcon from '../../assets/images/icons/Menu.svg';


export type DropdownMenuItem = {
  id: string
  label: string
  onClick: Function
  categories?: Array<Category>
  subcategories?: Array<Subcategory>
  courierTypes?: Array<CourierType>
  requiredUserRole?: UserRoleCode
}

type DropdownMenuProps = {
  items: Array<DropdownMenuItem>
  setDropdownOpen?: Function
}

export const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({ items, setDropdownOpen }) => {
  const [open, setOpen] = useState(false);
  
  // use useEffect to add and remove mouse event listeners on mount/unmount
  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    }
  })

  const toggleOpen = (): void => {
    setOpen(!open);
    if (setDropdownOpen) setDropdownOpen(!open);
  }

  const handleOnClick = (onClick: Function): void => {
    onClick();
    setOpen(false);
    if (setDropdownOpen) setDropdownOpen(false);
  }

  const handleClickOutside = (event: PointerEvent): void => {
    if (menuRef && !menuRef.contains(event.target as Node) && open) {
      setOpen(false);
      if (setDropdownOpen) setDropdownOpen(false);
    }
  }

  let menuRef: HTMLDivElement | null;
  const initClickOutside = (el: HTMLDivElement | null): void => {
    if (el) {
      menuRef = el;
    }
  }

  return (
    <StyledDropdownMenu ref={el => initClickOutside(el)}>
      <img
        className="DropdownMenu_button button"
        src={menuIcon}
        alt="Dropdown menu"
        onClick={toggleOpen}
        draggable="false"
      />
      
      <div className={`DropdownMenu ${open ? 'open' : 'closed'}`}>
        <ul className="DropdownMenu_items">
          {items.map(item => {
            return (
              <li
                key={item.id}
                onClick={() => handleOnClick(item.onClick)}
              >
                {item.label}
              </li>
            )
          })}
        </ul>
      </div>
    </StyledDropdownMenu>
  );
}

const StyledDropdownMenu = styled.div`
  position: relative;
  width: fit-content;

  .DropdownMenu_button {
    padding: 10px 5px;
    margin-right: -5px;
  }

  .DropdownMenu {
    position: absolute;
    right: 0;
    top: 24px;
    overflow: hidden;
    user-select: none;
    z-index: 99;
    transition: 
      opacity ${transitions.default},
      height ${transitions.slow};
    
    &.open {
      opacity: 1;
    }

    &.closed {
      opacity: 0;
      pointer-events: none;
    }

    .DropdownMenu_items {
      padding: 0;
      border: 2px solid ${colours.lightGrey};
      width: 190px;
      border-radius: 6px;
      margin: 0;
      background-color: white;

      li {
        display: flex;
        align-items: center;
        height: 38px;
        padding: 0 15px;
        cursor: pointer;
        transition: background-color ${transitions.default};

        &:not(:last-child) {
          border-bottom: 1px solid ${colours.lightGrey};
        }

        &:hover {
          font-family: "Mulish SemiBold";
          background-color: ${colours.lightGrey};
        }
      }
    }
  }
`