import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';

import NextIcon from '../../assets/images/icons/Next.svg';
import TickIcon from '../../assets/images/icons/TickGreen.svg';


type ProcessStepsProps = {
  steps: Array<string>,
  current: number | string
}

export const ProcessSteps: FunctionComponent<ProcessStepsProps> = ({ steps, current }) => {
  const getCurrentIndex = (): number => {
    if (typeof current === 'number') {
      return current;
    } else {
      return steps.findIndex(step => step === current);
    }
  }

  const getStepState = (i: number): string => {
    const currentIndex = getCurrentIndex();
    if (i === currentIndex) {
      return 'current';
    } else if (i < currentIndex) {
      return 'complete';
    } else {
      return '';
    }
  }

  return (
    <StyledProcessSteps className={`ProcessSteps ${steps.length > 5 ? 'compressed' : ''}`}>
      {steps.map((step: string, i: number) => {
        return (
          <p className={`bold ${getStepState(i)}`} key={`${i}-${step}`}>
            <span className="Step_number">
              {getStepState(i) === 'complete' ?
                <img src={TickIcon} alt="" />
              :
                <>{i + 1}</>
              }
            </span>
            <span className="Step_text">
              {step}{i !== steps.length - 1 && <img className="Step_next" src={NextIcon} alt="" />}
            </span>
          </p>
        )
      })}
    </StyledProcessSteps>
  );
}

const StyledProcessSteps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  p {
    color: #898989;
    transition: color ${transitions.default};

    .Step_number {
      display: inline-block;
      width: 23px;
      height: 23px;
      border: 1px solid #898989;
      border-radius: 100%;
      text-align: center;
      font-size: 0.75rem; // 12px
      padding: 3px 0;
      transition: border-color ${transitions.default}, background-color ${transitions.default}, color ${transitions.default};
    }

    .Step_text {
      display: inline-block;
      font-size: 0.875rem; // 14px
      text-transform: capitalize;
      margin-left: 6px;
    }

    .Step_next {
      margin-left: 6px;
    }

    &.current {
      color: black;

      .Step_number {
        border-color: black;
        background-color: black;
        color: white;
      }
    }
    
    &.complete {
      color: #35C680;

      .Step_number {
        border-color: #35C680;
      }
    }
  }

  &.compressed {
    gap: 9px;

    p {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      &:not(:last-child) {
        .Step_number {
          transform: translateX(-6.5px);
        }
      }

      .Step_text {
        margin-left: 0;
      }
      
      .Step_next {
        margin-left: 9px;
      }
    }
  }
`