import { FunctionComponent, useEffect, useState, Dispatch, SetStateAction } from "react";
import { useParams, Params, Navigate } from "react-router-dom";

import { IUser } from "../types/IUsers";

import { Config } from "../common/Config/Config";
import { useApi } from "../context/ApiProvider";
import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";
import { Loader } from '../components/Loader/Loader';
// import { Orders } from "../components/Orders/Orders";
import { Result } from '../components/Result/Result';
import { TimeAgo } from "../components/TimeAgo/TimeAgo";


type UserPageProps = {
  loggedin: boolean,
  user: IUser | null,
  location: any,
  onSelectLocation: Function,
  onSelectUser: Function,
  onLogout: Function
}

export const UserPage: FunctionComponent<UserPageProps> = ({ loggedin, user, location, onSelectLocation, onSelectUser, onLogout }) => {
  // important - get username from route parameter
  const { userId }: Readonly<Params<string>> = useParams();

  // load state
  const [userState, setUserState]: [IUser, Dispatch<SetStateAction<IUser>>] = useState<any>()
  
  // get API context
  const apiHelper: any = useApi();
  
  // get endpoint
  const endpoint: string = Config.api.backend.users.get.endpoint_short;
  
  // use useEffect hook to update the User state variables when API data is returned
  useEffect(() => {
    (async () => {
      const response = await apiHelper.get(endpoint + userId);

      // update component data
      setUserState(response.ok ? response.body : null);
    })();
  }, [apiHelper, endpoint, userId]);

  // temp
  const currentUserId = '4';
  const currentUser = (pageUsername: string | undefined, currentUsername: string): boolean => {
    if (pageUsername === currentUserId) { return true } else { return false };
  }

  return (
    <>
      {!loggedin || !location ? 
        <Navigate to="/login" />
      : 
        <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
          <div>

            {user === undefined ? 
              <Loader type={'default'} />
            :
              <>
                {user === null ? 
                  <>
                    <Result wasSuccessful={false} />
                    <p>User not found.</p>
                  </>
                :
                  <>
                    <div>
                      <Heading heading={user.first_name + ' ' + user.last_name} />

                      <p>
                          <strong>Member since:</strong> <TimeAgo isoDate={user.created_at} /><br />
                          <strong>Last seen:</strong> <TimeAgo isoDate={user.last_seen} />
                      </p>
                    </div>

                    {currentUser(userId, currentUserId) ? 
                      <>
                        <div>
                          <h2>Your details</h2>
                          {/* <div><strong>Username:</strong> {user.username}</div> */}
                          <div><strong>Email:</strong> {user.email}</div>
                          <div><strong>First Name:</strong> {user.first_name}</div>
                          <div><strong>Last Name:</strong> {user.last_name}</div>
                          <div><a href="/#" title="Edit">Edit</a></div>
                          {user.telephone ? <div><strong>Telephone:</strong> {user.telephone}</div> : null }
                        </div>
                      </>
                    : null }

                    {/* {userId ? 
                      <Orders orderSelection={userId} />
                    : null } */}
                  </>
                }
              </>
            }
          </div>
        </Body>
      }
    </>
  );
}
