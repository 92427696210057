import { FunctionComponent, useEffect } from 'react';
import { transitions } from '../../assets/css/variables';
import { clamp } from '../../helpers/utils';
import styled from 'styled-components';

import minusIcon from '../../assets/images/icons/Minus.svg';
import plusIcon from '../../assets/images/icons/Plus.svg';


type QuantitySelectorProps = {
  label?: string
  quantity?: number
  min?: number | undefined
  max?: number | undefined
  interval?: number
  small?: boolean
  disabled?: boolean
  onChange?: Function
}

export const QuantitySelector: FunctionComponent<QuantitySelectorProps> = ({ label, quantity, min, max, interval, small, disabled, onChange }) => {
  // set initial value
  useEffect((): void => {
    const initialValue = clamp(quantity!, min, max);
    if (onChange && initialValue !== quantity) onChange(initialValue);
  });

  const increaseQuantity = (): void => {
    const newValue = clamp(quantity! + interval!, min, max);
    if (onChange) onChange(newValue);
  }
  
  const decreaseQuantity = (): void => {
    const newValue = clamp(quantity! - interval!, min, max);
    if (onChange) onChange(newValue);
  }

  return (
    <StyledQuantitySelector className={`QuantitySelector ${disabled ? 'QuantitySelector_disabled' : ''} ${small ? 'QuantitySelector_small' : ''}`}>
      {label && <label className="semibold">{label}</label>}
      <div>
        <img className={`button ${quantity === min ? 'disabled' : ''}`} src={minusIcon} alt="" onClick={decreaseQuantity} draggable="false" />
        <p className="bold">{quantity}</p>
        <img className={`button ${quantity === max ? 'disabled' : ''}`} src={plusIcon} alt="" onClick={increaseQuantity} draggable="false" />
      </div>
    </StyledQuantitySelector>
  );
}

QuantitySelector.defaultProps = {
  label: 'Qty:',
  quantity: 0,
  min: undefined,
  max: undefined,
  interval: 1,
  disabled: false,
  onChange: undefined
}

const StyledQuantitySelector = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  &.QuantitySelector_disabled {
    pointer-events: none;
    opacity: 0.5;

    .disabled {
      opacity: 1;
      pointer-events: none;
    }
  }

  label {
    font-size: 0.625rem; // 10px
    margin-top: 5px;
    margin-bottom: 5px;
    user-select: none;
  }

  div {
    display: flex;
    align-items: center;
    
    img {
      padding: 5px;
      cursor: pointer;
      user-select: none;
      transition: opacity ${transitions.fast};

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    
    p {
      font-size: 1.25rem; // 20px
      text-align: center;
      width: fit-content;
      min-width: 31px;
      margin: 0;
      user-select: none;
    }
  }

  &.QuantitySelector_small {
    p {
      font-size: 1rem; // 16px
      min-width: 26px;
    }

    img.button {
      width: 20px;
      height: 20px;
      padding: 2px;
    }
  }
`