import { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { colours } from '../../assets/css/variables';
import styled from 'styled-components';

import { IUser } from "../../types/IUsers";
import { ILocation } from '../../types/ILocations';
import { l18n } from '../../common/l18n';
import { Menu } from '../Menu/Menu';
import { useDummyData } from '../../context/DummyDataProvider';

// import scriptionLogo from '../../assets/images/logos/Scription.svg';

// import omniflowLogo from '../../assets/images/logos/OmniFlow_Beta.png'
import SparrowHub from '../../assets/images/logos/sparrow_logo_beta.png';
// import SparrowHubIcon from '../../assets/images/logos/sparrowhub_icon_beta.png';
import SparrowHubIcon from '../../assets/images/logos/SparrowHubSecondaryLogoBeta.png';
import menuIcon from '../../assets/images/icons/HamburgerMenu.svg';


// header links
const pageLinks = [
  { title: 'Orders', url: '/orders' },
  // { title: 'Account', url: '/u/1' },
  // { title: 'Switch User', url: '#' },
  // { title: 'Reset password (create)', url: '/reset-password' },
  // { title: 'Reset password (confirm)', url: '/reset-password/confirm' },
];

const showDebugNav = false;

type HeaderProps = {
  loggedin: boolean,
  user: IUser | null,
  location: ILocation | null,
  type: string,
  onSelectLocation?: Function,
  onSelectUser?: Function,
  onLogout?: Function
}

export const Header: FunctionComponent<HeaderProps> = ({ loggedin, user, location, type, onSelectLocation, onSelectUser, onLogout }) => {
  const [showMenu, setShowMenu] = useState(false);

  const dummyData: any = useDummyData();

  return (
    <StyledHeader>
      <header className={type}>
        {/* main logo/home link */}
        <NavLink className="Header_home" to="/" title="Home">
          <img src={!loggedin || location === null ? SparrowHub :  SparrowHubIcon} alt="SparrowHub" title="SparrowHub" />
        </NavLink>

        {/* nav content */}
        {loggedin && type === 'default' &&
          <>
            {dummyData.state.useDummyData &&
              <p className="Header_userInfo">
                You are logged in as Hamish<br />
                {location?.name}
              </p>
            }
            <img src={menuIcon} className="button" alt="Nav menu" onClick={() => setShowMenu(true)} draggable="false" />
            
            <Menu visible={showMenu} userRole={user?.role_code} onClose={() => setShowMenu(false)} onSelectLocation={onSelectLocation!} onSelectUser={onSelectUser!} onLogout={onLogout!} />
          </>
        }
      </header>

      {showDebugNav && 
        <DebugNav>
          <p>Debug Nav</p>
          <hr />
          
          {/* home */}
          <NavLink to="/" title={l18n.label.website_name}><span>{l18n.label.website_name}</span></NavLink>
          <hr />

          {/* page links */}
          <nav>
            {pageLinks.map((link) => (
              <NavLink to={link.url} title={link.title} key={link.title}><span>{link.title}</span></NavLink>
            ))}
          </nav>
          <hr />

          {/* log in/out */}
          <div>
            {!loggedin ?
              <NavLink to="/login" title={l18n.label.login}><span>{l18n.label.login}</span></NavLink>
            : 
              <NavLink to="/logout" title={l18n.label.logout}><span>{l18n.label.logout}</span></NavLink>
            }
          </div>
        </DebugNav>
      }
    </StyledHeader>
  );
}

Header.defaultProps = {
  onSelectLocation: () => { return undefined },
  onSelectUser: () => { return undefined },
}

const StyledHeader = styled.div`
  height: 79px;
  margin: 0 0 45px 0;

  header {
    z-index: 998;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    min-height: 54px;

    .Header_logout {
      padding: 8px;
      margin-right: -8px;
    }

    /* large centered header for login screens */
    &.login {
      height: 74px;
      justify-content: center;
      margin: 40px auto 20px auto;

      .Header_home {
        img {
          max-width: 130px;
        }
      }
    }

    /* default left-aligned header for main screens */
    &.default {
      position: fixed;
      margin: 0 0 45px 0;
      padding: 0 29px;
      background: white;
      align-items: center;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);

      .Header_home {
        cursor: pointer;
        margin-top: 5px;
        margin-right: auto;

        img {
          max-height: 32px;
        }
      }

      .Header_userInfo {
        font-family: 'Mulish Bold';
        font-size: 0.75rem; // 12px
        text-align: right;
        line-height: 125%;
        margin-right: 19px;
      }
    }
  }
`

const DebugNav = styled.nav`
  position: fixed;
  z-index: 99;
  top: 10px;
  left: 10px;
  width: fit-content;
  max-width: 150px;
  padding: 10px;
  background-color: white;
  border: 1px solid ${colours.lightGrey};
  border-radius: 6px;

  a {
    display: block;
    font-size: 0.875rem; // 14px
  }

  p {
    margin: 0;
  }

  hr {
    border-top: 1px solid ${colours.lightGrey};
  }
`