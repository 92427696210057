import { FunctionComponent, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import styled from 'styled-components';

import { ApiHelper } from "../common/ApiHelper/ApiHelper";
import { useApi } from "../context/ApiProvider";
import { sortOrders, filterOrdersByQuery } from "../helpers/utils";

import { IUser } from "../types/IUsers";
import { IOrder, IOrders, OrderDeliveryType } from "../types/IOrders";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";
import { ScriptQueueSearch } from "../components/ScriptQueueSearch/ScriptQueueSearch";
import { ScriptQueueDashboard } from "../components/ScriptQueueDashboard/ScriptQueueDashboard";
import { ScriptQueueTile } from "../components/ScriptQueueTile/ScriptQueueTile";
import { Button, ButtonColour, ButtonType } from "../components/Button/Button";
import { Tabs, TabOption } from "../components/Tabs/Tabs";

const useLocalDummyData = false;

const dummyOrders = [
  // incoming patients
  {
    status_code: 'new',
    type: 'scriptQueue_inStore',
    time_in_queue: 20,
    created_at: '2023-04-17T00:55:45.179',
    numScripts: 2,
    numEscripts: 2,
    delivery_firstname: 'Gabriela',
    delivery_lastname: 'Friedman',
    delivery_email: 'gabriela.friedman@gmail.com',
    delivery_phone: '0412 345 678',
    notes: [{ note: 'Customer wants to purchase [enter over the counter product here]. They are experiencing a severe headache.'}]
  },
  {
    status_code: 'new',
    type: 'scriptQueue_inStore',
    time_in_queue: 68,
    created_at: '2023-04-17T00:54:45.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Isabela',
    delivery_lastname: 'Brennan',
    delivery_email: 'isabela.brennan@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // dispensing
  {
    status_code: 'awaiting_processing',
    type: 'scriptQueue_inStore',
    time_in_queue: 136,
    created_at: '2023-04-17T00:53:45.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Jaidyn',
    delivery_lastname: 'Carrillo',
    delivery_email: 'jaidyn.carrillo@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // on_hold
  {
    status_code: 'on_hold',
    type: 'scriptQueue_inStore',
    time_in_queue: 136,
    created_at: '2023-04-17T00:53:15.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Grant',
    delivery_lastname: 'Burgess',
    delivery_email: 'grant.burgess@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // pre collection
  {
    status_code: 'awaiting_review',
    type: 'scriptQueue_inStore',
    time_in_queue: 198,
    created_at: '2023-04-17T00:52:45.179',
    numScripts: 3,
    numEscripts: 3,
    delivery_firstname: 'Mckenzie',
    delivery_lastname: 'George',
    delivery_email: 'mckenzie.george@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  {
    status_code: 'awaiting_review',
    type: 'scriptQueue_inStore',
    time_in_queue: 269,
    created_at: '2023-04-17T00:51:45.179',
    numScripts: 2,
    numEscripts: 2,
    delivery_firstname: 'Michelle',
    delivery_lastname: 'Ochoa',
    delivery_email: 'michelle.ochoa@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  {
    status_code: 'awaiting_review',
    type: 'scriptQueue_inStore',
    time_in_queue: 314,
    created_at: '2023-04-17T00:50:45.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Antonio',
    delivery_lastname: 'Sylvester',
    delivery_email: 'antonio.s@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // awaiting collection
  {
    status_code: 'awaiting_pickup',
    type: 'scriptQueue_inStore',
    time_in_queue: 382,
    created_at: '2023-04-17T00:49:45.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Jazlene',
    delivery_lastname: 'Sweeney',
    delivery_email: 'jazlene.sweeney@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  {
    status_code: 'awaiting_pickup',
    type: 'scriptQueue_inStore',
    time_in_queue: 428,
    created_at: '2023-04-17T00:48:45.179',
    numScripts: 2,
    numEscripts: 2,
    delivery_firstname: 'Liam',
    delivery_lastname: 'Mcmahon',
    delivery_email: 'liam.mcmahon@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // complete
  {
    status_code: 'complete',
    type: 'scriptQueue_inStore',
    time_in_queue: 896,
    created_at: '2023-04-16T20:04:45.180',
    numScripts: 2,
    numEscripts: 2,
    delivery_firstname: 'Brenda',
    delivery_lastname: 'Mendoza',
    delivery_email: 'brenda.mendoza@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  {
    status_code: 'complete',
    type: 'scriptQueue_inStore',
    time_in_queue: 617,
    created_at: '2023-04-16T16:32:45.180',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Anastasia',
    delivery_lastname: 'Tucker',
    delivery_email: 'anastasia.tucker@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  }
];

// const currentMinutes = new Date().getMinutes();
// const getTimestamp = (hourOffset: number): string => {
//   const date = new Date();
//   date.setMinutes(currentMinutes + (hourOffset * 60));
//   return date.toISOString();
// }

const generateDummyData = (dummyOrders: any): Array<any> => {
  const result: Array<any> = [];
  dummyOrders.forEach((patient: any, i: number) => {
    const id = i + 1;

    // let timeInQueue = 0;
    // let createdAt = '';
    // if (patient.status_code === 'complete') {
    //   timeInQueue = Math.floor(randomRange(135, 1200));
    //   createdAt = getTimestamp(randomRange(-0.1, -24));
    // } else {
    //   timeInQueue = i * 60 + Math.floor(randomRange(0, 30));
    //   createdAt = getTimestamp(-(timeInQueue / 60 / 60))
    // }

    const newPatient = {
      ...JSON.parse(JSON.stringify(patient)),
      id: id,
      platform_order_no: String(id).padStart(5, '0'),
      // time_in_queue: timeInQueue,
      // created_at: createdAt,
    }
    result.push(newPatient);
  });
  return result;
}


type ScriptQueuePageProps = {
  orders: IOrders | null
  setOrders: Function
  loggedin: boolean
  user: IUser | null
  location: any
  onSelectLocation: Function
  onSelectUser: Function
  onLogout: Function
}

export const ScriptQueuePage: FunctionComponent<ScriptQueuePageProps> = ({ orders, setOrders, loggedin, user, location, onSelectLocation, onSelectUser, onLogout }) => {
  const navigate = useNavigate();

  const apiHelper: ApiHelper = useApi();
  
  const [category, setCategory] = useState('new');
  const [subcategory, setSubcategory] = useState('awaiting_processing');
  const [query, setQuery] = useState('');

  useEffect(() => {
    (async () => {
      if (!useLocalDummyData) {
        // request Orders data from API
        // console.log('request orders from API')
        const response = await apiHelper.getLocationOrders(location?.code);
    
        // update component state if successful
        if (response.ok) {
          // console.log('set orders from db');
          const orders = JSON.parse(response.body.data!);
          const filteredOrders = orders.filter((order: IOrder) => order.delivery_type_code === OrderDeliveryType.InStore);
          // console.log(orders);
          // console.log(filteredOrders);
          setOrders(filteredOrders);
        } else {
          // clear orders if not
          // console.log('query failed')
          // console.log(response)
          setOrders([null]);
        }
      } else {
        setOrders(generateDummyData(dummyOrders));
      }
    })();
  }, []);

  const categoryOrders = (categoryArg?: string) => {
    if (!orders) return [];
    const cat = categoryArg || category;
    const byQuery = filterOrdersByQuery(orders, query);
    const byCategory = byQuery.filter(order => order.status_code === cat);
    return byCategory;
  }

  // const filteredOrders = categoryOrders();
  const filteredOrders = category === 'awaiting_processing'
    // ? categoryOrders().filter((order: any) => order.status_code === subcategory)
    ? categoryOrders(subcategory)
    : categoryOrders();
  const filteredSortedOrders = sortOrders(filteredOrders, ['created']);

  const handleAddOrder = (): void => {
    navigate(`/process-prescription/add-script`);
  }

  const dispensingTabOptions: Array<TabOption> = [
    {
      id: 'awaiting_processing',
      value: 'awaiting_processing',
      label: 'Active',
      count: categoryOrders('awaiting_processing').length
    },
    {
      id: 'on_hold',
      value: 'on_hold',
      label: 'On Hold',
      count: categoryOrders('on_hold').length
    }
  ]

  return (
    <>
      {!loggedin || !location ? 
        <Navigate to="/login" />
      : 
        <>
          <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
            <StyledScriptQueuePage>
              <Heading heading="SparrowScripts Queue" />
              <ScriptQueueSearch orders={orders || []} setQuery={setQuery} setCategory={setCategory} />
              <ScriptQueueDashboard orders={orders || []} category={category} query={query} setCategory={setCategory} />
              <Button type={ButtonType.Primary} colour={ButtonColour.Green} text="Add New Order" onClick={handleAddOrder} />

              {(orders !== null && orders.length !== 0 && orders[0] !== null) &&
                <>
                  {category === 'awaiting_processing' &&
                    <Tabs options={dispensingTabOptions} current={subcategory} onSelectTab={setSubcategory} />
                  }

                  {filteredSortedOrders.map((order: any) => {
                    return (
                      <div key={`${order.id}`}>
                        <ScriptQueueTile order={order} orders={orders || []} setOrders={setOrders} user={user} />
                      </div>
                    )
                  })}
                </>
              }
            </StyledScriptQueuePage>
          </Body>
        </>
      }
    </>
  );
}

const StyledScriptQueuePage = styled.div`
  .Button_Green {
    margin-bottom: 35px;
  }
`