import { Config } from '../../common/Config/Config';
import { IResponse } from '../../types/IResponses';


const BASE_API_URL: string = Config.api.backend.baseUrl.endpoint;
const API_ERROR_CODE: number = Config.api.backend.errorCode;

export class ApiClient {
  private baseUrl: string;
  private apiErrorCode: number;

  constructor() {
    this.baseUrl = BASE_API_URL;
    this.apiErrorCode = API_ERROR_CODE;
  }

  /**
   * request to backend API
   * 
   * @param options any
   * @returns Promise<{ok: boolean;status: number;body: any;}>
   */
  async request(options: any): Promise<{ok: boolean;status: number;body: any;}> {
    // check for and prepare query string
    let query = new URLSearchParams(options.query || {}).toString();
    query = query !== '' ? '?' + query : '';

    // console.log('ApiClient.request', options)

    // make request
    let response: Response | IResponse;
    try {
      // success response
      response = await fetch(this.baseUrl + options.url + query, {
        mode: 'cors', // CORS support for localhost Admin API
        credentials: 'include',
        method: options.method,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers,
        },
        body: options.body ? JSON.stringify(options.body) : null,
      });
    } catch (error: any) {
      // prepare fake error
      response = {
        ok: false,
        status: 500,
        json: async () => {
          return {
            code: 500,
            message: 'The server is unresponsive.',
            description: error.toString(),
          }
        }
      }
    }

    // return response
    /** @var Promise<{ok: boolean;status: number;body: any;}> */
    return {
      ok: response.ok,
      status: response.status,
      // body: response.status !== this.apiErrorCode ? await response.json() : null,
      body: response.status !== this.apiErrorCode
        ? options.blob ? await response.blob!() : await response.json()
        : null,
    }
  }

  /**
   * GET request helper
   * 
   * @param url string
   * @param query string
   * @param options any
   * @returns Promise<{ ok: boolean; status: number; body: any; }>
   */
  async get(url: string, query: string, options: any): Promise<any> {
    // console.log('ApiClient.get', url, query, options);
    
    return this.request({
      method: 'GET',
      url,
      query,
      ...options,
    });
  }

  /**
   * POST request helper
   * 
   * @param url string
   * @param body any
   * @param options any
   * @returns Promise<{ ok: boolean; status: number; body: any; }>
   */
  async post(url: string, body: any, options: any): Promise<{ ok: boolean; status: number; body: any;}> {
    return this.request({
      method: 'POST',
      url,
      body,
      ...options, 
    });
  }

  /**
   * PUT request helper
   * 
   * @param url string
   * @param body any
   * @param options any
   * @returns Promise<{ ok: boolean; status: number; body: any; }>
   */
  async put(url: string, body: any, options: any): Promise<{ ok: boolean; status: number; body: any;}> {
    return this.request({
      method: 'PUT',
      url,
      body,
      ...options, 
    });
  }

  /**
   * DELETE request helper
   * 
   * @param url string
   * @param options any
   * @returns Promise<{ ok: boolean; status: number; body: any; }>
   */
  async delete(url: string, options: any): Promise<{ ok: boolean; status: number; body: any;}> {
    return this.request({
      method: 'DELETE',
      url,
      ...options, 
    });
  }
}
