import { useState, useEffect, FunctionComponent } from 'react';
import { parseTimestamp } from '../../helpers/utils';

// prepare datetime formmater
const rtf: Intl.RelativeTimeFormat = new Intl.RelativeTimeFormat(undefined, {numeric: 'always'});

// create time scale
const secondsTable: (string | number)[][] = [
  ['year', 60 * 60 * 24 * 365],
  ['month', 60 * 60 * 24 * 30],
  ['week', 60 * 60 * 24 * 7],
  ['day', 60 * 60 * 24],
  ['hour', 60 * 60],
  ['minute', 60],
];

/**
 * calculate and return how long ago the order was created with provided datetime
 * 
 * @param date Date
 * @returns 
 */
function getTimeAgo(date: Date) {
  let bestUnit: string | null = null,
    bestTime: number | null = null,
    bestInterval: number | null = null;

  // prepare seconds
  const seconds: number = Math.round((date.getTime() - new Date().getTime()) / 1000);
  const absSeconds: number = Math.abs(seconds);
  
  // find the correct time scale
  for (let [unit, unitSeconds] of secondsTable) {
    if (absSeconds >= unitSeconds) {
      bestUnit = unit.toString();
      bestTime = Math.round(seconds / Number(unitSeconds));
      bestInterval = Number(unitSeconds) / 2;
      break;
    }
  };

  // defaults
  if (!bestUnit) {
    bestUnit = 'second';
    bestTime = (seconds / 10) * 10;
    bestInterval = 10;
  }

  return [bestTime, bestUnit, bestInterval];
}


type TimeAgoProps = {
  isoDate: any,
}

export const TimeAgo: FunctionComponent<TimeAgoProps> = ({ isoDate }) => {
  // init current date values
  const date = parseTimestamp(isoDate);
  const [time, unit, interval] = getTimeAgo(date);

  // setup fake state variable for date update
  const [, setUpdate] = useState(0);
  useEffect(() => {
    // prepare interval timer based on calculated interval values
    const timerId = setInterval(
      // update state variable
      () => setUpdate(update => update + 1),
      Number(interval) * 1000
    );
    return () => clearInterval(timerId);
  }, [interval]);

  return (
    <>
      {time && unit ? 
          <span title={date.toString()}>{rtf.format(Number(time), (unit as any))}</span>
      : null }
    </>
  );
}
