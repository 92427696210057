import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

import { IUser } from "../types/IUsers";
import { ILocations } from "../types/ILocations";

import { Body } from "../components/Body/Body";
import { AuthModal } from "../components/AuthModal/AuthModal";


type LoginPageProps = {
  loggedin: boolean
  user: IUser | null
  location: any
  partnerLocations: ILocations | undefined
  selectedUser: any
  onLogin: Function
  onLogout: Function
  onSelectLocation: Function
  onSetPartnerLocations: Function
}

export const LoginPage: FunctionComponent<LoginPageProps> = ({ loggedin, user, location, partnerLocations, selectedUser, onLogin, onLogout, onSelectLocation, onSetPartnerLocations }) => {
  return (
    <>
      {loggedin ?
        <>
          {selectedUser ? 
            <>
              {location ? 
                <Navigate to="/" />
              :
                <Navigate to="/select-store" />
              }
            </>
          : 
            <Navigate to="/select-user" />
          }
        </>
      : 
        <Body loggedin={loggedin} user={user} location={location}>
          <AuthModal type="login" onLogin={onLogin} onSelectLocation={onSelectLocation} onSetPartnerLocations={onSetPartnerLocations} />
        </Body>
      }
    </>
  );
}
