import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';

import dropdownIcon from '../../assets/images/icons/Dropdown.svg';
import personIcon from '../../assets/images/icons/Person.svg';

export enum AccordionIcon {
  Person = 1
}

type AccordionProps = {
  label: string
  icon?: AccordionIcon
  chevron?: boolean
  children: any
}

export const Accordion: FunctionComponent<AccordionProps> = ({ label, icon, chevron, children }) => {
  const [open, setOpen] = useState(false);
  const [openHeight, setOpenHeight] = useState(-1);

  const iconSrc = (icon: AccordionIcon): string => {
    switch (icon) {
      case AccordionIcon.Person:
        return personIcon;
      default:
        return '';
    }
  }

  const toggleOpen = (): void => {
    if (openHeight === -1) setOpenHeight(calculateOpenHeight());
    setOpen(!open);
  }

  const itemRefs: Array<HTMLElement> = [];
  
  const addItemRef = (el: HTMLElement | null): void => {
    if (el) itemRefs.push(el);
  }

  const calculateOpenHeight = (): number => {
    return itemRefs.reduce((acc, el) => {
      return acc + el.offsetHeight;
    }, 0);
  }

  return (
    <StyledAccordion className={`${open ? 'open' : 'closed'}`} style={{ height: `${openHeight}px` }}>
      <div className="Accordion_header" onClick={toggleOpen} ref={el => addItemRef(el)}>
        {icon && <img className="Accordion_icon" src={iconSrc(icon)} alt="" draggable="false" />}
        <p className="bold">{label}</p>
        {chevron ?
          <img className="Accordion_dropdown" src={dropdownIcon} alt="" draggable="false" />
        :
          <p className="Accordion_dropdownText">{open ? 'Hide' : 'Show'}</p>
        }
      </div>

      <div className="Accordion_body" ref={el => addItemRef(el)}>
        {children}
      </div>
    </StyledAccordion>
  );
}

Accordion.defaultProps = {
  chevron: true
}

const StyledAccordion = styled.div`
  overflow: hidden;
  transition: height ${transitions.slow};

  &.open {
    .Accordion_header .Accordion_dropdown {
      transform: rotate(-180deg);
    }
  }

  &.closed {
    height: 25px !important;
  }

  .Accordion_header {
    display: flex;
    height: 25px;
    cursor: pointer;
    user-select: none;
    align-items: center;

    .Accordion_icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    p {
      margin: 0;
    }

    .Accordion_dropdown {
      transition: transform ${transitions.slow};
      margin-left: auto;
    }
    
    .Accordion_dropdownText {
      margin-left: auto;
      text-decoration: underline;
    }
  }
`