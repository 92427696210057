import { FunctionComponent } from 'react';
import styled from 'styled-components';


type TextTagProps = {
  text: any
}

export const TextTag: FunctionComponent<TextTagProps> = ({ text }) => {
  return (
    <StyledTextTag className="TextTag regular">
      {text}
    </StyledTextTag>
  );
}

const StyledTextTag = styled.span`
  padding: 4px 6px;
  border: 1px solid black;
  border-radius: 1px;
`