import { FunctionComponent, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { IOrder, OrderDeliveryType } from '../../types/IOrders';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
// import { useDummyData } from '../../context/DummyDataProvider';
import { formatTimestamp } from '../../helpers/utils';

import { Timestamp } from '../../types/Timestamp';
import { IDeliveryBatches } from "../../types/IDeliveryBatches";

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { PickList } from '../PickList/PickList';
import { Badge } from '../Badge/Badge';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';

import printIcon from '../../assets/images/icons/Print.svg';
// import tickIcon from '../../assets/images/tags/Check.svg';
import closeIcon from '../../assets/images/icons/Close.svg';

// configure react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

type OrderDocument = {
  id: string
  name: string
  generated_at: Timestamp
  printed: boolean
  view: string
  image: string
  delivery_batch_id?: string
}

type OrderDocumentsModalProps = {
  show: boolean
  order: IOrder
  onClose?: Function
  deliveries: any
}

export const OrderDocumentsModal: FunctionComponent<OrderDocumentsModalProps> = ({ show, order, onClose, deliveries }) => {
  const apiHelper: ApiHelper = useApi();
  // const dummyData = useDummyData();

  const pickListRef = useRef(null);
  const printManifestRef = useRef(null);
  const printShippingLabelRef = useRef(null);

  const [currentDocumentPreview, setCurrentDocumentPreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [showCurrentManifest, setShowCurrentManifest] = useState(false);
  const [currentManifest, setCurrentManifest] = useState(undefined as any | undefined);
  const [currentShippingLabel, setCurrentShippingLabel] = useState(undefined as any | undefined);
  const [numPages, setNumPages] = useState(0);
  // const [shouldPrint, setShouldPrint] = useState(false);
  const [currentPrintDocument, setCurrentPrintDocument] = useState(undefined as any | undefined);

  const printShippingLabelDoc = useReactToPrint({
    content: () => printShippingLabelRef.current,
    bodyClass: "printable_visible",
  });

  const printManifestDoc = useReactToPrint({
    content: () => printManifestRef.current,
    bodyClass: "printable_visible",
  });

  const printPickList = useReactToPrint({
    content: () => pickListRef.current,
    bodyClass: 'printable_visible'
  })

  // methods
  const handleClose = (): void => {
    if (onClose) onClose();
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
    // if (shouldPrint) {
    //   setTimeout(() => {
    //     if (currentPrintDocument.id === 'shipping_label') {
    //       printShippingLabelDoc();
    //     } else if (currentPrintDocument.id === 'manifest') {
    //       printManifestDoc();
    //     }
    //     setShouldPrint(false);
    //   }, 500);
    // }
  }

  const handlePrintShippingLabel = (document: any) => {
    setCurrentPrintDocument(document);
    // setShouldPrint(true);
    setErrorMessage('');
    return new Promise<any>((resolve, reject) => {
      // deliveries.map((item: IDeliveryBatches) => {
        apiHelper.getDeliveryLabels(document.delivery_batch_id).then(response => {  
          if (response.ok) {
            const labelUrl = URL.createObjectURL(response.body);
            // setCurrentShippingLabel(labelUrl);
            window.open(labelUrl, '_blank')
            resolve(labelUrl);
          } else {
            setErrorMessage(`Error retrieving shipping label.`);
            reject('Error retrieving shipping label');
          }
        });
      // })
    });
  };

  const handleViewManifestDoc = async (document: any) => {
    // setShouldPrint(true);
    setErrorMessage('');
    return new Promise<any>((resolve, reject) => {
      // deliveries.map((item: IDeliveryBatches) => {
        apiHelper.getDeliveryBatch(
          // item.delivery_batch_id
          document.delivery_batch_id
        ).then(res => {
          const data = typeof res.body.data === "string"
            ? JSON.parse(res.body.data)
            : res.body.data;

          if (data) {
            apiHelper.getDeliveryBatchDocument(data?.id).then((response) => {
              if (response.ok) {
                const manifestUrl = URL.createObjectURL(response.body);
                window.open(manifestUrl, '_blank')
                // setCurrentManifest(manifestUrl);
                resolve(manifestUrl);
              } else {
                setErrorMessage(`Error retrieving manifest document: ${response.message}`);
                reject();
              }
            });
          } else {
            setErrorMessage(`Error retrieving delivery batch data.`);
            reject();
          }
          // const manifestDoc = data && apiHelper.getDeliveryBatchDocument(data?.id);
          // setCurrentManifest(manifestDoc);
        });
      // })
    });
  }

  const handlePrintManifestDoc = async (document: any) => {
    setCurrentPrintDocument(document);
    await handleViewManifestDoc(document);
  };
  
  const handleViewDocument = (document: OrderDocument): void => {
    switch (document.id) {
      case 'pick_list':
        (pickListRef.current! as HTMLElement).classList.add('printable_preview');
        setCurrentDocumentPreview(document.id);
        break;
      case 'shipping_label':
        handlePrintShippingLabel(document);
        break;
      case 'manifest':
        (printManifestRef.current! as HTMLElement).classList.add('printable_preview');
        handleViewManifestDoc(document)
        break;
      // case 'return_label':
      //   console.log('todo: handle view return_label');
      //   break;
    }
  }

  const closePreview = () => {
    (pickListRef.current! as HTMLElement).classList.remove('printable_preview');
    setCurrentDocumentPreview('');
  }

  const handlePrintDocument = (document: OrderDocument): void => {
    switch (document.id) {
      case 'pick_list':
        setCurrentDocumentPreview('');
        printPickList();
        break;
      case 'shipping_label':
        handlePrintShippingLabel(document);
        break;
      case 'manifest':
        handlePrintManifestDoc(document);
        break;
      // case 'return_label':
      //   console.log('todo: handle print return_label');
      //   break;
    }
  }

  // computed
  const documents = (): Array<OrderDocument> => {
    const result: Array<OrderDocument> = [];
    
    // pick list
    result.push({
      id: 'pick_list',
      name: 'Pick List',
      generated_at: order.created_at,
      printed: false,
      view: "view",
      image: `${printIcon}`,
    })

    // shipping labels
    if (deliveries) {
      for (const item of deliveries) {
        if (item?.id && item.labels !== null) {
          result.push({
            id: 'shipping_label',
            name: 'Shipping Label',
            generated_at: item?.created_at,
            printed: false,
            view: "view",
            image: `${printIcon}`,
            delivery_batch_id: item.id
          })
        }
      }
    }

    // manifest
    if (deliveries?.length) {
      for (const item of deliveries) {
        if (item?.delivery_batch_id) {
          result.push({
            id: 'manifest',
            name: 'Manifest',
            generated_at: item?.created_at,
            printed: false,
            view: "view",
            image: `${printIcon}`,
            delivery_batch_id: item.delivery_batch_id
          })
        }
      }
    }

    // return label
    // result.push({
    //   id: 'return_label',
    //   name: 'Return Label',
    //   generated_at: null,
    //   printed: null
    // })

    return result;
  };

  return (
    <StyledOrderDocumentsModal className="OrderDocumentsModal">
      <Modal show={show}>
        <Heading heading="Documents" />
        <div className="OrderDocumentsModal_details divider">
          <Badge type={order.delivery_type_code} />
          {(order.delivery_type_code === OrderDeliveryType.Standard || order.delivery_type_code === OrderDeliveryType.Express) && <Badge type={'courier_australiaPost'} />}
          {order.delivery_type_code === OrderDeliveryType.SameDay && <Badge type={'courier_doordash'} />}
          <OrderDetails order={order} phone email bold={false} />
        </div>
        
        <div className="OrderDocumentsModal_table">
          <div className="row header">
            <p className="bold">Document Name</p>
            <p className="bold">Date Generated</p>
            {/* <p className="bold align_center">Printed?</p> */}
          </div>
          {documents().map((document: OrderDocument, i: number) => {
            return (
              <div className="row" key={`document-${i}-${document.id}`}>
                <p className="medium">{document.name}</p>
                <p className="medium">{formatTimestamp(document.generated_at, 'datetime')}</p>
                {/* <p className="medium align_center">
                  {document.printed && <img src={tickIcon} alt="" draggable="false" />}
                </p> */}
                <p className="medium buttonContainer">
                  <span className={`viewButton ${['manifest', 'shipping_label'].includes(document.id) && 'viewButton-hidden'}`} onClick={() => handleViewDocument(document)}>{document.view}</span>
                  <img className="printButton button" src={document.image} onClick={() => handlePrintDocument(document)} alt="Print document" title="Print document" />
                </p>
              </div>
            )
          })}
        </div>

        {errorMessage &&
          <div style={{ marginBottom: '20px' }}>
            <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
              <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="https://apt-support.freshdesk.com/support/tickets/new" target="_blank" rel="noreferrer">here</a>.</p>
            </Alert>
          </div>
        }
        <Button type={ButtonType.Secondary} text="Close" onClick={handleClose} />
      </Modal>

      {/* documents for printing Picklist */}
      <div className="printable_hidden" ref={pickListRef} style={{ border: '5px sold red'}}>
        {currentDocumentPreview !== '' &&
          <img src={closeIcon} alt="Close" className="button top_right" onClick={closePreview} draggable="false" />
        }
        <PickList order={order} />
      </div>

      {/* document for printing Shipping Label */}
      <div className="printable_hidden" ref={printShippingLabelRef}>
        <Document file={currentShippingLabel} options={options} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )}
        </Document>
      </div>
      {/* document for printing Manifest */}
      <div className="printable_hidden" ref={printManifestRef}>
        <Document file={currentManifest} options={options} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )}
        </Document>
      </div>
      {/* <Modal show={showCurrentManifest}>
        <img src={closeIcon} alt="Close" className="ShipmentHistory_modalClose button" onClick={() => setShowCurrentManifest(false)} draggable="false" />
        <pre>{JSON.stringify(currentManifest)}</pre>
      </Modal> */}
    </StyledOrderDocumentsModal>
  );
}

const StyledOrderDocumentsModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    /* .Badge {
      position: absolute;
      top: 60px;
      right: 50px;
    } */

    .OrderDocumentsModal_details {
      margin-top: 28px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .OrderDocumentsModal_table {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 12px;
      margin-bottom: 40px;

      .row {
        display: grid;
        /* grid-template-columns: 130px 130px 65px 1fr; */
        grid-template-columns: 170px 170px 1fr;
        gap: 28px;
        grid-gap: 28px;
        height: 24px;
        align-items: center;

        p {
          font-size: 0.75rem; // 12px
          margin: 0;

          &.buttonContainer {
            display: flex;
            justify-content: space-between;

            .viewButton {
              text-decoration: underline;
              cursor: pointer;
              margin: auto 0;
              padding: 2px 5px;
            }
            
            .viewButton-hidden {
              opacity: 0;
              pointer-events: none;
            }
            
            .printButton {
              cursor: pointer;
              width: 28px;
              height: auto;
              margin-left: 6px;
              padding: 2px 5px;
            }
          }
        }

        &.header {
          height: 49px;

          p {
            font-size: 1rem; // 16px
          }
        }
      }
    }
  }
  .Modal {
    position: relative;

    .ShipmentHistory_modalClose {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
`