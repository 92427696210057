import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';


type UserTimeoutModalProps = {
  show: boolean,
  currentUser: any,
  onContinue: Function,
  onLogout: Function
}

export const UserTimeoutModal: FunctionComponent<UserTimeoutModalProps> = ({ show, currentUser, onContinue, onLogout }) => {
  return (
    <Modal show={show}>
      <StyledUserTimeoutModal className="UserTimeoutModal">
        <Heading
          heading={currentUser !== null ? `Are you still there, ${currentUser.name}?` : 'Are you still there?'}
          subheading="Your session is about to expire. You can stay logged in or change employees."
        />
        <Button text="Yes, Stay Logged In" type={ButtonType.Primary} onClick={onContinue} />
        <Button text="Log in as Another Employee" type={ButtonType.Secondary} onClick={onLogout} />
      </StyledUserTimeoutModal>
    </Modal>
  );
}

const StyledUserTimeoutModal = styled.div`
  .Button_primary {
    margin-top: 45px;
  }
  
  .Button_secondary {
    margin-top: 25px;
  }
`