import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import App from './components/App/App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './assets/css/style.scss';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <GoogleReCaptchaProvider
      // https://www.npmjs.com/package/react-google-recaptcha-v3
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_API_KEY}
      useEnterprise={false}
    > */}
      <App />
    {/* </GoogleReCaptchaProvider> */}
  </React.StrictMode>
);

reportWebVitals();
