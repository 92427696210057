import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

export enum CountdownType {
  Dashboard = "dashboard",
  Order = "order",
}

export enum CountdownColour {
  Positive = "positive",
  Important = "important",
  Alert = "alert",
}

type CountdownProps = {
  type: CountdownType
  pickupAvailable: boolean
  deadlineDate?: Date
  colour?: CountdownColour
  colourOverride?: boolean
}

const timeUnits: Array<string> = [
  'hours',
  'minutes',
  'seconds'
]

const dummyDeadline = (): Date => {
  // const minuteOffset = 37;
  // const secondOffset = 54;
  const minuteOffset = 0;
  const secondOffset = 10;

  const dummyDeadline = new Date();
  dummyDeadline.setSeconds(dummyDeadline.getSeconds() + (minuteOffset * 60) + secondOffset);
  return dummyDeadline;
}

const minTime = -((1000 * 60 * 60 * 99) + (1000 * 60 * 59) + (1000 * 59))

export const Countdown: FunctionComponent<CountdownProps> = ({ type, pickupAvailable, deadlineDate, colour, colourOverride }) => {
  const [deadline, setDeadline] = useState(deadlineDate!);
  // const [deadline, setDeadline] = useState(dummyDeadline());
  const [remaining, setRemaining] = useState(0);
  const [formattedTime, setFormattedTime] = useState(null);

  useEffect(() => {
    initCountdown();
  }, []);

  const initCountdown = (): void => {
    handleInterval();
    // const interval = setInterval(() => {
    setInterval(() => {
      handleInterval();
    }, 1000 / 3)
  }

  const handleInterval = (): void => {
    const remaining = deadline.getTime() - new Date().getTime();
    setRemaining(remaining);
    setFormattedTime(formatTimeRemaining(remaining));
  }

  const formatTimeRemaining = (remaining: number): any => {
    // const clamped = Math.max(remaining, 0);
    const clamped = Math.max(remaining, minTime);

    // use if days are displayed separately
    // const days = Math.floor(clamped / (1000 * 60 * 60 * 24));
    // const hours = Math.floor((clamped % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
    // use if days are not displayed separately
    const hours = Math.floor(Math.abs(clamped) / (1000 * 60 * 60));
    const minutes = Math.floor((Math.abs(clamped) % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.ceil((Math.abs(clamped) % (1000 * 60)) / 1000);

    return {
      // days: Math.abs(days).toString().padStart(2, '0'),
      hours: Math.abs(hours).toString().padStart(2, '0'),
      minutes: Math.abs(minutes).toString().padStart(2, '0'),
      seconds: Math.abs(seconds).toString().padStart(2, '0'),
    }
  };

  return (
    <StyledCountdown className={`Countdown Countdown_${type} Countdown_${colourOverride && remaining < 0 ? 'alert' : colour}`}>
      {(pickupAvailable && type === 'dashboard') && <p>You have</p>}
      {(pickupAvailable && type === 'order') &&
        <>
          {remaining > 0 ?
            <p>Order must be processed within</p>
          :
            <p>Order is late for processing by</p>
          }
        </>
      }

      <div className="Countdown_clock">
        {timeUnits.map((unit: string, i: number) => {
          return (
            <div key={unit} style={{ display: 'flex' }}>
              <div className="Countdown_unit">
                {formattedTime !== null ?
                  <p>
                    <span>{formattedTime[unit as keyof typeof formattedTime][0]}</span>
                    <span>{formattedTime[unit as keyof typeof formattedTime][1]}</span>
                  </p>
                :
                  <p>
                    <span>0</span>
                    <span>0</span>
                  </p>
                }
                <label>{unit}</label>
              </div>
              {i !== 2 && <p className="Countdown_divider">:</p>}
            </div>
          )
        })}
      </div>

      {type === 'dashboard' && (pickupAvailable ? <p>to schedule a pickup for today</p> : <p>until the local Australia Post closes</p>)}
    </StyledCountdown>
  );
}

Countdown.defaultProps = {
  deadlineDate: dummyDeadline(),
  colour: CountdownColour.Important
}

const StyledCountdown = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Countdown_positive {
    background-color: #D6FFEB;

    p, label {
      color: #35C680;
    }

    .Countdown_unit span {
      background-color: #35C680;
    }
  }
  
  &.Countdown_important {
    background-color: #FFE8D2;

    p, label {
      color: #FFA24B;
    }

    .Countdown_unit span {
      background-color: #FFA24B;
    }
  }
  
  &.Countdown_alert {
    background-color: #FDEBEF;

    p, label {
      color: #C72025;
    }

    .Countdown_unit span {
      background-color: #C72025;
    }
  }

  p {
    font-family: "Mulish SemiBold";
  }

  .Countdown_clock {
    margin: 13px 12px 0 12px;
    display: flex;

    .Countdown_unit {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        display: flex;
        margin: 0 0 4px 0;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 35px;
        color: white;
        font-family: "Mulish Bold";
        font-size: 1.25rem; // 20px
        text-align: center;
        border-radius: 2px;

        &:first-child {
          margin-right: 5px;
        }
      }

      label {
        font-size: 0.625rem; // 10px
        text-transform: uppercase;
      }
    }

    .Countdown_divider {
      margin: 4px 5px 0 5px;
      font-size: 1.25rem; // 20px
    }
  }

  &.Countdown_dashboard {
    height: 95px;
  }

  &.Countdown_order {
    height: 57px;
    margin-top: 15px;
    margin-bottom: 24px;

    span {
      height: 26px !important;
      font-size: 1.125rem !important; // 18px
    }

    .Countdown_divider {
      margin-top: -2px !important;
    }

    label {
      font-size: 0.5rem !important; // 8px
    }

    .Countdown_clock {
      margin-right: 0;
      margin-top: 4px;
    }
  }
`