import { FunctionComponent } from "react";
import { useParams, Params, Navigate } from "react-router-dom";

import { IUser } from "../types/IUsers";

import { Body } from "../components/Body/Body";
import { AuthModal } from "../components/AuthModal/AuthModal";

type ResetPasswordPageProps = {
  loggedin: boolean,
  user: IUser | null,
  onLogin: Function,
  onLogout: Function
}

export const ResetPasswordPage: FunctionComponent<ResetPasswordPageProps> = ({ loggedin, user, onLogin, onLogout }) => {
  const { confirm }: Readonly<Params<string>> = useParams();

  return (
    <>
    {loggedin ? 
      <Navigate to="/" />
    :
      <Body loggedin={loggedin} user={user} location={null}>
        <AuthModal type={ confirm ? 'reset-confirm' : 'reset-create' } onLogin={onLogin} />
      </Body>
    }
    </>
  );
}
