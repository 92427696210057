import { FunctionComponent } from 'react';
import { colours, transitions } from '../../assets/css/variables';
import styled from 'styled-components';

export type TabOption = {
  id: string | number
  value: string | number
  label: string
  count?: number
}

type TabsProps = {
  options: Array<TabOption>
  current: string | number
  onSelectTab: Function
}

export const Tabs: FunctionComponent<TabsProps> = ({ options, current, onSelectTab }) => {
  const activeMarkerLeftPos = (): string => {
    const index = options.findIndex(option => option.id === current);
    return `${(100 / options.length) * index}%`;
  }

  return (
    <StyledTabs className="Tabs" options={options}>
      {options.map((option: TabOption, i: number) => {
        return (
          <div
            className={`Tabs_tab ${option.id === current ? 'active' : 'inactive'}`}
            key={`Tab_${i}`}
            onClick={() => onSelectTab(option.id)}
          >
            <p className="bold">
              {option.label}
              {typeof option.count === 'number' && <span> ({option.count})</span>}
            </p>
          </div>
        )
      })}
      <div className="Tabs_activeMarker" style={{ left: activeMarkerLeftPos() }}></div>
    </StyledTabs>
  );
}

const StyledTabs = styled.div<{ options: Array<TabOption> }>`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${colours.lightGrey};

  .Tabs_tab {
    width: ${props => 100 / props.options.length}%;
    text-align: center;
    cursor: pointer;
    user-select: none;

    p {
      font-size: 1rem; // 16px
      transition: color ${transitions.default};
    }

    &.active {
      color: black;
    }
    
    &.inactive {
      color: ${colours.inputOutline}
    }
  }

  .Tabs_activeMarker {
    position: absolute;
    bottom: -1px;
    height: 2px;
    width: ${props => 100 / props.options.length}%;
    border-radius: 2px;
    background-color: black;
    transition: left ${transitions.default};
  }
`