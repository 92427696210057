import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { IOrder, OrderStatus, OrderState, IOrders } from '../../types/IOrders';
import { IUser } from '../../types/IUsers';
import { IDeliveries, IDelivery } from '../../types/IDelivery';

import { IRequestBodyProcessDeliveryCompletion, IRequestBodySendNotification } from '../../types/IRequests';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { useDummyData } from '../../context/DummyDataProvider';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { Badge } from '../Badge/Badge';
import { NotificationType } from '../../types/INotifications';
import { IResponseSendNotification } from '../../types/IResponses';


type ScriptQueueConfirmationModalProps = {
  show: boolean
  order: IOrder
  setDummyStatus?: Function
  onClose?: Function
  orders: any
  setOrders: Function
  user: IUser | null
}

export const ScriptQueueConfirmationModal: FunctionComponent<ScriptQueueConfirmationModalProps> = ({ show, order, setDummyStatus, onClose, orders, setOrders, user }) => {
  const apiHelper: ApiHelper = useApi();
  const dummyData = useDummyData();
  
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // methods
  const successDuration = 3000;
  const handleCompleteProcess = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      setErrorMessage('');
      setIsLoading(true);
      if (dummyData.state.useDummyData) {
        // onRefresh();
        if (setDummyStatus) {
          setDummyStatus('awaiting_pickup').then(() => {
            setShowSuccess(true);
            setIsLoading(false);

            sendNotification();

            setTimeout(() => {
              handleClose();
            }, successDuration);
          })
        };
        resolve('');
      } else {
        setIsLoading(true);
        handleClose();
        // const promises = deliveries.map((delivery: IDelivery) => {
        //   const requestBody: IRequestBodyProcessDeliveryCompletion = {
        //     user_id: user.id,
        //   };
        //   return apiHelper.processDeliveryCompletion(delivery.id, requestBody)
        // })

        // setIsLoading(true);
        // Promise.all(promises).then((responses) => {
        //   setIsLoading(false);
        //   if (responses.every(response => response.status === 200)) {
        //     onRefresh();
        //     setShowSuccess(true);
        //     setTimeout(() => {
        //       handleClose();
        //     }, successDuration);
        //   } else {
        //     setErrorMessage(`Error updating order.`);
        //     reject();
        //   }
        // });
      }
    });
  }

  const sendNotification = async (): Promise<any> => {
    console.log('sendNotification');
    return new Promise<any>((resolve, reject) => {
      const notificationText = `Hi ${order.delivery_firstname},\n\nYour script (#${order.platform_order_no}) is ready for collection.\n\nRegards, Smith’s Pharmacy`;
      console.log(notificationText);
      
      const requestBody: IRequestBodySendNotification = {
        message: notificationText,
        contact: order.delivery_phone,
        notification_type_code: NotificationType.Sms,
        user_id: user!.id,
        partner_id: user!.partner_id
      }
      apiHelper.sendNotification(requestBody).then((response: IResponseSendNotification) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          console.warn(response)
          resolve(response);
        }
      });
    })
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledScriptQueueConfirmationModal className="ScriptQueueConfirmationModal">
      <Modal show={show}>
        {!showSuccess &&
          <>
            <Heading heading={`This will alert the customer that their scripts are ready for collection.`} />
            <div className="ScriptQueueConfirmationModal_details">
              <Badge type="scriptQueue_inStore" />
              <OrderDetails order={order} phone scripts bold={false} />
            </div>
            {errorMessage &&
              <div style={{ marginTop: '30px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="https://apt-support.freshdesk.com/support/tickets/new" target="_blank" rel="noreferrer">here</a>.</p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} tall text="Mark as Ready for Customer Collection" onClick={handleCompleteProcess} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        }
        {showSuccess &&
          <Alert type={AlertType.PositiveSecondary} successModal>
            <p>This script has been marked as ready for collection and the customer has been notified.</p>
          </Alert>
        }
      </Modal>
    </StyledScriptQueueConfirmationModal>
  );
}

const StyledScriptQueueConfirmationModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .ScriptQueueConfirmationModal_details {
      margin-top: 28px;
    }

    .ScriptQueueConfirmationModal_grid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px 14px;
      gap: 25px 14px;
      margin: 50px 0;

      .Button {
        width: 100%;
        height: 128px;
        margin: 0 !important;
      }
    }
  }

  .Button_primary {
    margin-top: 50px;
    margin-bottom: 23px;
  }
`