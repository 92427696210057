import { FunctionComponent, useState, ChangeEvent } from 'react';
import styled from 'styled-components';

import { IOrder, OrderDeliveryType, CourierType, DeliveryModelCode, OrderState, OrderStatus, OrderType } from '../../types/IOrders';
import { IRequestBodyUpdateOrder } from '../../types/IRequests';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { SelectInput, SelectInputOption } from '../SelectInput/SelectInput';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { Badge } from '../Badge/Badge';
import { OrderDetails } from '../OrderDetails/OrderDetails';


const getMethodLabel = (deliveryType: OrderDeliveryType): string => {
  switch (deliveryType) {
    case OrderDeliveryType.Standard:
      return 'Standard Delivery';
    case OrderDeliveryType.Express:
      return 'Express Delivery';
    case OrderDeliveryType.SameDay:
      return 'Same-Day Delivery';
    case OrderDeliveryType.Pickup:
      return 'Click & Collect';
    case OrderDeliveryType.Custom:
      return 'Custom Courier';
    default:
      return '';
  }
}

const methodOptions: Array<SelectInputOption> = Object.values(OrderDeliveryType).map((deliveryType) => {
  return {
    value: deliveryType,
    label: getMethodLabel(deliveryType)
  }
});

type ChangeDeliveryMethodModalProps = {
  show: boolean
  order: IOrder
  onClose?: Function
  onRefresh: Function
}

export const ChangeDeliveryMethodModal: FunctionComponent<ChangeDeliveryMethodModalProps> = ({ show, order, onClose, onRefresh }) => {
  
  // Context
  const apiHelper: ApiHelper = useApi();

  // Initial Value
  const initialDeliveryType = order.delivery_type_code;
  
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [updatedData, setUpdateddata] = useState< any >()
  const [selectedMethod, setSelectedMethod] = useState(initialDeliveryType);

  // computed
  const validChanges = (): boolean => {
    const fieldsChanged = selectedMethod !== initialDeliveryType
    return (fieldsChanged);
  }

  // methods
  const successDuration = 3000;
  const handleChangeDeliveryMethod = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      setErrorMessage('');
      // validate order
      if (
        (
          order.completed_at === null &&
          [OrderStatus.New, OrderStatus.Fraud, OrderStatus.AwaitingPayment, OrderStatus.OnHold, OrderStatus.AwaitingReview].includes(order.status_code) &&
          order.order_type_code === OrderType.Sale &&
          order.order_state_code === OrderState.New
        ) === false
      ) {
        console.log('Order not in valid state to change delivery method.');
        reject('Order not in valid state to change delivery method.');
      }

      // set delivery and courier type codes
      let courierTypeCode = undefined;
      switch (selectedMethod) {
        case OrderDeliveryType.Standard:
          courierTypeCode = CourierType.AustraliaPost;
          break;
        case OrderDeliveryType.Express:
          // courierTypeCode = CourierType.Aramex;
          courierTypeCode = CourierType.AustraliaPost;
          break;
        case OrderDeliveryType.SameDay:
          courierTypeCode = CourierType.Doordash;
          break;
        case OrderDeliveryType.Custom:
          courierTypeCode = CourierType.Custom;
          break;
        case OrderDeliveryType.Pickup:
          courierTypeCode = undefined;
          break;
      }

      const requestBody: IRequestBodyUpdateOrder = {
        delivery_type_code: selectedMethod,
        delivery_model_code: null,
        courier_type_code: courierTypeCode
      }

      setIsLoading(true);
      apiHelper.updateOrder(order.id, requestBody).then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          onRefresh();
          setShowSuccess(true);
          setTimeout(() => {
            handleClose();
          }, successDuration);
          resolve(response.body.data);
          const data = typeof response.body.data === "string"
            ? JSON.parse(response.body.data)
            : response.body.data;
          setUpdateddata(data);
        } else {
          setErrorMessage(`Error updating delivery method: ${response.body.message}`);
          reject(response.body.message);
        }
      });
    });
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      setSelectedMethod(initialDeliveryType);
      // reset success message
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledChangeDeliveryMethodModal className="EditDetails">
      <Modal show={show}>
        {!showSuccess ?
          <>
            <Heading heading="Change delivery method" />
            <div className="EditDetails_details divider">
              <Badge type={order.delivery_type_code} />
              {(order.delivery_type_code === OrderDeliveryType.Standard || order.delivery_type_code === OrderDeliveryType.Express) && <Badge type={'courier_australiaPost'} />}
              {order.delivery_type_code === OrderDeliveryType.SameDay && <Badge type={'courier_doordash'} />}
              <OrderDetails order={order} phone email bold={false} />
            </div>
            
            <form>
              <SelectInput 
                id="changeDelivery" 
                label="Select from available Delivery Methods" 
                options={methodOptions} 
                value={selectedMethod} 
                onChange={(e: ChangeEvent) => setSelectedMethod((e.target as HTMLInputElement).value as OrderDeliveryType)} 
              />
            </form>
            {errorMessage &&
              <div style={{ marginTop: '20px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="https://apt-support.freshdesk.com/support/tickets/new" target="_blank" rel="noreferrer">here</a>.</p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} text="Save Changes" onClick={handleChangeDeliveryMethod} disabled={!validChanges()} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        :
          <>
            <Alert type={AlertType.PositiveSecondary} successModal>
              <p>This order has been changed to <span className="extrabold">{getMethodLabel(selectedMethod)}</span></p>
            </Alert>
          </>
        }
      </Modal>
    </StyledChangeDeliveryMethodModal>
  );
}

const StyledChangeDeliveryMethodModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;
    
    .EditDetails_details {
      margin-top: 28px;
      /* margin-bottom: 38px; */

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      margin-top: 40px;

      /* address lines */
      div:nth-child(1) {
        grid-column: span 2;
      }
      div:nth-child(2) {
        margin-top: -15px;
        grid-column: span 2;
      }
    }

    .Button_primary {
      margin-top: 50px;
      margin-bottom: 23px;
    }
  }
`