
import { Timestamp } from "./Timestamp"

export enum RefundReason {
  Duplicate = "duplicate",
  Fraud = "fraud",
  Stock = "stock",
  Delivery = "delivery",
  Ops = "ops",
  CustomerRequest = "customer_request",
  CustomerChangeMind = "customer_change_mind",
  CustomerMistake = "customer_mistake",
  FulfilmentWrongProduct = "fulfilment_wrong_product",
  FulfilmentFaultyProduct = "fulfilment_faulty_product",
  Other = "other"
}

export interface RefundItem {
  order_item_id: number
  refund_qty: number
}

export interface RefundItems extends Array<RefundItem>{}

export interface IRefund {
  id: number
  created_at: Timestamp
  updated_at: Timestamp | null
  ordered_at: Timestamp
  refunded_at: Timestamp | null
  order_id: number
  transaction_id: string | null
  location_code: string | null
  status_code: string | null
  refund_reason_code: RefundReason | null
  refund_method_code: string | null
  platform_order_id: number | null
  platform_order_no: string | null
  platform_payment_method: string | null
  customer_ip: string | null
  customer_email: string | null
  customer_firstname: string | null
  customer_lastname: string | null
  total: number | null
  tax: number | null
  discount: number | null
  discount_tax_offset: number | null
  delivery: number | null
  delivery_tax: number | null
  delivery_discount: number | null
  delivery_discount_tax_offset: number | null
  billing_firstname: string | null
  billing_lastname: string | null
  billing_email: string | null
  billing_phone: string | null
  billing_street: string | null
  billing_city: string | null
  billing_state_code: string | null
  billing_postcode: string | null
  billing_country_code: string | null
  user_id: number | null
  integration_id: number | null
  partner_id: number
  is_visible: boolean
  platform_invoice_id: number | null
  refund_adjustment: number | null
  refund_delivery: number | null
}

export interface IRefunds extends Array<IRefund>{}
