import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { IOrder, OrderDeliveryType } from '../../types/IOrders';
import { ILocation, ILocations, LocationCode } from '../../types/ILocations';
import { IRequestBodyUpdateOrder } from '../../types/IRequests';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { useDummyData } from '../../context/DummyDataProvider';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { Badge } from '../Badge/Badge';


type TransferOrderModalProps = {
  show: boolean
  order: IOrder
  partnerLocations: ILocations | null
  onClose?: Function
  onRefresh: Function
}

export const TransferOrderModal: FunctionComponent<TransferOrderModalProps> = ({ show, order, partnerLocations, onClose, onRefresh }) => {
  const apiHelper: ApiHelper = useApi();
  const dummyData = useDummyData();
  
  // state
  const [transferLocation, setTransferLocation] = useState<ILocation | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  // methods
  const successDuration = 3000;
  const handleTransferOrder = (locationCode: LocationCode): Promise<any> => {
    setErrorMessage('');
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        // dummyData.mutations.setOrderStatus(order.id, orderStatus);
        resolve('');
      } else {
        const requestBody: IRequestBodyUpdateOrder = {
          location_code: locationCode
        }
        setIsLoading(true);
        apiHelper.updateOrder(order.id, requestBody).then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.status === 200) {
            onRefresh();
            setShowSuccess(true);
            setTimeout(() => {
              handleClose();
            }, successDuration);
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error transferring order: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      setShowSuccess(false);
      setTransferLocation(null);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledTransferOrderModal className="TransferModal">
      <Modal show={show}>
          {(transferLocation === null && !showSuccess) &&
            <>
              <Heading heading="Which store would you like to transfer this order to?" />
              <div className="TransferModal_details divider">
                <Badge type={order.delivery_type_code} />
                {(order.delivery_type_code === OrderDeliveryType.Standard || order.delivery_type_code === OrderDeliveryType.Express) && <Badge type={'courier_australiaPost'} />}
                {order.delivery_type_code === OrderDeliveryType.SameDay && <Badge type={'courier_doordash'} />}
                <OrderDetails order={order} phone email bold={false} />
              </div>
              <div className="TransferModal_grid">
                {partnerLocations &&
                  <>
                    {partnerLocations!
                      .filter(location => location.is_enabled)
                      .map((location: ILocation, i: number) => {
                        return (
                          <Button
                            key={`location-${location.id}`}
                            type={ButtonType.Primary}
                            text={location.name}
                            disabled={location.code === order.location_code}
                            onClick={() => setTransferLocation(location)}
                          />
                        )
                    })}
                  </>
                }
              </div>
              {errorMessage &&
                <div style={{ marginBottom: '20px' }}>
                  <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                    <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="https://apt-support.freshdesk.com/support/tickets/new" target="_blank" rel="noreferrer">here</a>.</p>
                  </Alert>
                </div>
              }
              <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
            </>
          }
          {(transferLocation !== null && !showSuccess) &&
            <>
              <Heading heading={`Are you sure you want to transfer this order to ${transferLocation.name}?`} />
              <div className="TransferModal_details divider">
                <OrderDetails order={order} phone email bold={false} />
              </div>
              <Button type={ButtonType.Primary} text="Yes, I'm Sure" onClick={() => handleTransferOrder(transferLocation.code)} loading={isLoading} />
              <Button type={ButtonType.Secondary} text="Back" onClick={() => setTransferLocation(null)} />
            </>
          }
          {(transferLocation !== null && showSuccess) &&
            <>
              <Alert type={AlertType.PositiveSecondary} successModal>
                <p>This order has been transferred to <span className="extrabold">{transferLocation.name}</span></p>
              </Alert>
            </>
          }
        </Modal>
    </StyledTransferOrderModal>
  );
}

const StyledTransferOrderModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .TransferModal_details {
      margin-top: 28px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .TransferModal_grid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px 14px;
      gap: 25px 14px;
      margin: 50px 0;

      .Button {
        width: 100%;
        height: 128px;
        margin: 0 !important;
      }
    }
  }

  .Button_primary {
    margin-top: 50px;
    margin-bottom: 23px;
  }
`