import { Config } from './Config/Config';


interface IDefinitions {
  website_name: string,
  user: string,
  users: string,
  order: string,
  orders: string,
  ordersSmall: string,
}

interface IL18n {
  defs: IDefinitions,
  label: {
    [key: string]: string,
  },
}

const definitions: IDefinitions = {
  website_name: 'SparrowHub',
  user: 'Partner',
  users: 'Partners',
  order: 'Order',
  orders: 'Orders',
  ordersSmall: 'orders',
}

export const l18n: IL18n = {
  defs: definitions,
  label: {
    website_name: definitions.website_name,
    website_version: Config.version,
    website_url: 'partner.sparrowhub.com.au',
    website_url_full: 'https://partner.sparrowhub.com.au',
    login: 'Sign in',
    logout: 'Sign out',
    welcome: `Welcome to ${definitions.website_name}`,
    footer_terms: `2023 ${definitions.website_name}. All rights reserved.`,
    result_orders_unavailable: `There are no ${definitions.orders} available`,
    result_orders_unavailable_modified: `It’s time to relax - there are no new ${definitions.ordersSmall} that require action.`
  },
}
