import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { formatPrice } from '../../helpers/utils';

import { QuantitySelector } from '../QuantitySelector/QuantitySelector';
import { Button, ButtonType } from '../Button/Button';

import tagCheckIcon from '../../assets/images/tags/Check.svg';
import tagCrossIcon from '../../assets/images/tags/Cross.svg';
import { ILocations, ILocation } from '../../types/ILocations';

type ProductTileProps = {
  product: any
  createOrderItems: Array<any>
  onAddProduct: Function
  partnerLocations: ILocations
  showAllStock?: boolean
}

export const ProductTile: FunctionComponent<ProductTileProps> = ({ product, createOrderItems, onAddProduct, partnerLocations, showAllStock }) => {
  // state
  const [quantity, setQuantity] = useState(product.stock[0] > 0 ? 1 : 0);

  // computed
  const minQty = (): number => {
    // if no stock available or all available stock already in cart, return 0
    // else, return 1
    return product.stock[0] === 0 || maxQty() === 0
      ? 0
      : 1
  }
  
  const maxQty = (): number => {
    const itemInCart = createOrderItems.find(item => item.sku === product.sku);
    if (!itemInCart) {
      return product.stock[0];
    } else {
      return product.stock[0] - itemInCart.qty_ordered;
    }
  }

  // methods
  const handleAddProduct = (): void => {
    const productObject = {
      name: product.name,
      sku: product.sku,
      total: product.total,
      qty_ordered: quantity,
      max_qty: maxQty()
    }
    onAddProduct(productObject);
  }

  return (
    <StyledProductTile className="ProductTile" key={product.id}>
      <img className="ProductTile_image" src={product.img_src} alt={product.name} title={product.name} />
      <div className="ProductTile_brandContainer">
        <p className="ProductTile_brand light">
          <img className="ProductTile_brandLogo" src={product.brand_src} alt={product.brand} title={product.brand} />
          {/* {product.brand} */}
          {maxQty()}
        </p>
        <p className="ProductTile_sku light">{product.sku}</p>
      </div>
      <p className="ProductTile_name bold">{product.name}</p>
      <div className="ProductTile_priceContainer">
        <p className="ProductTile_price bold">{formatPrice(product.total)}</p>
        {!showAllStock &&
          <p className="ProductTile_stock bold">
            {product.stock[0] > 0 ?
              <>
                <img src={tagCheckIcon} alt="" draggable="false" />
                {product.stock[0]} In Stock
              </>
            :
              <>
                <img src={tagCrossIcon} alt="" draggable="false" />
                Out of Stock
              </>
            }
          </p>
        }
      </div>
      <div className="ProductTile_action">
        {!showAllStock ?
          <>
            <QuantitySelector label="Quantity" quantity={minQty() === 0 && maxQty() === 0 ? 0 : quantity} onChange={setQuantity} min={minQty()} max={maxQty()} disabled={minQty() === 0 && maxQty() === 0} />
            <Button type={ButtonType.Primary} text="Add to Order" small onClick={handleAddProduct} />
          </>
        :
          <>
            <p className="ProductTile_stockLabel semibold">Stock available at the following stores:</p>
            {partnerLocations.map((location: ILocation, i: number) => {
              return (
                <p className="ProductTile_stock bold" key={location.code}>
                  {product.stock[i] > 0 ?
                    <img src={tagCheckIcon} alt="" draggable="false" />
                  :
                    <img src={tagCrossIcon} alt="" draggable="false" />
                  }
                  {product.stock[i]} - {location.name}
                </p>
              )
            })}
          </>
        }
      </div>
    </StyledProductTile>
  );
}

const StyledProductTile = styled.div`
  background: white;
  border-radius: 6px;
  padding: 11px 11px 24px 11px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);

  .ProductTile_image {
    height: 109px;
    width: 100%;
    object-fit: contain;
  }

  .ProductTile_brandContainer {
    display: flex;
    justify-content: space-between;

    .ProductTile_brand {
      display: flex;
      align-items: center;
      font-size: 0.5rem; // 8px
      margin: 6px 0;

      .ProductTile_brandLogo {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    .ProductTile_sku {
      font-size: 0.5rem; // 8px
    }
  }

  .ProductTile_name {
    font-size: 0.75rem; // 12px
    min-height: 30px;
    margin-top: 0;
  }

  .ProductTile_priceContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #DADADA;
    padding-bottom: 12px;

    .ProductTile_price {
      font-size: 1.125rem; // 18px
      margin: 0;
    }
  }

  .ProductTile_stockLabel {
    font-size: 0.5rem; // 8px
    margin: 16px 0 6px 0;
  }

  .ProductTile_stock {
    display: flex;
    align-items: center;
    font-family: "Mulish Bold";
    height: 23px;
    font-size: 0.5rem; // 8px
    margin: 0;

    img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }

  .ProductTile_action {
    height: 119px;

    .ProductTile_stock {
      /* margin-left: 6px; */
    }
  }

  .QuantitySelector {
    margin: 8px auto 16px auto;
  }
`