import { FunctionComponent } from "react";
import { useParams, Params } from "react-router-dom";

import { IUser } from "../types/IUsers";

import { Body } from "../components/Body/Body";
import { AuthModal } from "../components/AuthModal/AuthModal";


type LogoutPageProps = {
  loggedin: boolean,
  user: IUser | null,
  onLogout: Function
}

export const LogoutPage: FunctionComponent<LogoutPageProps> = ({ loggedin, user, onLogout }) => {
  const { auto }: Readonly<Params<string>> = useParams();

  return (
    <>
      <Body loggedin={loggedin} user={user} location={null}>
        <AuthModal type={auto ? 'logout-auto' : 'logout'} />
      </Body>
    </>
  );
}
