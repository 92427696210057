import { FunctionComponent } from 'react';
import styled from 'styled-components';


type MultiModalProps = {
  children: any,
}

export const MultiModal: FunctionComponent<MultiModalProps> = ({ children }) => {
  return (
    <StyledMultiModal>
      {children}
    </StyledMultiModal>
  );
}

const StyledMultiModal = styled.div`
  .Modal_overlay:not(:first-child) {
    background: transparent;
  }
`