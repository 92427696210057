import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colours } from '../../assets/css/variables';


type DashboardCountProps = {
  value: any,
  small?: boolean,
  noAnimation?: boolean,
}

type AnimationState = {
  className: string,
  delay: number,
  duration: number
}

const animationStates: Array<AnimationState> = [
  {
    className: 'animState_0_initial',
    delay: 0,
    duration: 0,
  },
  {
    className: 'animState_1_maximum',
    delay: 0,
    duration: 100,
  },
  {
    className: 'animState_2_minimum',
    delay: 0,
    duration: 500,
  },
  {
    className: 'animState_3_complete',
    delay: 0,
    duration: 500,
  },
  // final resting state
  {
    className: 'ready',
    delay: 0,
    duration: 0,
  }
];

export const DashboardCount: FunctionComponent<DashboardCountProps> = ({ value, small, noAnimation }) => { 
  const [hasMounted, setHasMounted] = useState(false);
  const [animationState, setAnimationState] = useState('ready');
  const [prevValue, setPrevValue] = useState(-1);

  // trigger animation when value prop changes
  useEffect(() => {
    // ignore first instance when component mounts
    if (hasMounted) {
      // suppress if noAnimation
      if (!noAnimation && value !== undefined) {
        // only trigger if animation is not currently playing
        if (animationState === 'ready' && prevValue !== value) {
          triggerAnimation();
        }
      }
    } else {
      if (value !== undefined) {
        setHasMounted(true);
      }
    }

    setPrevValue(value);
  }, [value]);

  // trigger class changes as defined in animationStates
  const triggerAnimation = (): void => {
    let runningDelay = 0;

    animationStates.forEach(state => {
      runningDelay += state.delay;

      setTimeout(() => {
        setAnimationState(state.className)
      }, runningDelay);

      runningDelay += state.duration;
    })
  }

  return (
    <>
      <StyledDashboardCount className={`DashboardCount ${animationState} ${small ? 'small' : ''}`}>
        <div className={`DashboardCount_value`}>{value}</div>
        <div className={`DashboardCount_animation`}>
          {[...Array(12)].map((e: any, i: number) => {
            return (
              <div className="DashboardCount_animationCircle" key={`animationCircle_${i}`}></div>
            )
          })}
        </div>
      </StyledDashboardCount>
    </>
  );
}

const StyledDashboardCount = styled.div`
  position: relative;
  display: inline-block;

  .DashboardCount_value {
    font-family: "Mulish Bold";
    font-size: 1.875rem; // 30px
  }

  .DashboardCount_animation {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 65px;
    height: 65px;
    transform: translate(-50%, 50%) scale(0);
    pointer-events: none;

    .DashboardCount_animationCircle {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: ${colours.positive};
      border-radius: 100%;
    }
  }

  /* animation states */

  &.animState_0_initial {
    .DashboardCount_value {
      transition: color ${animationStates[0].duration}ms ease-out;
    }
    .DashboardCount_animation {
      transition: transform ${animationStates[0].duration}ms ease-out;
      transform: translate(-50%, 50%) scale(0);

      .DashboardCount_animationCircle {
        transition: width ${animationStates[0].duration}ms ease-out, height ${animationStates[0].duration}ms ease-out;
        width: 0;
        height: 0;
      }
    }
  }

  &.animState_1_maximum {
    .DashboardCount_value {
      transition: color ${animationStates[1].duration}ms ease-out;
      color: ${colours.positive};
    }
    .DashboardCount_animation {
      transition: transform ${animationStates[1].duration}ms ease-out;
      transform: translate(-50%, 50%) scale(1);

      .DashboardCount_animationCircle {
        transition: width ${animationStates[1].duration}ms ease-out, height ${animationStates[1].duration}ms ease-out;
        width: 9px;
        height: 9px;
      }
    }
  }

  &.animState_2_minimum {
    .DashboardCount_value {
      transition: color ${animationStates[2].duration}ms ease-out;
      color: ${colours.positive};
    }
    .DashboardCount_animation {
      transition: transform ${animationStates[2].duration}ms ease-out;
      transform: translate(-50%, 50%) scale(1.2);

      .DashboardCount_animationCircle {
        transition: width ${animationStates[2].duration}ms ease-out, height ${animationStates[2].duration}ms ease-out;
        width: 2px;
        height: 2px;
      }
    }
  }

  &.animState_3_complete {
    .DashboardCount_value {
      transition: color ${animationStates[3].duration}ms ease-out;
    }
    .DashboardCount_animation {
      transition: transform ${animationStates[3].duration}ms ease-out ${animationStates[3].duration}ms;
      transform: translate(-50%, 50%) scale(0);

      .DashboardCount_animationCircle {
        transition: width ${animationStates[3].duration}ms ease-out, height ${animationStates[3].duration}ms ease-out;
        width: 0;
        height: 0;
      }
    }
  }

  /* small size for secondary dashboard tiles */
  
  &.small {
    .DashboardCount_value {
      font-size: 1.375rem; // 22px
    }
  }
`