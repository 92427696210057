import { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useDummyData } from '../../context/DummyDataProvider';

import { IUser, UserRoleCode } from '../../types/IUsers';
import { ILocation, ILocations } from '../../types/ILocations';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';

import { Button, ButtonType } from '../Button/Button';
import { DigitInput } from '../DigitInput/DigitInput';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';

// import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Heading } from "../Heading/Heading";
import { InputField } from "../InputField/InputField";


type AuthModalProps = {
  type: string
  onLogin?: Function
  onLogout?: Function
  onSelectLocation?: Function
  onSelectUser?: Function
  partnerLocations?: ILocations
  onSetPartnerLocations?: Function
  user?: IUser | null
}

export const AuthModal: FunctionComponent<AuthModalProps> = ({ type, onLogin, onLogout, onSelectLocation, onSelectUser, partnerLocations, onSetPartnerLocations, user }) => {
  const apiHelper: ApiHelper = useApi();
  const dummyData: any = useDummyData();

  // const [email, setEmail] = useState(dummyData.state.useDummyData ? 'user@rivalsoftware.com.au' : '');
  // const [password, setPassword] = useState(dummyData.state.useDummyData ? 'passwordpassword' : '');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userid, setUserid] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // handle persistent login
  useEffect(() => {
    if (apiHelper && apiHelper !== null && type === 'login') {
      if (dummyData.state.useDummyData) {
        // handleDummyLogin();
      } else {
        handleGetCurrentUser(true);
      }
    }
  }, [apiHelper, dummyData.state.useDummyData, type]);

  const handleDummyLogin = (): void => {
    onSetPartnerLocations!(dummyData.state.locations);
    onSelectLocation!(dummyData.state.locations[0]);
    onLogin!(dummyData.state.dummyUser, dummyData.state.locations);
    setIsLoading(false);
  }

  const handleLogin = async () => {
    setErrorMessage('');
    if (dummyData.state.useDummyData) {
      // prev fake login
      handleDummyLogin();

      // hybrid dummy login
      apiHelper.loginUser({
        email: email,
        password: password
      }).then((response) => {
        if (response.status === 200) {
          // handleGetCurrentUser();
          handleDummyLogin();
        } else {
          if (response.status === 400) {
            setErrorMessage('Your email or password is incorrect.');
          } else if (response.status === 500) {
            setErrorMessage('An unexpected error has occurred.');
          }
          setIsLoading(false);
          console.log(response);
        }
      });
    } else {
      setIsLoading(true);
      apiHelper.loginUser({
        email: email,
        password: password
      }).then((response) => {
          if (response.status === 200) {
            handleGetCurrentUser();
          } else {
            if (response.status === 400) {
              setErrorMessage('Your email or password is incorrect.');
            } else if (response.status === 500) {
              setErrorMessage('An unexpected error has occurred.');
            }
            setIsLoading(false);
            console.log(response);
          }
        });
    }
  }

  const handleGetCurrentUser = async (autoLogin = false) => {
    if (autoLogin) setIsLoading(true);
    apiHelper.getCurrentUser().then((response) => {
      if (response.status === 200) {
        const currentUser: IUser = JSON.parse(response.body.data!);
        handleGetPartnerLocations(currentUser, autoLogin);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleGetPartnerLocations = async (req_user: IUser, autoLogin = false): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        resolve(dummyData.state.locations);
      } else {
        apiHelper.getPartnerLocations(req_user.partner_id).then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            const partnerLocations = JSON.parse(response.body.data!);
            onSetPartnerLocations!(partnerLocations);
            onLogin!(req_user, partnerLocations)
            resolve(partnerLocations);
          } else {
            console.log(response);
            reject(response.body.message);
          }
        });
      }
    });
  }

  const handleLogout = async () => {
    if (dummyData.state.useDummyData) {
      onLogout!();
    } else {
      apiHelper.logoutUser().then((response) => {
        if (response.status === 200) {
          onLogout!();
        } else {
          console.log(response);
        }
      });
    }
  }

  const handleSelectLocation = (location: any): void => {
    onSelectLocation!(location);
  }

  const handleSelectUser = (): void => {
    const user = { name: 'Hamish' };
    onSelectUser!(user);
  }

  // const handleVerify = () => {
  //   console.log('google recaptcha handle verify');
  // }

  const handleCreatePasswordReset = () => {
    console.log('create password reset!');
  }

  const handleConfirmPasswordReset = () => {
    console.log('confirm password reset!');
  }

  // const getApplicationData = (user: IUser, location: ILocation): void => {
  //   getPartnerLocations(user.partner_id).then(locations => onSetPartnerLocations!(locations));
  // }

  return (
    <StyledAuthModal>
      {/* Login */}
      {type === 'login' &&
        <>
          <Heading heading="Log in to SparrowHub" subheading="Enter your store details to log in." />
          {errorMessage &&
            <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
              <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="https://apt-support.freshdesk.com/support/tickets/new" target="_blank" rel="noreferrer">here</a>.</p>
            </Alert>
          }
          <form>
            <InputField type="text" id="email" label="Email" value={email} onChange={(e: ChangeEvent) => setEmail((e.target as HTMLInputElement).value)} />
            <InputField type="password" id="password" label="Password" value={password} onChange={(e: ChangeEvent) => setPassword((e.target as HTMLInputElement).value)} />
            {dummyData.state.useDummyData && <NavLink to="/reset-password" className="forgot_password bold">Forgot Password?</NavLink>}
            <Button type={ButtonType.Primary} text="Log In" onClick={handleLogin} loading={isLoading} submit />
          </form>
        </>
      }

      {/* Logout */}
      {type === 'logout' &&
        <>
          <Heading heading="Log out" subheading="You have been successfully logged out." />
          <NavLink to="/" className="bold">Return home</NavLink>
        </>
      }
      
      {/* Auto logout */}
      {type === 'logout-auto' &&
        <>
          <Heading heading="Session expired" subheading="Your session has expired. Please log in again to continue." />
          <NavLink to="/" className="bold">Log in</NavLink>
        </>
      }

      {/* Create Password Reset */}
      {type === 'reset-create' &&
        <>
          <Heading heading="Reset Password" subheading="Enter your email address to reset your password." />
          <form>
            <InputField type="email" id="email" label="Email"></InputField>
            <NavLink to="/reset-password/confirm">
              <Button type={ButtonType.Primary} text="Send email" onClick={handleCreatePasswordReset} submit />
            </NavLink>
          </form>
        </>
      }

      {/* Confirm Password Reset */}
      {type === 'reset-confirm' &&
        <>
          <Heading heading="Reset Password" subheading="Enter and confirm your new password." />
          <form>
            <InputField type="password" id="password" label="New Password"></InputField>
            <InputField type="password" id="confirm-password" label="Confirm Password"></InputField>
            {/* <GoogleReCaptcha onVerify={handleVerify} /> */}
            <NavLink to="/">
              <Button type={ButtonType.Primary} text="Reset Password" onClick={handleConfirmPasswordReset} submit />
            </NavLink>
          </form>
        </>
      }

      {/* Select Location */}
      {type === 'location' &&
        <>
          <Heading heading="Which store are you shipping from?" subheading="Select your store to view your orders." />
          <div className="select_location_grid">
            {/* Only display disabled Locations for admin users */}
            {(user?.role_code && user?.role_code === UserRoleCode.Admin) ?
              <>
                {/* Admins */}
                {partnerLocations!.map((location: ILocation, i: number) => {
                  return (<Button type={ButtonType.Primary} text={location.name} onClick={() => handleSelectLocation(location)} key={location.name} />)
                })}
              </>
            :
              <>
                {/* Users */}
                {partnerLocations!.filter(location => location.is_enabled).map((location: ILocation, i: number) => {
                  return (<Button type={ButtonType.Primary} text={location.name} onClick={() => handleSelectLocation(location)} key={location.name} />)
                })}
              </>
            }
          </div>
        </>
      }

      {/* Switch Sub-User */}
      {type === 'user' &&
        <>
          <Heading heading="Enter your user ID code" subheading="This is a 4 digit code unique to you." />
          <form>
            <DigitInput id="userid" length={4} value={userid} onChange={(value: string) => setUserid(value)} />
            <Button type={ButtonType.Primary} text="Next" onClick={handleSelectUser} submit />
            <Button type={ButtonType.Secondary} text="Log Out" onClick={handleLogout} />
          </form>
        </>
      }
    </StyledAuthModal>
  );
}

const StyledAuthModal = styled.div`
  width: 100%;
  padding: 83px 52px;
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  background: white;
  box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);

  a {
    font-size: 0.8125rem; // 13px
  }

  form, .select_location_grid {
    margin-top: 55px;
  }

  label {
    display: block;
  }

  input {
    display: block;
    width: 100%;
  }

  .Alert {
    margin-top: 15px;
    margin-bottom: -20px;
  }

  .forgot_password {
    display: inline-block;
    font-size: 0.8125rem; // 13px
  }

  .Button_primary {
    margin-top: 55px;
  }

  .Button_secondary {
    margin-top: 25px;
  }

  .select_location_grid {
    display: grid;
    grid-gap: 25px 14px;
    gap: 25px 14px;
    grid-template-columns: 1fr 1fr;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 230px;
      height: 128px;
      margin-top: 0;
      padding: 30px;
    }
  }
`