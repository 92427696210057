import { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';


type DigitInputProps = {
  id: string,
  label?: string,
  length: number,
  value: any,
  onChange: Function
}

export const DigitInput: FunctionComponent<DigitInputProps> = ({ id, label, length, value, onChange }) => {
  const [digits, setDigits] = useState(Array(length).join('.').split('.'));

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  });

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Backspace') {
      if (document.activeElement&& document.activeElement.id.includes('digitInput-')) {
        event.preventDefault();
        const currentIndex = Number.parseInt(document.activeElement.id.split('-').pop()!);
        handleSetDigit(currentIndex, '');

        const prevInput = document.getElementById(`digitInput-${currentIndex - 1}`);
        if (prevInput) prevInput.focus();
      }
    }
  };

  const handleSetDigit = (i: number, value: string): void => {
    // validate value
    if (!'0123456789'.includes(value) && value !== '' && value.length <= 1) return;
    
    let digit;
    if (value.length > 1) {
      digit = value.split('').pop();
    } else if (value !== '') {
      digit = value.trim();
    } else {
      digit = '';
    }

    // set digit
    const newDigits = JSON.parse(JSON.stringify(digits));
    newDigits[i] = digit;
    setDigits(newDigits);

    // autofocus next
    const nextInput = document.getElementById(`digitInput-${i + 1}`);
    const prevInput = document.getElementById(`digitInput-${i - 1}`);

    if (digit !== '') {
      if (nextInput) nextInput.focus();
    } else {
      // if (prevInput) prevInput.focus();
    }

    // call onChange
    onChange(newDigits.join(''));
  }

  return (
    <>
      {label && <label>{label}</label> }
      <StyledDigitInput>
        {digits.map((digit: any, i: number) => {
          return (
            <input
              key={`digitInput-${i}`}
              id={`digitInput-${i}`}
              type="text"
              pattern="[0-9]"
              inputMode="decimal"
              min="0" max="9"
              value={digit}
              onChange={(e: ChangeEvent) => handleSetDigit(i, (e.target as HTMLInputElement).value)}
            />
          )
        })}
      </StyledDigitInput>
    </>
  );
}

const StyledDigitInput = styled.div`
  width: 100%;
  display: flex;
  gap: 42px;

  input {
    height: 116px;
    display: flex;
    place-content: center;
    text-align: center;

    font-size: 2.1875rem; // 35px
  }
`