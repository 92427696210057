import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

import { IUser } from "../types/IUsers";

// import { Body } from "../components/Body/Body";
// import { Heading } from "../components/Heading/Heading";


type HomePageProps = {
  loggedin: boolean,
  user: IUser | null,
  location: any,
  selectedUser: any,
  onSelectLocation: Function,
  onSelectUser: Function,
  onLogout: Function
}

export const HomePage: FunctionComponent<HomePageProps> = ({ loggedin, user, location, selectedUser, onSelectLocation, onSelectUser, onLogout }) => {
  return (
    <>
      {!loggedin || !location || !selectedUser ? 
        <Navigate to="/login" />
      : 
        <>
          <Navigate to="/orders" />
          {/* <Body loggedin={loggedin}>
            <Heading heading="Hello" />
          </Body> */}
        </>
      }
    </>
  );
}
