import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colours } from '../../assets/css/variables';
import { getOrderUrgency } from '../../helpers/utils';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';

import { OrderDeliveryType, OrderStatus, OrderUrgency } from '../../types/IOrders';
import { IRequestBodyUpdateOrder } from '../../types/IRequests';
import { IUser } from '../../types/IUsers';

import { Badge } from '../Badge/Badge';
import { Button, ButtonType } from '../Button/Button';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu/DropdownMenu';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { TimeAgo } from '../TimeAgo/TimeAgo';
import { OrderNotes } from '../OrderNotes/OrderNotes';
import { ScriptQueueConfirmationModal } from '../ScriptQueueConfirmationModal/ScriptQueueConfirmationModal';

import tagCheckIcon from '../../assets/images/tags/Check.svg';
import tagExclamationIcon from '../../assets/images/tags/Exclamation.svg';
import editBlueIcon from '../../assets/images/icons/Edit_blue.svg';


type ScriptQueueTileProps = {
  order: any
  highlight?: boolean
  orders: Array<any>
  setOrders: Function
  user: IUser | null
}

export const ScriptQueueTile: FunctionComponent<ScriptQueueTileProps> = ({ order, highlight, orders, setOrders, user }) => {
  const apiHelper: ApiHelper = useApi();

  const navigate = useNavigate();

  // state
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // methods
  const handleProcessOrder = (): void => {
    switch (order.status_code) {
      case 'new':
        handleSetDummyOrderStatus('awaiting_processing');
        break;
      case 'awaiting_processing':
        handleSetDummyOrderStatus('awaiting_review');
        break;
      case 'on_hold':
        handleSetDummyOrderStatus('awaiting_review');
        break;
      case 'awaiting_review':
        setShowConfirmationModal(true);
        break;
      case 'awaiting_pickup':
        // handleSetDummyOrderStatus('complete');
        navigate(`/process-prescription/collect-script/${order.id}`);
        break;
    }
  }

  const handleReverseOrder = (): void => {
    switch (order.status_code) {
      case 'awaiting_processing':
        handleSetDummyOrderStatus('new');
        break;
      case 'awaiting_review':
        handleSetDummyOrderStatus('awaiting_processing');
        break;
      case 'awaiting_pickup':
        handleSetDummyOrderStatus('awaiting_review');
        break;
    }
  }

  const handleDeleteOrder = (): void => {
    const mutatedOrders = JSON.parse(JSON.stringify(orders));
    const orderIndex = mutatedOrders.findIndex((o: any) => o.id === order.id);
    mutatedOrders.splice(orderIndex, 1);
    setOrders(mutatedOrders);
  }

  const handleSetDummyOrderStatus = async (status: string): Promise<any> => {
    // const mutatedOrders = JSON.parse(JSON.stringify(orders));
    // const orderIndex = mutatedOrders.findIndex((o: any) => o.id === order.id);
    // mutatedOrders[orderIndex].status_code = status;
    // setOrders(mutatedOrders);

    return new Promise<any>((resolve, reject) => {
      const requestBody: IRequestBodyUpdateOrder = {
        status_code: status as OrderStatus
      }
  
      setIsLoading(true);
      apiHelper.updateOrder(order.id, requestBody).then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          // onRefresh();
          // setShowSuccess(true);
          // setTimeout(() => {
          //   handleClose();
          // }, successDuration);
          resolve(response.body.data);
          const data = typeof response.body.data === "string"
            ? JSON.parse(response.body.data)
            : response.body.data;
          updateLocalData(data);
        } else {
          // setErrorMessage(`Error updating delivery method: ${response.body.message}`);
          reject(response.body.message);
        }
      });
    })
  }

  const updateLocalData = (updatedOrder: any): void => {
    const mutatedOrders = JSON.parse(JSON.stringify(orders));
    const orderIndex = mutatedOrders.findIndex((o: any) => o.id === order.id);
    mutatedOrders[orderIndex] = updatedOrder;
    setOrders(mutatedOrders);
  }

  // computed
  const primaryButtonLabel = (): string => {
    switch (order.status_code) {
      case 'new':
        return 'Send for Dispensing';
      case 'awaiting_processing':
        return 'Mark as Dispensed';
      case 'on_hold':
        return 'Mark as Dispensed';
      case 'awaiting_review':
        return 'Mark as Ready for Customer Collection';
      case 'awaiting_pickup':
        return 'Mark as Collected';
      default:
        return '';
    }
  }

  // const prevStatusLabel = (): string => {
  //   switch (order.status_code) {
  //     case 'dispensing':
  //       return 'Incoming Queue';
  //     case 'pre-collection':
  //       return 'Dispensing Queue';
  //     case 'awaiting-collection':
  //       return 'Pre-Collection';
  //     default:
  //       return '';
  //   }
  // }

  const dropdownMenuItems: Array<DropdownMenuItem> = [
    {
      id: 'delete',
      label: 'Delete order',
      onClick: () => { handleDeleteOrder() },
    },
    ...(order.status_code !== 'new' ? [{
      id: 'reverse',
      label: `Move order to previous status`,
      onClick: () => { handleReverseOrder() },
    }] : []),
    ...(order.status_code === 'awaiting_processing' ? [{
      id: 'hold',
      label: `Place order on hold`,
      onClick: () => { handleSetDummyOrderStatus('on_hold') },
    }] : []),
    ...(order.status_code === 'on_hold' ? [{
      id: 'active',
      label: `Mark order as active`,
      onClick: () => { handleSetDummyOrderStatus('awaiting_processing') },
    }] : [])
  ];

  return (
    <>
      <StyledOrder deliveryType={order.type} className={getOrderUrgency(order) === OrderUrgency.Overdue ? 'highlight' : ''}>
        {/* absolute positioned elements */}
        <StyledTimeAgo className="Order_timeAgo italic" urgency={OrderUrgency.Standard}>
          <TimeAgo isoDate={order.created_at} />
        </StyledTimeAgo>

        <div className="Order_menu">
          <DropdownMenu items={dropdownMenuItems} />
        </div>

        {order.status_code === 'incoming' &&
          <div className="Order_editDetails button">
            <img src={editBlueIcon} alt="Edit details" />
            <p className="bold">Edit Details</p>
          </div>
        }
        
        {/* relative positioned elements */}
        <Badge type="scriptQueue_inStore" />
        
        <div className="Order_tags">
          {['awaiting_review', 'awaiting_pickup', 'complete'].includes(order.status_code) &&
            <p>
              <img src={tagCheckIcon} alt="" draggable="false" />
              Dispensed by Pharmacist
            </p>
          }
          {['awaiting_pickup', 'complete'].includes(order.status_code) &&
            <p>
              <img src={tagCheckIcon} alt="" draggable="false" />
              Message sent to customer for collection
            </p>
          }
        </div>
        
        <div style={{ position: 'relative' }}>
          <OrderDetails order={order} phone scripts />
          {(order.billing_street !== null && order.billing_street !== undefined && order.billing_street !== '') &&
            <div className="Order_notes">
              <OrderNotes order={order} noDate scriptQueue />
            </div>
          }
        </div>

        {/* buttons */}
        <Button type={ButtonType.Primary} text={primaryButtonLabel()} onClick={handleProcessOrder} loading={isLoading} />
      </StyledOrder>

      <ScriptQueueConfirmationModal 
        show={showConfirmationModal}
        order={order}
        setDummyStatus={handleSetDummyOrderStatus}
        onClose={() => setShowConfirmationModal(false)}
        orders={orders}
        setOrders={setOrders}
        user={user}
      />
    </>
  );
}

const StyledOrder = styled.div<{ deliveryType: OrderDeliveryType }>`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 252px;
  margin: 25px 0;
  padding: 52px 52px 46px 52px;
  
  border-radius: 6px;
  background: white;
  box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);
  overflow: hidden;
  z-index: 0;

  &:last-child {
    margin-bottom: 0;
  }

  // HIGHLIGHT
  &.highlight {
    border-top: 1px solid ${colours.alert};
    border-right: 1px solid ${colours.alert};
    border-bottom: 1px solid ${colours.alert};
  }

  // LEFT GRADIENT
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 10px;
    ${props => handleDeliveryType(props.deliveryType)};
  }

  // DROPDOWN MENU
  .Order_menu {
    position: absolute;
    top: 18px;
    right: 54px;
  }

  // HISTORY
  .Order_history {
    position: absolute;
    top: 1px;
    right: 0;

    padding: 8px;
    margin-right: -8px;
    margin-top: -8px;
  }

  // TAGS
  .Order_tags {
    display: flex;
    align-items: center;
    margin-top: 13px;

    p {
      display: flex;
      align-items: center;
      font-family: "Mulish Bold";
      height: 24px;
      font-size: 0.625rem; // 10px
      margin: 0 22px 0 0;

      img {
        margin-right: 5px;
      }
    }
  }

  // BUTTON
  button {
    margin-top: 33px;
  }

  .Button_secondary {
    margin-top: 20px;
  }

  // SCRIPT QUEUE CLASSES
  .Order_editDetails {
    position: absolute;
    right: 53px;
    top: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;
    z-index: 1;
    padding: 0 3px;

    img {
      width: 14px;
      height: 14px;
      margin-bottom: 2px;
    }

    p {
      font-size: 0.625rem; // 10px
      color: #2EC0DF;
    }
  }

  .Order_notes {
    border-top: 1px solid #DADADA;
  }
`

const TelehealthModal = styled.div`
  .Modal {
    .Heading {
      margin: 50px 0 40px 0;
    }
    .Button_primary {
      margin: 40px 0 25px 0;
    }
    .successMessage {
      .Alert {
        margin: -19px 0 -33px 0;
      }
    }
  }
`

const handleDeliveryType = (deliveryType: string): string => {
  switch (deliveryType) {
    case 'scriptQueue_inStore':
      return 'background: linear-gradient(to bottom, #68D4F8, #6E94DD 90%);'
    default:
      return '';
  }
};

const StyledTimeAgo = styled.p<{ urgency: OrderUrgency }>`
  position: absolute;
  top: 52px;
  right: 54px;
  width: fit-content;
  margin: 0;
  padding: 5px 10px;
  font-size: 0.625rem; // 10px
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  ${props => handleUrgency(props.urgency)};
`

const handleUrgency = (urgency: OrderUrgency): string => {
  switch (urgency) {
    case OrderUrgency.Standard:
      return `
        background: #FCFCFC;
        border-color: #EFEFEF;
        color: #AEAEAE;
      `;
    case OrderUrgency.Warning:
      return `
        background: #FDEFEB;
        border-color: #FEE1D1;
        color: #E5721E;
      `;
    case OrderUrgency.Overdue:
      return `
        background: #FDEBEF;
        border-color: #FED1D7;
        color: ${colours.alert};
      `;
    default:
      return '';
  }
};