import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ILocation } from '../../types/ILocations';
import { formatLocationAddress } from '../../helpers/utils';

import phoneIcon from '../../assets/images/icons/Phone.svg';
import mailIcon from '../../assets/images/icons/Mail.svg';
import pinIcon from '../../assets/images/icons/Pin.svg';


type LocationDetailsProps = {
  location: ILocation,
  phone?: boolean,
  email?: boolean,
  address?: boolean,
}

export const LocationDetails: FunctionComponent<LocationDetailsProps> = ({ location, phone, email, address }) => {
  return (
    <StyledLocationDetails>
      <p className="LocationDetails_id bold">
        <span className="LocationDetails_name">{location.name}</span>
      </p>
      {address && 
        <p className="LocationDetails_detail LocationDetails_address">
          <img src={pinIcon} alt="Address" style={{ padding: '0 3px' }} />
          <span>{formatLocationAddress(location)}</span>
        </p>
      }
      {(phone || email) &&
        <div className="LocationDetails_contact">
          {phone && 
            <p className="LocationDetails_detail">
              <img src={phoneIcon} alt="Phone" />
              {location.contact_phone}
            </p>
          }
          {email && 
            <p className="LocationDetails_detail LocationDetails_email ellipsis">
              <img src={mailIcon} alt="Email" />
              <span className="ellipsis">{location.contact_email}</span>
            </p>
          }
        </div>
      }
    </StyledLocationDetails>
  );
}

const StyledLocationDetails = styled.div`
  .LocationDetails_id {
    margin: 13px 0 13px 0;
    font-size: 1.125rem; // 18px
    width: calc(100% - 40px);
    white-space: nowrap;
    display: flex;

    .LocationDetails_name {
    }

    .LocationDetails_number {
      min-width: fit-content;
    }

    .LocationDetails_divider {
      flex-grow: 0;
      margin: 0 12px;
    }
  }

  .LocationDetails_contact {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 16px;
  }

  .LocationDetails_detail {
    display: flex;
    align-items: center;
    font-size: 1rem; // 16px
    margin-top: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.LocationDetails_email {
      flex-grow: 1;
      max-width: 60%;
      /* max-width: 200px; */
    }
    
    &.LocationDetails_address {
      margin-top: 15px !important;
    }

    img {
      margin-right: 10px;
    }
  }
`