import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';
import { formatPrice } from '../../helpers/utils';

import { IOrder } from '../../types/IOrders';

import { Alert, AlertType } from '../Alert/Alert';
import { QuantitySelector } from '../QuantitySelector/QuantitySelector';

import dropdownIcon from '../../assets/images/icons/Dropdown.svg';
import shoppingBagIcon from '../../assets/images/icons/ShoppingBag.svg';
import deleteIcon from '../../assets/images/icons/Delete.svg';


type OrderItemsProps = {
  order: IOrder
  orderItems: Array<any>
  partialRefundItems?: Array<any>
  setItems?: Function
  fields?: Array<string>
  accordion?: boolean
  summaryTable?: boolean
  createOrder?: boolean
  boldQuantity?: boolean
  nonRefundsOnly?: boolean
  refundsOnly?: boolean
  noRefundAlert?: boolean
}

export const OrderItems: FunctionComponent<OrderItemsProps> = ({ order, orderItems, partialRefundItems, setItems, fields, accordion, summaryTable, createOrder, boldQuantity, nonRefundsOnly, refundsOnly, noRefundAlert }) => {
  const [open, setOpen] = useState(false);
  const [openHeight, setOpenHeight] = useState(-1);

  // methods
  const handleDeleteItem = (item: any): void => {
    let mutatedItems = JSON.parse(JSON.stringify(orderItems));
    mutatedItems = mutatedItems.filter((i: any) => i.sku !== item.sku);

    if (setItems) {
      setItems(mutatedItems);
    }
  }
  
  const handleSetQuantity = (item: any, value: number): void => {
    let mutatedItems = JSON.parse(JSON.stringify(orderItems));
    let itemToUpdate = mutatedItems.find((i: any) => i.sku === item.sku);
    itemToUpdate.qty_ordered = value;
    
    if (setItems) {
      setItems(mutatedItems);
    }
  }

  // determine items to display
  let items = orderItems;
  if (partialRefundItems) {
    items = partialRefundItems;

    if (nonRefundsOnly) {
      items = items
        .map(item => { return { ...item, qty: item.qty_ordered - item.qtyToRefund } })
        .filter(item => item.qty !== 0);
    }
    if (refundsOnly) {
      items = partialRefundItems
        .filter(item => item.qtyToRefund !== 0)
        .map(item => { return { ...item, qty: item.qtyToRefund } });
    }
  } else {
    if (nonRefundsOnly) console.warn('Cannot display nonRefundsOnly, partialRefundItems is undefined.')
    if (refundsOnly) console.warn('Cannot display refundsOnly, partialRefundItems is undefined.')
  }

  const showPartialRefundStyles = (
    partialRefundItems !== undefined &&
    !partialRefundItems.every((item: any) => item.qtyToRefund === 0) &&
    !nonRefundsOnly &&
    !refundsOnly
  );
  
  const toggleOpen = (): void => {
    if (openHeight === -1) setOpenHeight(calculateOpenHeight());
    setOpen(!open);
  }

  const itemRefs: Array<HTMLElement> = [];
  
  const addItemRef = (el: HTMLElement | null): void => {
    if (el) itemRefs.push(el);
  }

  const calculateOpenHeight = (): number => {
    return itemRefs.reduce((acc, el) => {
      return acc + el.clientHeight;
    }, 0) - 20;
  }
  
  const totalPrice = (): number => {
    return items.reduce((acc, item) => {
      const qty = item.qtyToRefund ? item.qty_ordered - item.qtyToRefund : item.qty_ordered;
      return acc + item.total * qty;
    }, 0);
  }
  
  const numberItems = (): number => {
    return items.reduce((acc, item) => {
      return acc + (item.qtyToRefund ? item.qty_ordered - item.qtyToRefund : item.qty_ordered);
    }, 0);
  }

  const itemUrl = (item: any): string => {
    switch (order.partner_id) {
      case 3:
        return `https://www.chemistworks.com.au/index.php/catalog/product/view/id/${item.platform_product_id}`
      case 4:
        return `https://catandclaudias.com.au/index.php/catalog/product/view/id/${item.platform_product_id}`;
      default:
        return '#';
    }
  }

  return (
    <StyledOrderItems className={`OrderItems ${open || !accordion ? 'open' : 'closed'} ${!accordion ? 'no-accordion' : ''}`} style={{ height: `${openHeight}px` }}>
      {accordion &&
        <div className="OrderItems_header" onClick={toggleOpen} ref={el => addItemRef(el)}>
          <div style={{ display: 'flex' }}>
            <img className="OrderItems_shoppingBag" src={shoppingBagIcon} alt="" />
            <p className="bold" style={{ marginTop: '3px' }}>
              <span>{`${numberItems()} ${numberItems() === 1 ? 'item' : 'items'}`}</span>
              <span className="Order_divider">|</span>
              {/* {showPartialRefundStyles && <span className="OrderItems_oldPrice strikethrough">{`${formatPrice(order.total)}`}</span>} */}
              <span>{`${formatPrice(totalPrice())}`}</span>
            </p>
          </div>
          <img className="OrderItems_dropdown" src={dropdownIcon} alt="Show order items" draggable="false" />
        </div>
      }

      <table ref={el => addItemRef(el)} className={`${summaryTable ? 'summaryTable' : ''} ${createOrder ? 'createOrder' : ''}`}>
        <thead>
          <tr>
            {/* {fields!.includes('sku') && <th className="align_left sku">SKU</th>} */}
            {fields!.includes('name') && <th className="align_left name">Product Name</th>}
            {fields!.includes('sku-name') && <th className="align_left sku-name">Product Name</th>}
            {fields!.includes('quantity') && <th className="align_center quantity">Qty</th>}
            {fields!.includes('quantity-select') && <th className="align_center quantity-select">Qty</th>}
            {fields!.includes('price') && <th className="align_right price">Price</th>}
            {fields!.includes('delete') && <th className="align_center delete"></th>}
          </tr>
        </thead>
        <tbody>
          <>
            {(showPartialRefundStyles && !noRefundAlert) &&
              <tr>
                <td colSpan={10}>
                  <Alert type={AlertType.Urgent} small>
                    <p>Selected item(s) will be refunded once order has been processed</p>
                  </Alert>
                </td>
              </tr>
            }
            {items.length !== 0 ?
              <>
                {items.map((item: any, i: number) => {
                  return (
                    <tr className={`OrderItems_item ${showPartialRefundStyles && item.qtyToRefund === item.qty_ordered ? 'strikethrough' : ''}`} key={`item-${i}-${item.sku}`}>
                      {/* {fields!.includes('sku') && <td className="align_left sku">{item.sku}</td>} */}
                      {/* {fields!.includes('name') && <td className="align_left name">{item.name}</td>} */}
                      {(fields!.includes('name') || fields!.includes('sku-name')) &&
                        <td className="align_left sku-name">
                          <p className="sku-name_sku">SKU: {item.sku}</p>
                          <p className="sku-name_name">
                            <a className="sku-name_name" href={itemUrl(item)} target="_blank">{item.name}</a>
                          </p>
                        </td>
                      }
                      {fields!.includes('quantity') &&
                        <td className={`align_center quantity ${boldQuantity ? 'extrabold' : ''}`}>
                          {(!showPartialRefundStyles || item.qtyToRefund === undefined || item.qtyToRefund === 0 || item.qty_ordered - item.qtyToRefund === 0) ?
                            <>
                              <span>{item.qty === null || item.qty === undefined ? item.qty_ordered : item.qty}</span>
                              {/* <span>{item.qty}</span> */}
                            </>
                          :
                            <>
                              <span className="strikethrough">{item.qty_ordered}</span>
                              <span style={{ marginLeft: '8px' }}>{(item.qty || item.qty_ordered) - item.qtyToRefund}</span>
                            </>
                          }
                        </td>
                      }
                      {fields!.includes('quantity-select') &&
                        <td className="align_center quantity-select">
                          <QuantitySelector label="" quantity={item.qty_ordered} onChange={(value: number) => handleSetQuantity(item, value)} min={1} max={item.max_qty} small />
                        </td>
                      }
                      {fields!.includes('price') && <td className="align_right price">{formatPrice(item.total)}</td>}
                      {fields!.includes('delete') &&
                        <td className="align_center delete">
                          <img className="delete-button button" src={deleteIcon} onClick={() => handleDeleteItem(item)} alt="Delete item" draggable="false" />
                        </td>
                      }
                    </tr>
                  )
                })}
                {createOrder &&
                  <tr className="OrderItems_item total noRowColours">
                    {fields!.map((field: string, i: number) => {
                      if (i < fields!.length - 3) return (
                        <td key={i}></td>
                      )
                    })}
                    <td className="OrderItems_total bold align_center">Total</td>
                    <td className="OrderItems_total bold align_right">{formatPrice(totalPrice())}</td>
                    <td className="OrderItems_total"></td>
                  </tr>
                }
              </>
            :
              <tr className="OrderItems_item">
                <td colSpan={10} className="italic">No items to display.</td>
              </tr>
            }
          </>
        </tbody>
      </table>
    </StyledOrderItems>
  );
}

OrderItems.defaultProps = {
  fields: ['sku', 'name', 'quantity', 'price'],
  accordion: false,
  summaryTable: false,
  createOrder: false,
  boldQuantity: false,
  nonRefundsOnly: false,
  refundsOnly: false,
  noRefundAlert: false
}

const StyledOrderItems = styled.div`
  overflow: hidden;
  transition: height ${transitions.slow};

  &.open {
    .OrderItems_header .OrderItems_dropdown {
      transform: rotate(-180deg);
    }
  }

  &.closed {
    height: 25px !important;
  }

  .OrderItems_header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    align-items: center;

    .OrderItems_shoppingBag {
      width: 17px;
      margin-left: 3px;
      margin-right: 13px;
    }

    .OrderItems_dropdown {
      transition: transform ${transitions.slow};
    }
    
    p {
      margin: 0;
    }

    .Order_divider {
      margin: 0 12px;
    }
    
    .OrderItems_oldPrice {
      margin-right: 10px;
    }
  }

  table {
    padding: 0 32px;
    width: 100%;
    border-spacing: 0 21px;
    margin-bottom: -21px;
    thead {
      border-bottom: 3px solid #000000;
    }

    tr {
      
      vertical-align: top;
    }
    tr:first-child {
      border-bottom: 5px solid yellow;
    }
    .align_left {
      padding-right: 10px;
    }

    /* non summary table styles */
    &:not(.summaryTable) {
      td.quantity {
        padding-top: 19px;
      }
      td.price {
        padding-top: 19px;
      }
    }
  }

  &.no-accordion {
    table {
      padding: 0;
    }
  }

  /* column styling */
  .sku {
    width: 95px;
    overflow-wrap: anywhere;
    padding-right: 15px;
  }
  .sku-name {
    /* width: 400px; */

    .sku-name_sku {
      font-size: 0.75rem; // 12px
      margin: 0;
    }
    .sku-name_name {
      font-size: 1rem; // 16px
      margin: 0;
      line-height: 1.64;
    }
  }
  .quantity {
    width: 50px;
  }
  .quantity-select {
    width: 80px;
  }
  .price {
    width: 85px;
  }
  .delete {
    .delete-button {
      width: 22px;
      height: auto;
    }
  }

  /* summary table styles */
  .summaryTable {
    .sku {
      width: 100px;
    }
    /* .name {
    } */
    /* .quantity {
    } */
    /* .price {
    } */
    tr:nth-child(even) {
      background-color: #f0f0f0;
    }
  }

  /* partial refund alert */
  td {
    .Alert {
      margin: 0 !important;
    }
  }
  .summaryTable {
    td {
      vertical-align: middle;
      .Alert {
        margin: 0 -7px !important;
      }
    }
  }

  /* total */
  .OrderItems_total {
    border-top: 3px solid black;
    padding-top: 20px;
  }

  /* create order styling */
  .createOrder {
    .price {
      width: 70px;
    }
    
    .delete {
      width: 30px;
    }

    .OrderItems_item {
      &:not(.total) {
        height: 82px;
      }

      td {
        vertical-align: middle;
      }
    }
  }
`