import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

import { IUser } from "../types/IUsers";
import { ILocations } from "../types/ILocations";

import { Body } from "../components/Body/Body";
import { AuthModal } from "../components/AuthModal/AuthModal";


type SelectLocationPageProps = {
  loggedin: boolean
  user: IUser | null
  location: any
  partnerLocations?: ILocations
  selectedUser: any
  onSelectLocation: Function
  onLogout: Function
}

export const SelectLocationPage: FunctionComponent<SelectLocationPageProps> = ({ loggedin, user, location, partnerLocations, selectedUser, onSelectLocation, onLogout }) => {
  return (
    <>
      {!partnerLocations && <Navigate to="/login" />}
      {!loggedin && <Navigate to="/login" />}
      {!selectedUser && <Navigate to="/select-user" />}
      {loggedin && selectedUser && location ?
        <Navigate to="/" />
      : 
        <>
          {partnerLocations &&
            <Body loggedin={loggedin} user={user} location={null}>
              <AuthModal type="location" onSelectLocation={onSelectLocation} partnerLocations={partnerLocations} user={user} />
            </Body>
          }
        </>
      }
    </>
  );
}
