import { FunctionComponent, ChangeEventHandler, FocusEventHandler, ChangeEvent } from 'react';
import { HTMLInputTypeAttribute } from "react";
import styled from 'styled-components';


type InputFieldProps = {
  type?: HTMLInputTypeAttribute
  id?: string
  label?: string
  labelAfter?: string
  placeholder?: string
  min?: number
  max?: number
  step?: string
  value?: any
  regex?: RegExp
  onChange?: ChangeEventHandler
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
}

export const InputField: FunctionComponent<InputFieldProps> = ({ type, id, label, labelAfter, placeholder, min, max, step, value, regex, onChange, onFocus, onBlur }) => {
  const validateInput = (event: ChangeEvent): void => {
    const input = (event.target as HTMLInputElement).value;
    let isValid = true;
    
    if (regex) {
      if (input !== "" && !regex.test(input)) {
        isValid = false;
      }
    }

    if (max) {
      if (parseFloat(input) > max) (event.target as HTMLInputElement).value = max.toString();
    }
    
    if (min) {
      if (parseFloat(input) > min) (event.target as HTMLInputElement).value = min.toString();
    }
    
    if (isValid && onChange) onChange(event);
  }
  
  return (
    <StyledInputField className="InputField">
      <label className="InputField_label" htmlFor={id}>{label}</label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        min={min}
        max={max}
        step={step}
        value={value === undefined ? '' : value}
        onChange={validateInput}
        onFocus={onFocus}
        onBlur={onBlur}
        inputMode={type === 'number' ? 'decimal' : 'text'} 
      />
      {labelAfter &&
        <label className="InputField_labelAfter">{labelAfter}</label>
      }
    </StyledInputField>
  );
}

const StyledInputField = styled.div`
  position: relative;

  input {
    width: 100%;
    height: 48px;
    margin: 8px 0 32px 0;
    padding: 13px;
    border-radius: 4px;
    border: 1px solid #9B9B9B;

    &:active, &:focus-visible {
      border: 2px solid black;
      outline: none;
      padding: 12px;
    }

    &::placeholder {
      color: #D4D4D4;
    }

    &:last-of-type {
      margin-bottom: 8px;
    }
  }

  .InputField_labelAfter {
    position: absolute;
    bottom: 0;
    left: calc(100% + 10px);

    height: 48px;
    margin: 8px 0;
    padding: 1rem 0;
  }
`