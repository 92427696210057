import { FunctionComponent } from 'react';
import { To, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';

import BackArrowIcon from '../../assets/images/icons/BackArrow.svg';

type BackButtonProps = {
  to?: To | null,
  text: string,
  onClick?: Function
}

export const BackButton: FunctionComponent<BackButtonProps> = ({ to, text, onClick }) => {
  const navigate = useNavigate();
  const handleNavigation = (): void => {
    if (onClick) onClick();
    if (to) navigate(to);
  }

  return (
    <StyledBackButton onClick={handleNavigation}>
      <img src={BackArrowIcon} alt="Back arrow" draggable="false" />
      <p className="bold">{text}</p>
    </StyledBackButton>
  );
}

BackButton.defaultProps = {
  to: -1 as To,
  text: 'Back'
}

const StyledBackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin: 15px 0;

  img {
    width: 17px;
    height: 12px;
    transition: transform ${transitions.default}
  }

  p {
    font-size: 0.875rem; // 14px
    margin: 0 16px;
    padding: 10px 0;
  }

  &:hover {
    img {
      transform: translateX(-10px);
    }
  }
`