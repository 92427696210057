import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { transitions, boxShadows } from '../../assets/css/variables';

import { filterOrdersByQuery } from "../../helpers/utils";

import { DashboardCount } from '../DashboardCount/DashboardCount';

import arrowIcon from '../../assets/images/icons/Arrow.svg';


type TelehealthDashboardProps = {
  patients: Array<any>
  category: string,
  query: string,
  setCategory: Function
}

export const categories = [
  { id: 'incoming', label: 'Incoming Patients' },
  { id: 'in_queue', label: 'Patient Queue' },
  { id: 'complete', label: 'Previous Patients' },
]

export const TelehealthDashboard: FunctionComponent<TelehealthDashboardProps> = ({ patients, category, query, setCategory }) => {
  const [suppressAnimation, setSuppressAnimation] = useState(false);

  // watch query prop to suppress DashboardCount animation
  // when returning from search
  useEffect(() => {
    if (query !== '') {
      setSuppressAnimation(true);
    } else {
      setTimeout(() => {
        setSuppressAnimation(false);
      }, 500);
    }
  }, [query]);

  const numOrdersByCategory = (category: string, query: string): number => {
    return filterOrdersByQuery(patients, query).filter(patient => patient.status_code === category).length;
    // return patients.filter(patient => patient.status_code === category).length;
  }

  return (
    <>
      <StyledDashboard className="Dashboard">
        {categories.map((cat: any, i: number) => {
          return (
            <div
              className={`dashboard_tile ${i < 4 ? 'dashboard_tile_primary' : 'dashboard_tile_secondary'} ${cat.id === category ? 'selected' : ''}`}
              id={`tile_${cat.id}`}
              key={cat.id}
              onClick={() => setCategory(cat.id)}
            >
              <div className="tile_countParent">
                <div style={{ display: 'inline', position: 'relative' }}>
                  <DashboardCount
                    value={numOrdersByCategory(cat.id, query)}
                    // small={i > 3}
                    noAnimation={query !== '' || suppressAnimation || i === 5}
                  />
                </div>
                <div style={{ display: 'inline' }}>
                  {query && <span className="tile_resultLabel bold">{numOrdersByCategory(cat.id, query) === 1 ? 'result' : 'results'}</span>}
                </div>
              </div>
              <p className="tile_label">{cat.label}</p>
              <img src={arrowIcon} alt="" className={`${cat.id === category ? 'selected' : ''}`} draggable="false" />
            </div>
          )
        })}
      </StyledDashboard>
    </>
  );
}

const StyledDashboard = styled.div`
  display: grid;
  grid-gap: 12px 10px;
  gap: 12px 10px;
  grid-template-columns: repeat(3, 1fr);
  margin: 31px 0;
  user-select: none;

  .dashboard_tile {
    position: relative;
    border: 1px solid #9B9B9B;
    cursor: pointer;
    border-radius: 6px;
    background: white;
    color: black;
    ${boxShadows.default}
    transition:
      box-shadow ${transitions.default},
      border-color ${transitions.default};

    &:hover {
      ${boxShadows.hover}
    }

    &.selected {
      border: 2px solid black;
      
      .tile_label {
        font-family: 'Mulish Bold';
      }
    }

    p {
      margin: 0;

      &.tile_label {
        font-family: 'Mulish Regular';
        font-size: 0.625rem; // 10px
      }
    }

    img {
      width: 14px;
      height: 10px;
      transition: opacity ${transitions.default}, margin-left ${transitions.slow};

      &.selected {
        opacity: 0;
        margin-left: -32px;
      }
    }

    &.dashboard_tile_primary {
      height: 99px;
      padding: 12px 6px 12px 16px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;

      .tile_countParent {
        transition: margin-top ${transitions.slow};
        display: inline-block;

        .tile_resultLabel {
          font-size: 1.125rem; // 18px
          margin-left: 8px;
          display: inline-block;
        }
      }
      
      &.selected {
        padding: 11px 5px 11px 15px;

        .tile_countParent {
          margin-top: 7px;
        }
      }

      img {
        position: absolute;
        top: 16px;
        right: 15px;
      }
    }

    &.dashboard_tile_secondary {
      height: 49px;
      grid-column: span 2;
      display: flex;
      align-items: center;
      justify-content: center;
      
      p.tile_label {
        margin: 1px 18px 0 18px;
      }

      .tile_countParent {
        display: flex;
        align-items: center;
        
        .tile_count {
          display: flex;
          align-items: center;
          display: inline-block;
        }

        .tile_resultLabel {
          font-size: 1rem; // 16px
          margin-left: 8px;
          margin-bottom: 1px;
          display: inline-block;
          margin-left: 8px;
        }
      }
    }
  }
`