import { FunctionComponent, useState, useEffect, ChangeEvent, useRef } from "react";
import { Navigate, Params, useParams, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { containers } from "../assets/css/variables";
import { QRCodeSVG } from 'qrcode.react';
import { useReactToPrint } from "react-to-print";
import { transitions } from "../assets/css/variables";
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

import { useApi } from '../context/ApiProvider';
import { ApiHelper } from '../common/ApiHelper/ApiHelper';
import { getCurrentTimestamp } from "../helpers/utils";
import { Helpers } from "../helpers/helpers";

import { IUser } from "../types/IUsers";
import { IOrder, IOrders, OrderDeliveryType, OrderState, OrderStatus, OrderType } from "../types/IOrders";
import { ILocation, ILocations } from "../types/ILocations";
import { NotificationType } from "../types/INotifications";
import { IRequestBodyCreateOrder, IRequestBodyUpdateOrder, IRequestBodySendNotification } from "../types/IRequests";
import { IResponseSendNotification } from "../types/IResponses";
import { IScriptServeResponse } from "../types/IScriptServe";

import { Body } from "../components/Body/Body";
import { BackButton } from "../components/BackButton/BackButton";
import { ProcessSteps } from "../components/ProcessSteps/ProcessSteps";
import { Heading } from "../components/Heading/Heading";
import { Button, ButtonColour, ButtonIcon, ButtonType } from "../components/Button/Button";
import { InputField } from "../components/InputField/InputField";
import { QuantitySelector } from "../components/QuantitySelector/QuantitySelector";
import { Alert, AlertIcon, AlertType } from "../components/Alert/Alert";
import { Modal } from "../components/Modal/Modal";
import { TextArea } from "../components/TextArea/TextArea";
import { ScriptDocket, ScriptDocketType } from "../components/ScriptDocket/ScriptDocket";
import { SelectionGroup, SelectionGroupInputType, SelectionGroupOption, SelectionGroupType } from "../components/SelectionGroup/SelectionGroup";

import phoneIcon from '../assets/images/icons/Phone.svg';
import personIcon from '../assets/images/icons/Person.svg';


export enum ScriptProcess {
  AddScript = 'add-script',
  CollectScript = 'collect-script'
}

export enum ScriptProcessStep {
  CustomerDetails = "Customer",
  ScriptQuantity = "Quantity",
  ScriptInformation = "Prescription Details",
  ConfirmId = "Confirm ID",
  Notes = "Notes"
}

export const scriptProcessSteps = {
  'add-script': [
    ScriptProcessStep.CustomerDetails,
    ScriptProcessStep.ScriptQuantity,
    ScriptProcessStep.ScriptInformation,
  ],
  'collect-script': [
    ScriptProcessStep.ConfirmId,
    ScriptProcessStep.Notes,
  ]
}

const confirmIdOptions: Array<SelectionGroupOption> = [
  {
    id: 'script-docket',
    value: 'script-docket',
    label: 'Prescription Docket'
  },
  {
    id: 'drivers-license',
    value: 'drivers-license',
    label: 'Driver\'s License'
  },
  {
    id: 'student-id',
    value: 'student-id',
    label: 'Student ID'
  },
  {
    id: 'nsw-photo-card',
    value: 'nsw-photo-card',
    label: 'NSW Photo Card'
  },
  {
    id: 'passport',
    value: 'passport',
    label: 'Passport'
  },
  {
    id: 'seniors-card',
    value: 'seniors-card',
    label: 'Seniors Card'
  },
  {
    id: 'custom',
    value: 'custom',
    label: 'Other'
  }
];

type ScriptProcessPageProps = {
  loggedin: boolean
  user: IUser | null
  location: ILocation | null
  onSelectLocation: Function
  onSelectUser: Function
  onLogout: Function
  orders: IOrders
  partnerLocations: ILocations
  setOrders: Function
}

export const ScriptProcessPage: FunctionComponent<ScriptProcessPageProps> = ({ loggedin, user, location, onSelectLocation, onSelectUser, onLogout, orders, partnerLocations, setOrders }) => {
  const apiHelper: ApiHelper = useApi();
  const helpers = new Helpers();
  const navigate = useNavigate();
  const printRef = useRef(null);
  
  const { process, orderId }: Readonly<Params<string>> = useParams();
  // const process = 'add-script';
  // const orderId = '00011';

  // state
  const [currentStepIndex, setCurrentStepIndex] = useState(0); 
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [notesInput, setNotesInput] = useState('');
  const [notes, setNotes] = useState('');
  const [numScripts, setNumScripts] = useState(0);
  const [numEscripts, setNumEscripts] = useState(0);
  const [escripts, setEscripts] = useState([] as Array<any>);

  const [confirmIdMethod, setConfirmIdMethod] = useState('');
  const [customConfirmIdMethod, setCustomConfirmIdMethod] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');

  const [showNotesModal, setShowNotesModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // scroll to top when step changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ currentStepIndex ])

  // methods
  const handleBack = (): void => {
    const prevStep = currentStepIndex - 1;
    if (prevStep >= 0) setCurrentStepIndex(prevStep);
  }

  const handleNext = (numSteps: number = 1): void => {
    const nextStep = currentStepIndex + numSteps;
    if (nextStep < scriptProcessSteps[process as keyof typeof scriptProcessSteps].length) setCurrentStepIndex(nextStep);
  }

  const handleStepReset = (): void => {
    setCurrentStepIndex(0);
  }
  
  const handleSetNumEscripts = (val: number): void => {
    setNumEscripts(val);
    const newArray = Array.from({length: val}, (v, i) => { return {
      id: i,
      focus: false,
      token: '',
      prescription: null
    }});
    setEscripts(newArray);
  }
  
  const handleSetEscripts = (index: number, val: string): void => {
    const mutatedEscripts = JSON.parse(JSON.stringify(escripts));
    mutatedEscripts.forEach((script: any, i: number) => {
      if (i === index) {
        script.token = val;
      }
    });
    setEscripts(mutatedEscripts);
  }
 
  // get eScript data from the ScriptServe API
  const handleGetPrescription = async (index: number, token: string): Promise<void> => {
    let prescriptionItem: {};
    try {
      // make the API call using the submitted token
      const headers = {
        'Authorization': `Bearer ${helpers.getSparrowScriptBearerToken()}`,
        'x-uuid': uuidv4()
      };
      const response = await axios.get<IScriptServeResponse>(
        // localhost is working after API CORS update
        // `http://localhost:3001/api/product_request?prescription_token=${token}`, 
        `https://script.sparrowhub.com.au/api/product_request?prescription_token=${token}`,
        { headers },
      );

      // get the name from the first product
      let name = response.data.products?.find((prod) => prod.pbs_preferred_term != null)?.pbs_preferred_term;
      if (name) {
        name = toProperCase(name);
      }

      // build the patient initials
      let initials = '';
      if (response.data.medication_knowledge?.mk_name_given) {
        initials = response.data.medication_knowledge?.mk_name_given[0].charAt(0);
      }
      initials += response.data.medication_knowledge?.mk_name_family?.charAt(0)

      // update product data
      prescriptionItem = {
        name,
        initials,
        repeats: response.data.medication_knowledge?.mk_remaining_repeats,
      }
    } catch (error) {
      // if it fails, fall back to a default result
      prescriptionItem = {
        name: 'Chlorsig Eye Drops 0.5% 1',
        // initials: 'HH',
        repeats: 3
      }
      console.error(error);
    }
    
    // update our existing eScript data
    const mutatedEscripts = JSON.parse(JSON.stringify(escripts));
    mutatedEscripts[index].prescription = prescriptionItem;
    setEscripts(mutatedEscripts);
  }

  // convert a string of words to Proper/Title case
  const toProperCase = (str: string): string => {
    return str.replace(
      /\w\S*/g,
      (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }
    );
  }

  const focusEscript = (index: number): void => {
    const mutatedEscripts = JSON.parse(JSON.stringify(escripts));
    mutatedEscripts.forEach((script: any, i: number) => {
      if (i === index) {
        script.focus = true;
      } else {
        script.focus = false;
      }
    });
    setEscripts(mutatedEscripts);
    setTimeout(() => {
      const input = document.getElementById(`escript-${index}`);
      input!.focus();
    }, 50);
  }
  
  const successDuration = 3000;
  const handleCompleteProcess = async (): Promise<any> => {
    if (process === ScriptProcess.AddScript) {
      return new Promise<any>((resolve, reject) => {
        const requestBody: IRequestBodyCreateOrder = {
          // required fields
          ordered_at: getCurrentTimestamp(),
          partner_id: user!.partner_id,
          order_type_code: OrderType.Sale,
          order_state_code: OrderState.New,
          is_visible: true,
          // standard script queue fields
          delivery_type_code: OrderDeliveryType.InStore,
          status_code: OrderStatus.New,
          location_code: location!.code,
          // input fields
          platform_order_no: nextOrderId(),
          delivery_firstname: firstname,
          delivery_lastname: lastname,
          delivery_phone: phone,
          delivery_street: prescriptionDataString(),
          delivery_city: JSON.stringify({numScripts: numScripts, numEscripts: numEscripts}),
          billing_street: notes === '' ? undefined : JSON.stringify(notes)
        }
        setIsLoading(true);
        apiHelper.createOrder(requestBody).then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.status === 200) {
            // const data = typeof response.body.data === "string"
            //   ? JSON.parse(response.body.data)
            //   : response.body.data;
            // updateLocalData(data);

            sendNotification();

            setShowSuccess(true);
            setTimeout(() => {
              navigate('/prescription-queue')
            }, successDuration);
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error creating order: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      });
    } else if (process === ScriptProcess.CollectScript) {
      return new Promise<any>((resolve, reject) => {
        const requestBody: IRequestBodyUpdateOrder = {
          status_code: 'complete' as OrderStatus
        }
        setIsLoading(true);
        apiHelper.updateOrder(parseInt(orderId!, 10), requestBody).then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            const data = typeof response.body.data === "string"
              ? JSON.parse(response.body.data)
              : response.body.data;
            updateLocalData(data);

            setShowSuccess(true);
            setTimeout(() => {
              navigate('/prescription-queue')
            }, successDuration);
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error collecting prescription: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      })
    }
  }

  const sendNotification = async (): Promise<any> => {
    console.log('sendNotification');
    return new Promise<any>((resolve, reject) => {
      const notificationText = `Hi ${firstname},\n\nYour script (#${nextOrderId()}) is in the queue. The estimated wait time is 12 min.\n\nRegards, Smith’s Pharmacy`;
      console.log(notificationText);

      const requestBody: IRequestBodySendNotification = {
        message: notificationText,
        contact: phone,
        notification_type_code: NotificationType.Sms,
        user_id: user!.id,
        partner_id: user!.partner_id
      }
      apiHelper.sendNotification(requestBody).then((response: IResponseSendNotification) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          console.warn(response)
          resolve(response);
        }
      });
    })
  }

  const updateLocalData = (newOrder: any): void => {
    const mutatedOrders = JSON.parse(JSON.stringify(orders));
    mutatedOrders.push(newOrder);
    setOrders(mutatedOrders);
  }
  
  const handlePrintDockets = useReactToPrint({
    content: () => printRef.current,
    bodyClass: "printable_visible",
  });

  // computed properties
  const steps = (): Array<ScriptProcessStep> => {
    return scriptProcessSteps[process as keyof typeof scriptProcessSteps]
  }

  const currentStep = (): ScriptProcessStep => {
    return steps()[currentStepIndex]
  }

  const newOrder = (): any => {
    return {
      platform_order_no: nextOrderId(),
      delivery_firstname: firstname,
      delivery_lastname: lastname,
      delivery_phone: phone,
      notes: notes,
      numScripts: numScripts,
      numEscripts: numEscripts,
      delivery_city: JSON.stringify({numScripts: numScripts, numEscripts: numEscripts}),
      escripts: escripts,
    }
  }
  
  const prescriptionDataString = (): any => {
    const perscriptionData = escripts.map((script: any) => script.prescription);
    return JSON.stringify(perscriptionData);
  }

  const customStringFieldIsInvalid = (string: string, customString: string): boolean => {
    if (string === 'custom' || string === 'other') {
      return customString.trim() === '';
    } else {
      return string === '';
    }
  }

  // const newOrderItems = (): any => {
  //   const pScriptData = [];
  //   for (let i = 0; i < numScripts; i++) {
  //     pScriptData.push({
  //       // required fields
  //       id: '0',
  //       created_at: getCurrentTimestamp(),
  //       order_id: newOrder().platform_order_no,
  //       partner_id: user!.partner_id,
  //       // input fields
  //       sku: 'paper-script',
  //     })
  //   }
  //   const eScriptData = [];
  //   for (let i = 0; i < numEscripts; i++) {
  //     eScriptData.push({
  //       // required fields
  //       id: '0',
  //       created_at: getCurrentTimestamp(),
  //       order_id: newOrder().platform_order_no,
  //       partner_id: user!.partner_id,
  //       // input fields
  //       sku: 'e-script',
  //       name: escripts[i].prescription.name,
  //       prescription_first_name: escripts[i].prescription.initials,
  //       qty_ordered: escripts[i].prescription.repeats,
  //       prescription_token_number: escripts[i].token
  //     })
  //   }
  //   return JSON.stringify({ pScriptData, eScriptData })
  // }

  const nextOrderId = (): string => {
    return '00017';
    // let highest = 0;
    // if (!orders) return '00000';
    // orders.forEach((order: IOrder) => {
    //   const int = parseInt(order.platform_order_no);
    //   if (int > highest) highest = int;
    // });
    // return (highest + 1).toString().padStart(5, '0');
  }

  return (
    <>
      {!loggedin || !location ?
        <Navigate to="/login" />
      :
        <>
          {!orders ?
            <Navigate to="/orders" />
          :
            <Body key={process} loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
              <BackButton to={'/prescription-queue'} text="Back to Incoming Queue" onClick={handleStepReset} />
              
              <StyledScriptProcessPage deliveryType="scriptQueue_inStore">
                <div className="ProcessScriptModal_processSteps divider">
                  <ProcessSteps steps={scriptProcessSteps[process as keyof typeof scriptProcessSteps]} current={currentStepIndex} />
                </div>
                
                {currentStep() === ScriptProcessStep.CustomerDetails &&
                  <div className="ProcessScriptModal_stepContents">
                    <div className="ProcessScriptModal_headingContainer">
                      <Heading heading="Enter the customer’s details:" />
                    </div>

                    <div style={{ display: 'flex', gap: '10px' }}>
                      <InputField type="text" id="firstname" label="First Name" value={firstname} onChange={(e: ChangeEvent) => setFirstname((e.target as HTMLInputElement).value)} /> 
                      <InputField type="text" id="lastname" label="Last Name" value={lastname} onChange={(e: ChangeEvent) => setLastname((e.target as HTMLInputElement).value)} /> 
                    </div>
                    <InputField type="text" id="phone" label="Phone Number" value={phone} regex={/^\d+$/} onChange={(e: ChangeEvent) => setPhone((e.target as HTMLInputElement).value)} />
                    
                    <Button text="Proceed to Next Step" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={firstname === '' || lastname === '' || phone === ''} onClick={handleNext} />
                    <Button text="Back" type={ButtonType.Secondary} onClick={() => navigate('/orders/complete')} />
                  </div>
                }
                
                {currentStep() === ScriptProcessStep.ScriptQuantity &&
                  <div className="ProcessScriptModal_stepContents">
                    <div className="ProcessScriptModal_headingContainer">
                      <Heading heading="How many scripts does the customer have?" />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h3>Paper scripts:</h3>
                      <QuantitySelector label="" quantity={numScripts} min={0} onChange={setNumScripts} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h3>E-scripts:</h3>
                      <QuantitySelector label="" quantity={numEscripts} min={0} onChange={handleSetNumEscripts} />
                    </div>
                    
                    <Button text="Proceed to Next Step" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={numScripts === 0 && numEscripts === 0} onClick={handleNext} />
                    <Button text="Back" type={ButtonType.Secondary} onClick={handleBack} />
                  </div>
                }
                
                {currentStep() === ScriptProcessStep.ScriptInformation &&
                  <>
                    <div className="ProcessScriptModal_stepContents">
                      <div className="ProcessScriptModal_headingContainer">
                        <Heading heading={`Order ${nextOrderId()}`} />

                        <p style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '-18px 0 8px 0' }}>
                          <img style={{ width: '26px', height: 'auto' }} src={personIcon} alt="Name" />
                          {firstname} {lastname}
                        </p>
                        <p style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '8px 0' }}>
                          <img style={{ width: '26px', height: 'auto', padding: '2px' }} src={phoneIcon} alt="Phone" />
                          {phone}
                        </p>
                      </div>

                      <h3>Order Summary</h3>
                      <div style={{ borderBottom: '3px solid black' }}>
                        <p style={{ margin: '10px 0' }}>
                          <span className="extrabold" style={{ display: 'inline-block', width: '100px' }}>Qty</span>
                          <span className="extrabold">Description</span>
                        </p>
                      </div>
                      {numScripts > 0 &&
                        <p style={{ margin: '10px 0' }}>
                          <span style={{ display: 'inline-block', width: '100px' }}>{numScripts}</span>
                          <span>Paper scripts</span>
                        </p>
                      }
                      {numEscripts > 0 &&
                        <p style={{ margin: '10px 0' }}>
                          <span style={{ display: 'inline-block', width: '100px' }}>{numEscripts}</span>
                          <span>E-scripts</span>
                        </p>
                      }
                      
                      {numEscripts > 0 &&
                        <div style={{ marginTop: '40px' }}>
                          {escripts.map((script: any, i: number) => {
                            return (
                              <div key={`escript-${i}`}>
                                <label>E-Script #{i + 1}</label>
                                {script.prescription !== null ?
                                  <div className="ProcessScriptModal_escriptDetails">
                                    <div className="qrcode">
                                      <QRCodeSVG value={script.token} size={70} />
                                    </div>
                                    <div>
                                      <p className="bold header">{script.prescription.name}</p>
                                      <p className="details">
                                        <span className="bold">Patient Initials:</span> {script.prescription.initials}<br />
                                        <span className="bold">Supply Remaining:</span> {script.prescription.repeats}<br />
                                        <span className="bold">Token Number:</span> {script.token}
                                      </p>
                                    </div>
                                  </div>
                                :
                                  <>
                                    {(script.focus || script.token !== '') ?
                                      <div className="ProcessScriptModal_escriptContainer">
                                        <InputField type="text" id={`escript-${i}`} value={escripts[i].token} onChange={(e: ChangeEvent) => handleSetEscripts(i, (e.target as HTMLInputElement).value)} />
                                        <Button text="Submit" type={ButtonType.Primary} onClick={() => handleGetPrescription(i, escripts[i].token)} />
                                      </div>
                                    :
                                      <Button text="Scan E-Script" type={ButtonType.AddParcel} icon={ButtonIcon.Plus} small onClick={() => focusEscript(i)} />
                                    }
                                  </>
                                }
                              </div>
                            )
                          })}
                        </div>
                      }

                      <div className="ProcessScriptModal_buttonContainer">
                        <Button text={`${notes === '' ? 'Add' : 'Edit'} Order Notes`} type={ButtonType.Primary} colour={ButtonColour.Blue} icon={ButtonIcon.Notes} small onClick={() => setShowNotesModal(true)} />
                        <Button text="Print Dockets" type={ButtonType.Primary} colour={ButtonColour.Green} icon={ButtonIcon.Print} small disabled={escripts.some((escript: any) => escript.prescription === null)} onClick={handlePrintDockets} />
                      </div>

                      {notes !== '' &&
                        <Alert type={AlertType.Notes}>
                          <p>{notes}</p>
                        </Alert>
                      }
                      
                      {errorMessage &&
                        <div style={{ marginBottom: '20px' }}>
                          <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                            <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="https://apt-support.freshdesk.com/support/tickets/new" target="_blank" rel="noreferrer">here</a>.</p>
                          </Alert>
                        </div>
                      }

                      {/* <Button text="Proceed to Next Step" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={escripts.some((escript: any) => escript.prescription === null)} onClick={handleNext} /> */}
                      <Button text="Create Order" type={ButtonType.Primary} icon={ButtonIcon.Arrow} loading={isLoading} disabled={escripts.some((escript: any) => escript.prescription === null)} onClick={handleCompleteProcess} />
                      <Button text="Back" type={ButtonType.Secondary} onClick={handleBack} />
                    </div>

                    <Modal show={showNotesModal}>
                      <Heading heading="Add order notes for the dispensing Pharmacist:" />
                      <TextArea id="notes-input" value={notesInput} maxLength={250} onChange={(e: ChangeEvent) => setNotesInput((e.target as HTMLTextAreaElement).value)} />
                      <Button text="Save Changes" type={ButtonType.Primary} disabled={notesInput === notes} onClick={() => { setNotes(notesInput); setShowNotesModal(false) }} />
                      <Button text="Back" type={ButtonType.Secondary} onClick={() => setShowNotesModal(false)} />
                    </Modal>

                    <div className="printable_hidden" ref={printRef}>
                      <ScriptDocket type={ScriptDocketType.Pharmacist} script={newOrder()} />
                      <ScriptDocket type={ScriptDocketType.Customer} script={newOrder()} />
                    </div>
                  </>
                }

                {currentStep() === ScriptProcessStep.ConfirmId &&
                  <div className="ProcessScriptModal_stepContents">
                    <div className="ProcessScriptModal_headingContainer">
                      <Heading heading="How have you confirmed the customer's identity?" />
                    </div>
                    <div className="">
                      <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={confirmIdOptions} selected={confirmIdMethod} onChange={setConfirmIdMethod} />
                      <div className={`customField_container ${confirmIdMethod === 'custom' ? '' : 'hidden'}`}>
                        <InputField type="text" id="customConfirmId" label="Other" value={customConfirmIdMethod} onChange={(e: ChangeEvent) => setCustomConfirmIdMethod((e.target as HTMLInputElement).value)} /> 
                      </div>
                    </div>
                    <Button text="Add Order Notes" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={customStringFieldIsInvalid(confirmIdMethod, customConfirmIdMethod)} onClick={handleNext} />
                    <Button text="Back" type={ButtonType.Secondary} onClick={() => navigate('/orders/awaiting-pickup')} />
                  </div>
                }

                {currentStep() === ScriptProcessStep.Notes &&
                  <div className="ProcessScriptModal_stepContents">
                    <Heading heading="Do you have any additional notes for this order?" />
                    <TextArea id="notes-input" value={additionalNotes} maxLength={512} onChange={(e: ChangeEvent) => setAdditionalNotes((e.target as HTMLTextAreaElement).value)} />
                    {errorMessage &&
                      <div style={{ marginTop: '-60px', marginBottom: '20px' }}>
                        <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                          <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="https://apt-support.freshdesk.com/support/tickets/new" target="_blank" rel="noreferrer">here</a>.</p>
                        </Alert>
                      </div>
                    }
                    <Button text="Mark Order as Collected" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={handleCompleteProcess} loading={isLoading}/>
                    <Button text="Back" type={ButtonType.Secondary} onClick={handleBack} />
                  </div>
                }
              </StyledScriptProcessPage>

              <Modal show={showSuccess}>
                <Alert type={AlertType.PositiveSecondary} successModal>
                  {process === ScriptProcess.AddScript &&
                    <p>This order has been created and can be found in <span className="extrabold">Incoming Queue</span></p>
                  }
                  {process === ScriptProcess.CollectScript &&
                    <p>This order has successfully been marked as collected</p>
                  }
                </Alert>
              </Modal>
            </Body>
          }
        </>
      }
    </>
  );
}

const StyledScriptProcessPage = styled.div<{ deliveryType: OrderDeliveryType | string }>`
  ${containers.default}
  padding-top: 36px;
  position: relative;
  overflow: hidden;
  
  // TOP GRADIENT
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 10px;
    ${props => handleDeliveryType(props.deliveryType)};
  }

  .ProcessScriptModal_stepContents {
    padding-top: 50px;

    .Heading {
      margin-bottom: 35px;
    }

    .InputField {
      margin-bottom: 20px;
    }

    .Button {
      :not(.Button_addParcel) {
        margin: 25px 0;
      }

      &.Button_addParcel {
        margin-top: 8px;
        margin-bottom: 28px;
        height: 48px;
      }

      &.Button_primary {
        margin-top: 40px;
      }
    }

    .ProcessScriptModal_escriptContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 8px;
      margin-bottom: 28px;

      .InputField {
        margin: 0;
        width: 100%;

        input {
          margin: 0;
        }
      }

      .Button {
        height: 48px;
        margin: 0;
        width: 100px;
      }
    }

    .ProcessScriptModal_escriptDetails {
      display: flex;
      gap: 18px;
      margin-top: 8px;
      margin-bottom: 20px;

      .qrcode {
        padding: 5px 0;
      }

      .header {
        margin-top: 3px;
        margin-bottom: 5px;
      }

      .details {
        font-size: 0.75rem; // 12px
        margin: 0;
      }
    }

    .ProcessScriptModal_buttonContainer {
      display: flex;
      gap: 10px;
      margin-top: 40px;

      .Button {
        margin: 0;
      }
    }

    .Alert {
      margin-top: 30px;
    }
  }

  .Modal {
    textarea {
      margin: 30px 0 45px 0;
    }

    .Button_primary {
      margin-bottom: 25px;
    }
  }

  .customField_container {
    height: 121px;
    opacity: 1;
    overflow: hidden;
    transition: height ${transitions.default},
                opacity ${transitions.default},
      margin ${transitions.default};

    &.hidden {
      height: 0;
      opacity: 0;
      pointer-events: none;
      margin-bottom: -25px !important;
    }
  }

  .SelectionGroup_grid {
    margin-bottom: 30px;
  }
`

const handleDeliveryType = (deliveryType: OrderDeliveryType | string): string => {
  switch (deliveryType) {
    case 'scriptQueue_inStore':
      return 'background: linear-gradient(to left, #68D4F8, #6E94DD 90%);'
    default:
      return '';
  }
};