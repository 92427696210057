import { FunctionComponent } from 'react';


type HeaderProps = {
  type: string,
}

export const Loader: FunctionComponent<HeaderProps> = ({ type }) => {
  return (
    <div className={type} style={{ display: 'flex' }}>
      <span>
        <img src="/assets/images/loader/loader.gif" title="Loading..." alt="" />
      </span>
    </div>
  );
}
