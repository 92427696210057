import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

import { IUser } from "../types/IUsers";

import { Body } from "../components/Body/Body";
import { AuthModal } from "../components/AuthModal/AuthModal";


type SelectUserPageProps = {
  loggedin: boolean,
  user: IUser | null,
  location: any, 
  selectedUser: any,
  onSelectUser: Function,
  onLogout: Function,
}

export const SelectUserPage: FunctionComponent<SelectUserPageProps> = ({ loggedin, user, location, selectedUser, onSelectUser, onLogout }) => {
  return (
    <>
      {!loggedin && <Navigate to="/login" />}
      {loggedin && selectedUser ?
        <>
          {location ? 
            <Navigate to="/" />
          : 
            <Navigate to="/select-store" />
          }
        </>
      :
      <Body loggedin={loggedin} user={user} location={null}>
        <AuthModal type="user" onSelectUser={onSelectUser} onLogout={onLogout} />
      </Body>
      }
    </>
  );
}
