import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType, ButtonIcon } from '../Button/Button';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { ProcessSteps } from '../ProcessSteps/ProcessSteps';
import { SelectionGroup, SelectionGroupInputType, SelectionGroupType, SelectionGroupOption } from '../SelectionGroup/SelectionGroup';


export enum TelehealthProcess {
  ConfirmIncoming = 'confirm_incoming',
  RemoveIncoming = 'remove_incoming',
  CompleteAppointment = 'complete_appointment',
  MissedAppointment = 'missed_appointment',
  RemoveAppointment = 'remove_appointment'
}

const processSteps: Array<string> = [
  'Send Prescriptions',
  'Complete'
]

type ProcessTelehealthModalProps = {
  show: boolean
  order: any
  process: TelehealthProcess | null
  patients: Array<any>
  setPatients: Function
  onClose?: Function
}

export const ProcessTelehealthModal: FunctionComponent<ProcessTelehealthModalProps> = ({ show, order, process, patients, setPatients, onClose }) => {
  // state
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState('');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    // trigger processes which auto-complete
    if (
      process === TelehealthProcess.ConfirmIncoming ||
      process === TelehealthProcess.RemoveAppointment
    ) {
      handleCompleteProcess();
    }

    // reset state
    setCurrentStepIndex(0);
    setSelectedPrescription('');
  }, [ process ]);

  // computed properties
  const successMessage = (): string => {
    switch (process) {
      case TelehealthProcess.ConfirmIncoming:
        return 'Appointment has been added to the Patient Queue.';
      case TelehealthProcess.RemoveIncoming:
        return 'Patient has been removed from Incoming Patients.';
      case TelehealthProcess.CompleteAppointment:
        return 'Patient has been charged. This appointment can now be found in Previous Patients.';
      case TelehealthProcess.MissedAppointment:
        return 'Appointment has been marked as ‘missed’. This appointment can now be found in Previous Patients.';
      case TelehealthProcess.RemoveAppointment:
        return 'Appointment has been removed from your queue and can now be found in All Appointments.';
      default:
        return '';
    }
  }

  const prescriptionOptions = (): Array<SelectionGroupOption> => {
    const options = [];
    if (order.prescription_handling === 'pharmacy') options.push({
      id: 'pharmacy',
      value: 'pharmacy',
      label: `Yes, send prescription\ndirectly to pharmacy`
    });
    if (order.prescription_handling === 'escript') options.push({
      id: 'escript',
      value: 'escript',
      label: 'Yes, send patient\ne-script via SMS'
    });
    options.push({
      id: 'none',
      value: 'none',
      label: 'No prescription required'
    });
    return options;
  }

  // methods
  const handleClose = (): void => {
    setShowSuccessMessage(false);
    if (onClose) onClose();
  }

  const next = (): void => {
    setCurrentStepIndex(currentStepIndex + 1);
  }
  
  const back = (): void => {
    if (currentStepIndex === 0) {
      handleClose();
    } else {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  }

  const handleSetPatientStatus = (status: string): void => {
    const mutatedPatients = JSON.parse(JSON.stringify(patients));
    const patientIndex = mutatedPatients.findIndex((patient: any) => patient.id === order.id);
    mutatedPatients[patientIndex].status_code = status;
    setPatients(mutatedPatients);
  }
  
  const handleUnassignPatient = (): void => {
    const mutatedPatients = JSON.parse(JSON.stringify(patients));
    const patientIndex = mutatedPatients.findIndex((patient: any) => patient.id === order.id);
    mutatedPatients[patientIndex].assigned = 'unassigned';
    setPatients(mutatedPatients);
  }
  
  const handleCompleteAppointment = (): void => {
    const mutatedPatients = JSON.parse(JSON.stringify(patients));
    const patientIndex = mutatedPatients.findIndex((patient: any) => patient.id === order.id);
    
    // set status and doctor
    mutatedPatients[patientIndex].status_code = 'complete';
    mutatedPatients[patientIndex].seen_by = 'Dr. Alex Watson';

    // set completion_type
    if (process === TelehealthProcess.MissedAppointment) mutatedPatients[patientIndex].completion_type = 'missed';
    if (process === TelehealthProcess.CompleteAppointment) mutatedPatients[patientIndex].completion_type = 'complete';
    
    setPatients(mutatedPatients);
  }

  const successDuration = 3000;
  const updateDelay = 1000;
  const handleCompleteProcess = (): void => {
    // show success modal and hide after timeout
    setShowSuccessMessage(true);
    setTimeout(() => { handleClose() }, successDuration);

    // complete process with dummy data
    // after delay to simulate API time
    if (process === TelehealthProcess.ConfirmIncoming) {
      setTimeout(() => { handleSetPatientStatus('in_queue') }, successDuration + updateDelay);
    } else if (process === TelehealthProcess.RemoveIncoming) {
      setTimeout(() => { handleSetPatientStatus('removed') }, successDuration + updateDelay);
    } else if (process === TelehealthProcess.CompleteAppointment) {
      setTimeout(() => { handleCompleteAppointment() }, successDuration + updateDelay);
    } else if (process === TelehealthProcess.MissedAppointment) {
      setTimeout(() => { handleCompleteAppointment() }, successDuration + updateDelay);
    } else if (process === TelehealthProcess.RemoveAppointment) {
      setTimeout(() => { handleUnassignPatient() }, successDuration + updateDelay);
    }
  }

  return (
    <StyledProcessTelehealthModal className="ProcessTelehealthModal">
      <Modal show={show}>

        {/* MODAL CONTENT */}
        {!showSuccessMessage &&
          <>
            {process === TelehealthProcess.RemoveIncoming &&
              <>
                <Heading heading="Are you sure you want to remove this incoming patient?" />
                <div className="ProcessTelehealthModal_details divider">
                  <OrderDetails order={order} phone email bold={false} />
                </div>
                <p className="bold" style={{ lineHeight: '1.64' }}>Removing an incoming patient will cancel their booking with an available doctor. This action cannot be undone.</p>
                <Button type={ButtonType.Primary} text="Yes, I'm Sure" onClick={handleCompleteProcess} />
                <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
              </>
            }
            
            {process === TelehealthProcess.CompleteAppointment &&
              <>
                <div className="divider" style={{ marginBottom: '50px' }}>
                  <ProcessSteps steps={processSteps} current={currentStepIndex} />
                </div>

                {currentStepIndex === 0 &&
                  <>
                    <Heading heading="Does this patient require a prescription?" />
                    <div style={{ marginBottom: '30px' }}>
                      <Alert type={AlertType.Important} icon={AlertIcon.Person}>
                        {order.prescription_handling === 'pharmacy' &&
                          <p>This patient has elected to send their prescription (if applicable) to their chosen pharmacy via {order.script_email}</p>
                        }
                        {order.prescription_handling === 'escript' &&
                          <p>This patient has elected to have their prescription sent directly to their mobile phone (if applicable) via {order.delivery_phone}</p>
                        }
                      </Alert>
                    </div>
                    <SelectionGroup 
                      inputType={SelectionGroupInputType.Radio}
                      groupType={SelectionGroupType.Grid}
                      options={prescriptionOptions()}
                      selected={selectedPrescription}
                      onChange={setSelectedPrescription}
                    />
                    <Button type={ButtonType.Primary} icon={ButtonIcon.Arrow} text="Proceed to Next Step" onClick={next} disabled={selectedPrescription === ''} />
                  </>
                }
                
                {currentStepIndex === 1 &&
                  <>
                    <Heading heading="Confirm appointment completion" subheading="Are you sure you want to mark this appointment as completed and charge the customer's credit card?" />
                    <Button type={ButtonType.Primary} icon={ButtonIcon.Arrow} text="Yes, I'm Sure" onClick={handleCompleteProcess} />
                  </>
                }

                <Button type={ButtonType.Secondary} text="Back" onClick={back} />
              </>
            }

            {process === TelehealthProcess.MissedAppointment &&
              <>
                <Heading heading="Are you sure you want to mark this appointment as missed?" />
                <div className="ProcessTelehealthModal_details divider">
                  <OrderDetails order={order} phone email bold={false} />
                </div>
                <p className="bold" style={{ lineHeight: '1.64' }}>This action will remove the patient from the queue. This action cannot be undone.</p>
                <p className="bold" style={{ lineHeight: '1.64' }}>The patient will be notified and given the ability to re-enter the patient queue or cancel their appointment.</p>
                <Button type={ButtonType.Primary} text="Yes, I'm Sure" onClick={handleCompleteProcess} />
                <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
              </>
            }
          </>
        }

        {/* SUCCESS MESSAGE */}
        {showSuccessMessage &&
          <div className="successMessage">
            <Alert type={AlertType.PositiveSecondary} successModal>
              <p>{successMessage()}</p>
            </Alert>
          </div>
        }
      </Modal>
    </StyledProcessTelehealthModal>
  );
}

const StyledProcessTelehealthModal = styled.div`
  .Modal {
    .ProcessTelehealthModal_details {
      margin: 30px 0;
    }

    .Heading {
      margin: 15px 0 40px 0;
    }

    .Button_primary {
      margin: 40px 0 25px 0;
    }

    .successMessage {
      .Alert {
        margin: -19px 0 -33px 0;

        p {
          font-size: 1rem; // 16px
        }
      }
    }
  }
`