import { FunctionComponent } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';
import { useDummyData } from '../../context/DummyDataProvider';

import { IOrder } from '../../types/IOrders';

import { OrderDetails } from '../OrderDetails/OrderDetails';

import chemistworksLogo from '../../assets/images/logos/Chemistworks.png';
import catClaudiaLogo from '../../assets/images/logos/logo-catandclaudias.png';
import placeholderImage from '../../assets/images/products/placeholder.png';


export enum ScriptDocketType {
  Customer = 'customer',
  Pharmacist = 'pharmacist'
}

type ScriptDocketProps = {
  // order: IOrder
  script: any
  type: ScriptDocketType
}

export const ScriptDocket: FunctionComponent<ScriptDocketProps> = ({ script, type }) => {
  const dummyData: any = useDummyData();

  // computed
  const partnerLogo = (): string => {
    if (dummyData.state.useDummyData) {
      return dummyData.state.dummyLogo;
    } else {
      switch (script.partner_id) {
        case 3:
          return chemistworksLogo;
        case 4:
          return catClaudiaLogo;
        default:
          return '';
      }
    }
  }
  
  const partnerName = (): string => {
    if (dummyData.state.useDummyData) {
      return dummyData.state.dummyLocation.name;
    } else {
      switch (script.partner_id) {
        case 3:
          return 'Chemistworks';
        case 4:
          return 'Cat & Claudia\'s Community Pharmacy';
        default:
          return '';
      }
    }
  }

  return (
    <StyledScriptDocket className="ScriptDocket">
      {type === ScriptDocketType.Customer &&
        <div className="ScriptDocket_customer">
          <img className="ScriptDocket_logo" src={partnerLogo()} alt="" />
          <p className="ScriptDocket_location bold">Welcome to<br />{partnerName()}</p>
          <p className="ScriptDocket_customer bold">{script.delivery_firstname} {script.delivery_lastname}</p>
          <p className="ScriptDocket_orderId bold">{script.platform_order_no}</p>
          <p className="ScriptDocket_message">We are currently working on getting your medication to you. Your order number will be shown on the monitor and you will receive a text when it is ready for collection.</p>
        </div>
      }
      
      {type === ScriptDocketType.Pharmacist &&
        <div className="ScriptDocket_pharmacist">
          <div className="divider">
            <QRCodeSVG value={script.platform_order_no} size={70} />
            <OrderDetails order={script} phone scripts />
          </div>

          <h3>Order Summary</h3>
          <div style={{ borderBottom: '2px solid black' }}>
            <p style={{ margin: '10px 0' }}>
              <span className="extrabold" style={{ display: 'inline-block', width: '100px' }}>Qty</span>
              <span className="extrabold">Description</span>
            </p>
          </div>
          {script.numScripts > 0 &&
            <p style={{ margin: '10px 0' }}>
              <span style={{ display: 'inline-block', width: '100px' }}>{script.numScripts}</span>
              <span>Paper scripts</span>
            </p>
          }
          {script.numEscripts > 0 &&
            <p style={{ margin: '10px 0' }}>
              <span style={{ display: 'inline-block', width: '100px' }}>{script.numEscripts}</span>
              <span>E-scripts</span>
            </p>
          }
          
          {script.numEscripts > 0 &&
            <>
              {script.escripts.map((script: any, i: number) => {
                return (
                  <div key={`escript-${i}`}>
                    {script.prescription !== null &&
                      <>
                        <label>E-Script #{i + 1}</label>
                        <div className="ProcessScriptModal_escriptDetails">
                          <div className="qrcode">
                            <QRCodeSVG value={script.token} size={70} />
                          </div>
                          <div>
                            <p className="bold header">{script.prescription.name}</p>
                            <p className="details">
                              <span className="bold">Patient Initials:</span> {script.prescription.initials}<br />
                              <span className="bold">Supply Remaining:</span> {script.prescription.repeats}<br />
                              <span className="bold">Token Number:</span> {script.token}
                            </p>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                )
              })}
            </>
          }
        </div>
      }
    </StyledScriptDocket>
  )
}

const StyledScriptDocket = styled.div`
  /* width: 681px; */
  width: 560px;
  padding: 40px 50px;
  page-break-after: always;
  
  .ScriptDocket_customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .ScriptDocket_logo {
      width: 320px;
      height: 50px;
      object-fit: contain;
      margin: 0 0 52px 0;
    }
    .ScriptDocket_location {
      font-size: 1.375rem; // 22px
      margin: 0 0 35px 0;
    }
    .ScriptDocket_customer {
      font-size: 1.875rem; // 30px
      margin: 0 0 8px 0;
    }
    .ScriptDocket_orderId {
      font-size: 4rem; // 64px
      margin: 0 0 43px 0;
    }
    .ScriptDocket_message {
      font-size: 1.25rem; // 20px
      line-height: 1.5;
      margin: 0;
    }
  }

  .ScriptDocket_pharmacist {
    .divider {
      /* display: flex; */
      padding-bottom: 10px;

      .OrderDetails {
        flex-grow: 1;
      }
    }

    .ProcessScriptModal_escriptDetails {
      display: flex;
      gap: 18px;
      margin-top: 8px;
      margin-bottom: 20px;

      .qrcode {
        padding: 5px 0;
      }

      .header {
        margin-top: 3px;
        margin-bottom: 5px;
      }

      .details {
        font-size: 0.75rem; // 12px
        margin: 0;
      }
    }
  }
`