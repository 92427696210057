import { FunctionComponent } from 'react';


type ResultProps = {
  wasSuccessful: boolean,
  message?: string,
}

export const Result: FunctionComponent<ResultProps> = ({ wasSuccessful, message }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {message &&
          <p>{message}</p>
        }
        {wasSuccessful === true ? 
          <img src="/assets/images/loader/loader-success.svg" title="Success" alt="Success" style={{ maxWidth: '4rem', maxHeight: '4rem' }} />
        : 
          // <img src="/assets/images/loader/loader-fail.svg" title="Failure" alt="Failure" style={{ maxWidth: '4rem', maxHeight: '4rem' }} />
          <img src="/assets/images/loader/No-Orders.png" title="No Orders available for Processing" alt="No Orders available for Processing" style={{ maxWidth: '100%', maxHeight: '16.57rem' }} />
        }

      </div>
    </>
  );
}
