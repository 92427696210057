import { FunctionComponent, ChangeEventHandler, FocusEventHandler } from 'react';
import styled from 'styled-components';

import SelectArrow from '../../assets/images/icons/SelectArrow.svg';

export type SelectInputOption = {
  value: string | number | undefined
  label: string
}

type SelectInputProps = {
  id?: string
  label?: string
  options: Array<SelectInputOption>
  value?: any
  onChange?: ChangeEventHandler
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
}

export const SelectInput: FunctionComponent<SelectInputProps> = ({ id, label, options, value, onChange, onFocus, onBlur }) => {
  return (
    <>
      <div className="SelectInput">
        <label htmlFor={id}>{label}</label>
        <StyledSelectInput
          id={id}
          value={value || ''}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {options.map((option: SelectInputOption, i: number) => {
            const optionLabel = option.label || "any";
            return (
              <option value={option.value} key={`${id}_option-${i}`}>{optionLabel.charAt(0).toUpperCase() + optionLabel.slice(1)}</option>
            )
          })}
        </StyledSelectInput>
      </div>
    </>
  );
}

const StyledSelectInput = styled.select`
  width: 100%;
  height: 48px;
  margin: 8px 0 32px 0;
  padding: 13px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  background: no-repeat center right 13px url(${SelectArrow});

  &:active, &:focus-visible {
    border: 2px solid black;
    outline: none;
    padding: 12px;
  }

  &::placeholder {
    color: #D4D4D4;
  }

  &:last-of-type {
    margin-bottom: 8px;
  }
`