import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { colours } from '../../assets/css/variables';
import { formatOrderAddress, getOrderUrgency } from '../../helpers/utils';

import { OrderDeliveryType, OrderUrgency } from '../../types/IOrders';

import { Badge } from '../Badge/Badge';
import { Button, ButtonType } from '../Button/Button';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu/DropdownMenu';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { Accordion, AccordionIcon } from '../Accordion/Accordion';
import { ProcessTelehealthModal, TelehealthProcess } from '../ProcessTelehealthModal/ProcessTelehealthModal';

import tagCheckIcon from '../../assets/images/tags/Check.svg';
import tagExclamationIcon from '../../assets/images/tags/Exclamation.svg';


type TelehealthTileProps = {
  order: any
  highlight?: boolean
  patients: Array<any>
  setPatients: Function
}

export const TelehealthTile: FunctionComponent<TelehealthTileProps> = ({ order, highlight, patients, setPatients }) => {
  // state
  const [extraSeconds, setExtraSeconds] = useState(-1);
  
  // process modal
  const [process, setProcess] = useState<TelehealthProcess | null>(null);
  const [showModal, setShowModal] = useState(false);

  const dropdownMenuItems: Array<DropdownMenuItem> = [
    {
      id: 'cancel',
      label: 'Cancel order',
      onClick: () => { console.log('todo: cancel order') },
    },
    {
      id: 'refund',
      label: 'Refund order',
      onClick: () => { console.log('todo: refund order') },
    },
    {
      id: 'transfer',
      label: 'Transfer order',
      onClick: () => { console.log('todo: transfer order') },
    },
    {
      id: 'history',
      label: 'View order history',
      // onClick: () => { setShowHistoryModal(true) },
      onClick: () => { console.log('disabled: show history modal') },
    },
    {
      id: 'documentes',
      label: 'View documents',
      onClick: () => { console.log('todo: view documents') },
    }
  ];

  const successDuration = 3000;
  const handleProcessOrder = (process: TelehealthProcess): void => {
    setProcess(process);
    setShowModal(true);
  }

  if (order.status_code !== 'complete' && extraSeconds === -1) {
    setExtraSeconds(0);
    setInterval(() => {
      setExtraSeconds(extraSeconds => extraSeconds + 1);
    }, 1000)
  }

  const formatTimeInQueue = (order: any): string => {
    const mins = Math.floor((order.time_in_queue + extraSeconds) / 60).toString().padStart(2, '0');
    const secs = ((order.time_in_queue + extraSeconds) % 60).toString().padStart(2, '0');
    // return `(${order.time_in_queue}) ${mins}:${secs}`;
    return `${mins}:${secs}`;
  }

  return (
    <>
      <StyledOrder deliveryType={order.consult_type} className={getOrderUrgency(order) === OrderUrgency.Overdue ? 'highlight' : ''}>
        {/* absolute positioned elements */}
        <StyledTimeAgo className="Order_timeAgo italic" urgency={order.status_code === 'complete' ? OrderUrgency.Standard : OrderUrgency.Warning}>
          <>
            <label>Time in queue - {formatTimeInQueue(order)}</label>
          </>
        </StyledTimeAgo>
        <div className="Order_menu" style={{ pointerEvents: 'none' }}>
          <DropdownMenu items={dropdownMenuItems} />
        </div>
        
        {/* relative positioned elements */}
        <Badge type={order.consult_type} />
        
        <div className="Order_tags">
          {order.status_code !== 'incoming' &&
            <p>
              <img src={tagCheckIcon} alt="" draggable="false" />
              Patient file created in Best Practice
            </p>
          }
          {order.status_code === 'complete' && order.completion_type === 'complete' &&
            <p>
              <img src={tagCheckIcon} alt="" draggable="false" />
              Seen by {order.seen_by} on {new Date(order.created_at).toLocaleDateString()}
            </p>
          }
          {order.status_code === 'complete' && order.completion_type === 'missed' &&
            <p>
              {order.missed_behaviour === 'cancelled' &&
                <>
                  <img src={tagCheckIcon} alt="" draggable="false" />
                  Appointment cancelled
                </>
              }
              {order.missed_behaviour === 'notified' &&
                <>
                  <img src={tagExclamationIcon} alt="" draggable="false" />
                  Appointment missed
                </>
              }
            </p>
          }
        </div>
        
        <div style={{ position: 'relative' }}>
          <OrderDetails order={order} phone email  />

          <Accordion label="Patient Details" icon={AccordionIcon.Person} chevron={false}>
            <div className="patientInformation">
              <div>
                <label>Medicare Number</label>
                <p>{order.patient_medicare}</p>
              </div>
              <div>
                <label>Address</label>
                <p>{formatOrderAddress(order)}</p>
              </div>
              <div>
                <label>Sex Assigned at Birth</label>
                <p>{order.patient_sex}</p>
              </div>
              <div>
                <label>Date of Birth</label>
                <p>{order.patient_dob}</p>
              </div>
              <div>
                <label>Aboriginal or Torres Straight Islander</label>
                <p>{order.patient_aboriginal_torres_strait_islander}</p>
              </div>
              <div>
                <label>Department of Veteran Affairs (DVA)</label>
                <p>{order.patient_dva}</p>
              </div>
            </div>

            <div className="patientInformation wide">
              <div>
                <label>Chronic Conditions</label>
                <p>{order.patient_chronic_conditions}</p>
              </div>
              <div>
                <label>Medical History & Reason for Appointment</label>
                <p>{order.patient_history}</p>
              </div>
              <div>
                <label>Allergy Information</label>
                <p>{order.patient_allergies}</p>
              </div>
              <div>
                <label>Existing Medications</label>
                <p>{order.patient_medications}</p>
              </div>
            </div>
          </Accordion>
        </div>

        {/* buttons */}
        {order.status_code === 'incoming' && 
          <>
            <Button type={ButtonType.Primary} text="Confirm Patient Intake (Add to Queue)" onClick={() => handleProcessOrder(TelehealthProcess.ConfirmIncoming)} />
            <Button type={ButtonType.Secondary} text="Remove Patient" onClick={() => handleProcessOrder(TelehealthProcess.RemoveIncoming)} />
          </>
        }
        {(order.status_code === 'in_queue' && order.assigned === 'assigned') &&
          <>
            <Button type={ButtonType.Primary} text="Complete Appointment (Charge Patient)" onClick={() => handleProcessOrder(TelehealthProcess.CompleteAppointment)} />
            <Button type={ButtonType.Secondary} text="Missed Appointment (Notify Patient)" onClick={() => handleProcessOrder(TelehealthProcess.MissedAppointment)} />
            <Button type={ButtonType.Secondary} text="Remove Patient From My Queue" onClick={() => handleProcessOrder(TelehealthProcess.RemoveAppointment)} />
          </>
        }
      </StyledOrder>  

      <ProcessTelehealthModal 
        show={showModal}
        order={order}
        process={process}
        patients={patients}
        setPatients={setPatients}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}

const StyledOrder = styled.div<{ deliveryType: OrderDeliveryType }>`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 252px;
  margin: 25px 0;
  padding: 52px 52px 38px 52px;
  
  border-radius: 6px;
  background: white;
  box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);
  overflow: hidden;
  z-index: 0;

  &:last-child {
    margin-bottom: 0;
  }

  // HIGHLIGHT
  &.highlight {
    border-top: 1px solid ${colours.alert};
    border-right: 1px solid ${colours.alert};
    border-bottom: 1px solid ${colours.alert};
  }

  // LEFT GRADIENT
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 10px;
    ${props => handleDeliveryType(props.deliveryType)};
  }

  // DROPDOWN MENU
  .Order_menu {
    position: absolute;
    top: 28px;
    right: 54px;
  }

  // HISTORY
  .Order_history {
    position: absolute;
    top: 1px;
    right: 0;

    padding: 8px;
    margin-right: -8px;
    margin-top: -8px;
  }

  // TAGS
  .Order_tags {
    display: flex;
    align-items: center;
    margin-top: 13px;

    p {
      display: flex;
      align-items: center;
      font-family: "Mulish Bold";
      height: 24px;
      font-size: 0.625rem; // 10px
      margin: 0 22px 0 0;

      img {
        margin-right: 5px;
      }
    }
  }

  // BUTTON
  button {
    margin-top: 33px;
  }

  .Button_secondary {
    margin-top: 20px;
  }

  // PATIENT TILE CLASSES
  .showHideDetails {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 30px;
  }

  .patientInformation {
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.5);

    > div {
      label {
        font-size: 0.75rem; // 12px
      }

      p {
        margin-top: 5px;
      }
    }

    &.wide {
      > div {
        grid-column: span 2;
      }
    }
  }
`

const TelehealthModal = styled.div`
  .Modal {
    .Heading {
      margin: 50px 0 40px 0;
    }
    .Button_primary {
      margin: 40px 0 25px 0;
    }
    .successMessage {
      .Alert {
        margin: -19px 0 -33px 0;
      }
    }
  }
`

const handleDeliveryType = (deliveryType: string): string => {
  switch (deliveryType) {
    case 'telehealth_express_consultation':
      return 'background: linear-gradient(to bottom, #82E8AB, #40D84F 90%);'
    case 'telehealth_script_renewal':
      return 'background: linear-gradient(to bottom, #FFC702, #FFA800 90%);'
    default:
      return '';
  }
};

const StyledTimeAgo = styled.p<{ urgency: OrderUrgency }>`
  position: absolute;
  top: 58px;
  right: 54px;
  width: fit-content;
  margin: 0;
  padding: 5px 10px;
  font-size: 0.75rem; // 12px
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  ${props => handleUrgency(props.urgency)};
`

const handleUrgency = (urgency: OrderUrgency): string => {
  switch (urgency) {
    case OrderUrgency.Standard:
      return `
        background: #FCFCFC;
        border-color: #EFEFEF;
        color: #AEAEAE;
      `;
    case OrderUrgency.Warning:
      return `
        background: #FDEFEB;
        border-color: #FEE1D1;
        color: #E5721E;
      `;
    case OrderUrgency.Overdue:
      return `
        background: #FDEBEF;
        border-color: #FED1D7;
        color: ${colours.alert};
      `;
    default:
      return '';
  }
};