import { FunctionComponent } from "react";
import { Params, useParams } from "react-router-dom";
import styled from "styled-components";

import template1 from '../assets/images/graphics/template-1-min.png';
import template2 from '../assets/images/graphics/template-2-min.png';
import template3 from '../assets/images/graphics/template-3-min.png';
import template4 from '../assets/images/graphics/template-4-min.png';

export const TemplatePage: FunctionComponent = () => {
  const { id }: Readonly<Params<string>> = useParams();

  document.body.className = 'background_white';
  

  const getImageSrc = (): string => {
    switch (id) {
      case '1':
        return template1;
      case '2':
        return template2;
      case '3':
        return template3;
      case '4':
        return template4;
      default:
        return template1;
    }
  }

  return (
    <StyledTemplatePage>
      <img src={getImageSrc()} alt="" />
    </StyledTemplatePage>
  );
}

const StyledTemplatePage = styled.div`
  min-height: 100vh;
  background: white;

  img {
    width: 100%;
    height: auto;
    user-select: none;
    touch-action: pan-y;

    box-shadow: 0 0 5px 0 red;
  }
`