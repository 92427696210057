import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';

import { ILocations } from '../types/ILocations';

import { useDummyData } from '../context/DummyDataProvider';

import { Heading } from '../components/Heading/Heading';
import { Toggle } from '../components/Toggle/Toggle';
import { SearchBar } from '../components/SearchBar/SearchBar';
import { ProductTile } from '../components/ProductTile/ProductTile';
import { Modal } from '../components/Modal/Modal';
import { Alert, AlertType } from '../components/Alert/Alert';
import { log } from 'console';


const dummyProducts = [
  {
    id: 1,
    sku: 79834894,
    brand: 'Panadol',
    name: 'Panadol Drops 20ml Syringe',
    img_src: '/assets/images/dummy/product-image-1.png',
    brand_src: '/assets/images/dummy/panadol-logo.png',
    total: 7.99,
    stock: [5, 3, 10, 9]
  },
  {
    id: 2,
    sku: 72038463,
    brand: 'Panadol',
    name: 'Panadol Elixir 5-12 Years 200ml',
    img_src: '/assets/images/dummy/product-image-2.png',
    brand_src: '/assets/images/dummy/panadol-logo.png',
    total: 24.99,
    stock: [6, 3, 10, 9]
  },
  {
    id: 3,
    sku: 62943783,
    brand: 'Panadol',
    name: 'Panadol Rapid Caplets 40 Pack',
    img_src: '/assets/images/dummy/product-image-3.png',
    brand_src: '/assets/images/dummy/panadol-logo.png',
    total: 10.99,
    stock: [0, 3, 0, 9]
  },
  {
    id: 4,
    sku: 42562457,
    brand: 'Panadol',
    name: 'Panadol Rapid Caplets 20 Pack',
    img_src: '/assets/images/dummy/product-image-4.png',
    brand_src: '/assets/images/dummy/panadol-logo.png',
    total: 6.99,
    stock: [5, 3, 10, 9]
  },
  {
    id: 5,
    sku: 11111115,
    brand: 'Panadol',
    name: 'Panadol Mini Caps | 20 Pack',
    img_src: '/assets/images/dummy/product-image-5.png',
    brand_src: '/assets/images/dummy/panadol-logo.png',
    total: 5.99,
    stock: [3, 3, 10, 9]
  },
  {
    id: 6,
    sku: 63457838,
    brand: 'Panadol',
    name: 'Panadol Child 1-5 Years 200ml Orange',
    img_src: '/assets/images/dummy/product-image-6.png',
    brand_src: '/assets/images/dummy/panadol-logo.png',
    total: 16.99,
    stock: [0, 3, 10, 0]
  }
]

type ProductSearchPageProps = {
  createOrderItems: Array<any>
  setCreateOrderItems: Function
  setShowSearchResults: Function
  partnerLocations: ILocations
  searchQuery: string
  setSearchQuery: Function
}

export const ProductSearchPage: FunctionComponent<ProductSearchPageProps> = ({ createOrderItems, setCreateOrderItems, setShowSearchResults, partnerLocations, searchQuery, setSearchQuery }) => {
  // state
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showAllStock, setShowAllStock] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // context
  const dummyData: any = useDummyData();

  // return results when query changes
  useEffect(() => {
    if (searchQuery) {
      if (dummyData.state.useDummyData) {
        // use dummy products directly
        // setSearchResults(dummyProducts);

        // duplicate dummy products to test pagination
        const duplicatedProducts = [];
        for (let i = 0; i < 30; i++) {
          duplicatedProducts.push({
            ...dummyProducts[i % dummyProducts.length],
            id: i,
          })
        }
        setSearchResults(duplicatedProducts);
      } else {
        // todo: return result from API
      }
    }
  }, [ searchQuery ])

  // computed
  const resultsPerPage = 12;
  const currentPageResults = (): Array<any> => {
    const start = currentPage * resultsPerPage;
    const end = start + resultsPerPage;
    return searchResults.slice(start, end);
  }
  
  const pageNumbers = (): Array<number> => {
    const numPages = Math.ceil(searchResults.length / resultsPerPage);
    return Array.from(Array(numPages).keys());
  }

  // methods
  const successDuration = 3000;
  const handleAddItem = (item: any): void => {
    const newOrderItems = JSON.parse(JSON.stringify(createOrderItems));
    const existingItem = newOrderItems.find((i: any) => i.sku === item.sku);
    if (existingItem) {
      existingItem.qty_ordered += item.qty_ordered;
    } else {
      newOrderItems.push(item);
    }
    setCreateOrderItems(newOrderItems);
    
    setSuccessMessage(`${item.qty_ordered} x ${item.name} ${item.qty === 1 ? 'has' : 'have'} been added to order.`);
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), successDuration);
  }

  const prevPage = (): void => {
    if (currentPage > 0) setCurrentPage(currentPage => currentPage - 1);
  }
  
  const nextPage = (): void => {
    if (currentPage < pageNumbers().length - 1) console.log('hello');
    
    if (currentPage < pageNumbers().length - 1) setCurrentPage(currentPage => currentPage + 1);
  }

  return (
    <StyledProductSearchPage className="ProductSearchPage">
      <div className="ProductSearchPage_headingContainer">
        <Heading heading="Search Results" />
        <Toggle label="Viewing stock for:" options={['My Store', 'All Stores']} state={showAllStock} onChange={() => setShowAllStock(val => !val)} />
      </div>

      <SearchBar placeholder="Search for items to add to cart..." query={searchQuery} onSubmit={setSearchQuery} />

      <div className="ProductSearchPage_results">
        {currentPageResults().map((product: any) => {
          return (
            <ProductTile key={product.id} product={product} createOrderItems={createOrderItems} onAddProduct={handleAddItem} partnerLocations={partnerLocations} showAllStock={showAllStock} />
          )
        })}
      </div>

      <div className="ProductSearchPage_pages">
        <p className={`ProductSearchPage_pageButton ${currentPage === 0 ? 'disabled' : ''}`} onClick={prevPage}>
          prev
        </p>
        {pageNumbers().map((index: number) => {
          return (
            <p 
              key={`pageNumber-${index}`}
              className={`ProductSearchPage_pageButton ${currentPage === index ? 'active' : ''}`}
              onClick={() => setCurrentPage(index)}
            >
              {index + 1}
            </p>
          )
        })}
        <p className={`ProductSearchPage_pageButton ${currentPage === pageNumbers().length - 1 ? 'disabled' : ''}`} onClick={nextPage}>
          next
        </p>
      </div>
      
      <Modal show={showSuccessMessage}>
        <Alert type={AlertType.PositiveSecondary} successModal>
          <p>{successMessage}</p>
        </Alert>
      </Modal>
    </StyledProductSearchPage>
  );
}

const StyledProductSearchPage = styled.div`
  .ProductSearchPage_headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ProductSearchPage_results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px 10px;
    gap: 20px 10px;
  }

  .ProductSearchPage_pages {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 1rem;

    .ProductSearchPage_pageButton {
      text-decoration: underline;
      cursor: pointer;
      user-select: none;
    }

    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .active {
      pointer-events: none;
      font-family: "Mulish Bold";
      text-decoration: none;
    }
  }
`