import { FunctionComponent, useState, useRef, useEffect } from "react";
import { Navigate, useNavigate, Params, useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useDummyData } from '../context/DummyDataProvider';
import { filteredSortedOrders } from '../helpers/utils';
import { transitions } from "../assets/css/variables";

import { l18n } from '../common/l18n';
import { IOrders } from "../types/IOrders";
import { ILocation, ILocations } from "../types/ILocations";
import { IUser } from "../types/IUsers";
import { Category, Subcategory } from "../types/DashboardCategories";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";
import { Orders } from "../components/Orders/Orders";
import { Search } from "../components/Search/Search";
import { Dashboard } from "../components/Dashboard/Dashboard";
import { Countdown, CountdownColour, CountdownType } from "../components/Countdown/Countdown";
import { Button, ButtonColour, ButtonIcon, ButtonType } from "../components/Button/Button";
import { PickList } from "../components/PickList/PickList";
import { Modal } from "../components/Modal/Modal";
import { ScheduleDeliveryModal } from "../components/ScheduleDeliveryModal/ScheduleDeliveryModal";
import { Process, processSteps } from "./ProcessOrderPage";

import { ApiHelper } from "../common/ApiHelper/ApiHelper";
import { useApi } from "../context/ApiProvider";


export enum DeliveryLocationStatus {
  Closed = "closed",
  PickupAvailable = "pickupAvailable",
  DropoffAvailable = "dropoffAvailable"
}

type OrdersPageProps = {
  loggedin: boolean
  user: IUser
  orders: IOrders | null
  setOrders: Function
  location: ILocation
  onSelectLocation: Function
  partnerLocations: ILocations | null
  onSelectUser: Function
  onLogout: Function
}

const categoryFromParam = (categoryParam: string | undefined, validParams: Array<string>): Category => {
  if (categoryParam === undefined) return Category.OpenOrders;
  const paramIndex = validParams.findIndex(param => param === categoryParam);
  if (paramIndex !== -1) {
    return Object.values(Category)[paramIndex];
  } else {
    window.history.replaceState(null, l18n.label.website_name, '/orders');
    return Category.OpenOrders;
  }
}

const defaultSubcategory = (category: Category): Subcategory => {
  switch (category) {
    case Category.OpenOrders:
      return Subcategory.IncomingOrders;
    case Category.RequiresManifest:
      return Subcategory.ManifestPickup;
    case Category.DispatchCollection:
      return Subcategory.DispatchPickup;
    case Category.ClosedOrders:
      return Subcategory.CompletedOrders;
  }
}

export const OrdersPage: FunctionComponent<OrdersPageProps> = ({ loggedin, user, orders, setOrders, location, onSelectLocation, partnerLocations, onSelectUser, onLogout }) => {
  const { categoryParam }: Readonly<Params<string>> = useParams();

  const apiHelper: ApiHelper = useApi();

  const [refreshKey, setRefreshKey] = useState(0);
  const [ordersKey, setOrdersKey] = useState(-1);
  const [lastSync, setLastSync] = useState(0);

  // converted from snake case to kebab case
  const validParams = Object.values(Category).map((category: Category) => {
    return (category as string).replaceAll('_', '-');
  });

  const navigate = useNavigate();
  const dummyData: any = useDummyData();

  const [query, setQuery] = useState('');
  const [category, setCategory] = useState(categoryFromParam(categoryParam, validParams));
  const [subcategory, setSubcategory] = useState(defaultSubcategory(category));
  const printRef = useRef(null);
  
  const [searchInput, setSearchInput] = useState('');
  const [tileModalOpen, setTileModalOpen] = useState(false);

  // modals
  const [showScheduleDeliveryModal, setShowScheduleDeliveryModal] = useState(false);
  const [scheduleDeliveryProcess, setScheduleDeliveryProcess] = useState(Process.SchedulePickup);
  const [currentStep, setCurrentStep] = useState(0);

  // time & deadline variables
  const currentTime = new Date();
  const currentDay = currentTime.getDay();
  const currentTimestamp = currentTime.getTime();
  const deliveryLocation = dummyData.state.australiaPost;
  const open = deliveryLocation.hours[currentDay].open ? deliveryLocation.hours[currentDay].open.split(':') : false;
  const openTimestamp = new Date(currentTimestamp).setHours(open[0], open[1], 0, 0);
  const close = deliveryLocation.hours[currentDay].close ? deliveryLocation.hours[currentDay].close.split(':') : false;
  const closeTimestamp = new Date(currentTimestamp).setHours(close[0], close[1], 0, 0);
  const deadline = deliveryLocation.deadline.split(':');
  const deadlineTimestamp = new Date(currentTimestamp).setHours(deadline[0], deadline[1], 0, 0);

  // update subcategory when category changes
  useEffect(() => {
    setSubcategory(defaultSubcategory(category));
  }, [ category ])

  // init rolling order sync
  const refreshInterval = 1000 * 60 * 2;
  const pingInterval = 1000 * 20;

  const safeToRefresh = (): boolean => {
    // don't refresh again if already refreshing
    const notRefreshing = ordersKey === refreshKey;

    // don't refresh if user is currently searching
    const notSearching = searchInput === '' && query === '';

    // don't refresh if any modals or dropdowns are open
    const noModals = !showScheduleDeliveryModal;
    const noTileModals = !tileModalOpen;

    return (
      notRefreshing &&
      notSearching &&
      noModals &&
      noTileModals
    );
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const timeSinceLast = new Date().getTime() - lastSync;
      if (timeSinceLast > refreshInterval && safeToRefresh()) {
        apiHelper.getCurrentUser().then(response => {
          // console.log('checking user session and refreshing orders')
          if (response.status === 200) {
            // console.log('refreshing!')
            refreshOrders();
          } else {
            onLogout();
            navigate('/logout/auto');
          }
        });
      }
    }, pingInterval);
    return () => clearInterval(interval);
  }, [
    // deps must include all props referenced in safeToRefresh
    lastSync,
    ordersKey,
    refreshKey,
    query,
    searchInput,
    showScheduleDeliveryModal,
    tileModalOpen
  ])
  
  useEffect(() => {
    if (ordersKey === refreshKey) {
      setLastSync(new Date().getTime());
    }
  }, [ ordersKey, refreshKey ])

  // computed vars
  const showCountdown = (): boolean => {
    return australiaPostStatus() !== DeliveryLocationStatus.Closed;
  }

  const finaliseManifestLabel = (): string => {
    // return australiaPostStatus() === DeliveryLocationStatus.PickupAvailable
    return subcategory === Subcategory.ManifestPickup
      ? 'Finalise Australia Post Pickup'
      : 'Finalise Australia Post Drop Off';
  }

  const finaliseManifestHandler = (): Function => {
    // return australiaPostStatus() === DeliveryLocationStatus.PickupAvailable
    return subcategory === Subcategory.ManifestPickup
      ? handleSchedulePickup
      : handleOrganiseDropoff;
  }

  const finaliseManifestOrders = (): IOrders => {
    if (!orders) return [];
    return filteredSortedOrders(orders, category, undefined, subcategory, undefined, dummyData.state.useDummyData);
  };

  const finaliseManifestDisabled = (): boolean => {
    return false;
  }

  const australiaPostStatus = (): DeliveryLocationStatus => {
    // return DeliveryLocationStatus.PickupAvailable;
    // return DeliveryLocationStatus.DropoffAvailable;

    if (deliveryLocation.hours.open === false) {
      return DeliveryLocationStatus.Closed;
    } else if (currentTimestamp < openTimestamp || currentTimestamp > closeTimestamp) {
      return DeliveryLocationStatus.Closed;
    } else if (currentTimestamp < deadlineTimestamp && !dummyData.state.australiaPost.pickupScheduled) {
      return DeliveryLocationStatus.PickupAvailable;
    } else {
      return DeliveryLocationStatus.DropoffAvailable;
    }
  }

  const countdownDeadline = (): Date => {
    switch (australiaPostStatus()) {
      case DeliveryLocationStatus.PickupAvailable:
        return new Date(deadlineTimestamp);
      case DeliveryLocationStatus.DropoffAvailable:
        return new Date(closeTimestamp);
      default:
        return new Date();
    }
  }

  // methods
  const refreshOrders = (): void => {
    if (!dummyData.state.useDummyData) {
      setRefreshKey(refreshKey + 1);
    }
  }

  const handlePrintAllPickLists = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'printable_visible'
  })

  const handleSchedulePickup = (): void => {
    setScheduleDeliveryProcess(Process.SchedulePickup);
    setShowScheduleDeliveryModal(true);
  }

  const handleOrganiseDropoff = (): void => {
    setScheduleDeliveryProcess(Process.OrganiseDropoff);
    setShowScheduleDeliveryModal(true);
  }

  // const handleViewCurrentManifest = (): void => {
  //   console.log('todo: handle view current manifest');
  // }

  const handleViewManifestHistory = (): void => {
    navigate('/shipments')
  }

  const handleBack = (): void => {
    const prevStep = currentStep - 1;
    if (prevStep >= 0) {
      setCurrentStep(prevStep);
    } else {
      setShowScheduleDeliveryModal(false);
    }
  }

  const handleNext = (): void => {
    const nextStep = currentStep + 1;
    if (nextStep < processSteps[scheduleDeliveryProcess as keyof typeof processSteps].length) setCurrentStep(nextStep);
  }

  const handleClose = (): void => {
    setShowScheduleDeliveryModal(false);
    handleStepReset();
  }

  const handleStepReset = (): void => {
    setCurrentStep(0);
  }


  return (
    <>
      {!loggedin || !location ? 
        <Navigate to="/login" />
      : 
        <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
          <Heading heading={location.name} label="You are dispatching from:" showChangeLocation onSelectLocation={onSelectLocation} showRefreshOrders onRefresh={refreshOrders} refreshKey={refreshKey} ordersKey={ordersKey} />
          <Search setSearchInput={setSearchInput} setQuery={setQuery} setCategory={setCategory} setSubcategory={setSubcategory} orders={orders} />
          <Dashboard category={category} subcategory={subcategory} query={query} setCategory={setCategory} setSubcategory={setSubcategory} orders={orders} />

          {(orders !== null && orders.length !== 0 && orders[0] !== null) &&
            <>
              {/* additional controls for Open Orders */}
              <StyledOpenOrdersUI className={category === 'open' && filteredSortedOrders(orders, category, query, undefined, undefined, dummyData.state.useDummyData).length !== 0 ? '' : 'hidden'}>
                <Button type={ButtonType.Primary} text="Print All Pick Lists" colour={ButtonColour.Green} icon={ButtonIcon.Print} small onClick={handlePrintAllPickLists} />
                <div className="printable_hidden" ref={printRef}>
                  {filteredSortedOrders(orders, category, query, undefined, undefined, dummyData.state.useDummyData).map((order: any, i: number) => {
                      if (order.is_visible === true)
                      return (
                        <PickList order={order} key={`pickList_${i}`} />
                      );
                    else
                      return null;
                  })}
                </div>
              </StyledOpenOrdersUI>

              {/* additional controls for Awaiting Delivery */}
              <StyledAwaitingDeliveryUI className={`${category === Category.RequiresManifest ? '' : 'hidden'} ${showCountdown() ? '' : 'noTimer'}`}>
                {/* {showCountdown() &&
                  <Countdown type={CountdownType.Dashboard} pickupAvailable={australiaPostStatus() === DeliveryLocationStatus.PickupAvailable} deadlineDate={countdownDeadline()} colour={dummyData.state.australiaPost.dropoffOrganised ? CountdownColour.Positive : CountdownColour.Important} />
                } */}
                <Button type={ButtonType.Primary} text={finaliseManifestLabel()} onClick={finaliseManifestHandler()} disabled={finaliseManifestDisabled()} />

                <Modal show={showScheduleDeliveryModal}>
                  <ScheduleDeliveryModal user={user} category={category} subcategory={subcategory} process={scheduleDeliveryProcess} steps={processSteps[scheduleDeliveryProcess as keyof typeof processSteps]} currentStepIndex={currentStep} orders={orders} manifestOrders={finaliseManifestOrders()} setOrders={setOrders} location={location} next={handleNext} back={handleBack} onComplete={handleClose} onRefresh={refreshOrders} />
                </Modal>
              </StyledAwaitingDeliveryUI>
            </>
          }
          <Orders user={user} location={location} closeTimestamp={closeTimestamp} partnerLocations={partnerLocations} query={query} category={category} subcategory={subcategory} orders={orders} setOrders={setOrders} refreshKey={refreshKey} onRefresh={refreshOrders} ordersKey={ordersKey} setOrdersKey={setOrdersKey} setTileModalOpen={setTileModalOpen} />
        </Body>
      }
    </>
  );
}

const StyledAwaitingDeliveryUI = styled.div`
  /* height: 334px;
  &.noTimer {
    height: 212px;
  } */

  .Countdown {
    margin-bottom: 25px;
  }
  .Button_primary {
    /* margin-bottom: 23px; */
  }
  /* .Button_secondary {
    margin-bottom: 15px;
  } */
  /* last margin on bottom of div */
  margin-bottom: 25px;
  padding-bottom: 6px;

  transition: opacity ${transitions.default},
              height ${transitions.default},
              padding ${transitions.default},
              margin ${transitions.default};

  &.hidden {
    opacity: 0;
    height: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    /* margin-bottom: -25px; */
    pointer-events: none;
  }
`

const StyledOpenOrdersUI = styled.div`
  margin: 40px 0 25px 0;
  padding-bottom: 6px;
  transition: opacity ${transitions.default},
              height ${transitions.default},
              padding ${transitions.default},
              margin ${transitions.default};

  &.hidden {
    opacity: 0;
    height: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    pointer-events: none;
  }
`