import { FunctionComponent, ChangeEventHandler, FocusEventHandler } from 'react';
import styled from 'styled-components';


type TextAreaProps = {
  id?: string
  label?: string
  placeholder?: string
  value?: any
  maxLength?: number
  small?: boolean
  onChange?: ChangeEventHandler
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
}

export const TextArea: FunctionComponent<TextAreaProps> = ({ id, label, placeholder, value, maxLength, small, onChange, onFocus, onBlur }) => {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <StyledTextArea
        id={id}
        placeholder={placeholder}
        value={value || ''}
        maxLength={maxLength}
        className={small ? 'small' : ''}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </>
  );
}

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 257px;
  margin: 8px 0 32px 0;
  padding: 13px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  resize: none;

  &:active, &:focus-visible {
    border: 2px solid black;
    padding: 12px;
    outline: none;
  }

  &::placeholder {
    color: #D4D4D4;
  }

  &.small {
    height: 73px;
  }
`