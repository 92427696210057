import { FunctionComponent } from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';


type HeadingProps = {
  heading: string
  subheading?: string
  label?: string
  showChangeLocation?: boolean
  onSelectLocation?: Function
  showRefreshOrders?: boolean
  onRefresh?: Function
  refreshKey?: any
  ordersKey?: any
}

export const Heading: FunctionComponent<HeadingProps> = ({ heading, subheading, label, showChangeLocation, onSelectLocation, showRefreshOrders, onRefresh, refreshKey, ordersKey}) => {
  const handleRefresh = (): void => {
    if (onRefresh && refreshKey === ordersKey) onRefresh();
  }
  return (
    <StyledHeading>
      {heading.length ? 
        <header className="Heading">
          <div className="Heading_flexContainer">
            {label && <p className="Heading_label">{label}</p>}
            <div className="Heading_controls">
              {showChangeLocation && <NavLink className="Heading_changeLocation" to="/select-store" onClick={() => onSelectLocation!(null)}>Change dispatch store</NavLink>}
              {(showChangeLocation && showRefreshOrders) && <span className="Heading_controlsDivider"> | </span>}
              {showRefreshOrders && <span className="link" onClick={handleRefresh}>{refreshKey === ordersKey ? 'Sync new orders' : 'Syncing orders...'}</span>}
            </div>
          </div>
          <h2>{heading}</h2>
          {subheading && <p>{subheading}</p>}
        </header>
      : null }
    </StyledHeading>
  );
}

const StyledHeading = styled.div`
  .Heading_label {
    margin: 0;
  }

  h2 {
    margin: 3px 0 0 0;
    line-height: 109%;
  }

  a {
    padding: 8px;
    margin-right: -8px;
  }

  .Heading_flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .Heading_controls {
      a, span, .link {
        display: inline;
        text-align: right;
        min-width: fit-content;
        font-size: 0.625rem; // 10px
        padding-top: 4px;
        padding-bottom: 4px;
        text-decoration: underline;
        cursor: pointer;
      }

      span.Heading_controlsDivider {
        text-decoration: none;
        cursor: default;
      }
    }
  }
`