import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IOrder, OrderDeliveryType } from '../../types/IOrders';
import { formatOrderAddress } from '../../helpers/utils';

import phoneIcon from '../../assets/images/icons/Phone.svg';
import mailIcon from '../../assets/images/icons/Mail.svg';
import pinIcon from '../../assets/images/icons/Pin.svg';


type DetailItem = {
  label: string
  content: string
}

type CustomerDetailsProps = {
  order: IOrder
  name?: boolean
  email?: boolean
  phone?: boolean
  address?: boolean
}

export const CustomerDetails: FunctionComponent<CustomerDetailsProps> = ({ order, name, email, phone, address }) => {
  
  const detailItems = (): Array<DetailItem> => {
    return [
      ... name ? [{
        label: 'Full Name',
        content: `${order.delivery_firstname} ${order.delivery_lastname}`
      }] : [],
      ... email ? [{
        label: 'Email',
        content: order.delivery_email
      }] : [],
      ... phone ? [{
        label: 'Phone Number',
        content: order.delivery_type_code === OrderDeliveryType.Pickup ? order.billing_phone : order.delivery_phone
      }] : [],
      ... address ? [{
        label: 'Address',
        content: formatOrderAddress(order, 'delivery')
      }] : [],
    ]
  }
  
  return (
    <StyledCustomerDetails>
      <div className="CustomerDetails_table">
        {detailItems().map((item: any, i: number) => {
          return (
            <div className="row" key={`customerDetails-${i}`}>
              <p className="bold">{item.label}</p>
              <p className="medium">{item.content}</p>
            </div>
          )
        })}
      </div>
    </StyledCustomerDetails>
  );
}

CustomerDetails.defaultProps = {
  name: true,
  email: true,
  phone: true,
  address: true
}

const StyledCustomerDetails = styled.div`
  .CustomerDetails_table {
    .row {
      display: grid;
      grid-template-columns: 150px 1fr;

      &:nth-child(even) {
        background-color: #F8F8F8;
      }

      p {
        margin: 0;
        padding: 10px 7px;
        
        &:not(:last-child) {
          border-right: 3px solid black;
          margin-right: 12px;
        }
      }
    }
  }
`