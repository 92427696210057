import { IConfig } from "../../types/IConfig";
import { Helpers } from "../../helpers/helpers";
const helpers = new Helpers();


let Config: IConfig = {};

/**
 * Version
 */
const version: string = '0.2.0';
Config.version = version;

/**
 * Terms & Conditions
 */
Config.terms = {};
Config.terms.version = 0.1;


/**
 * Order Types
 */
Config.orders = {}
Config.orders.types = {}
Config.orders.types.feed = 'feed';

/**
 * Backend API
 */
Config.api = {};
Config.api.backend = {};
Config.api.backend.errorCode = helpers.getEndpointErrorCode();
Config.api.backend.messages = {};
Config.api.backend.auth = {};
Config.api.backend.users = {};
Config.api.backend.orders = {};
Config.api.backend.orderNotes = {};
Config.api.backend.orderPickups = {};
Config.api.backend.locations = {};
Config.api.backend.refunds = {};
Config.api.backend.deliveries = {};
Config.api.backend.deliveryParcels = {};
Config.api.backend.deliveryBatch = {};
Config.api.backend.deliveryBatches = {};
Config.api.backend.deliveryBatches.document = {};
Config.api.backend.notifications = {};



// GET BASE URL
Config.api.backend.baseUrl = {
  endpoint: helpers.buildEndpointUrl(null),
    endpoint_short: '',
  methods: [],
};

// -------------------------------------------------------- AUTHENTICATION

// REGISTER USER
Config.api.backend.auth.register = {
    endpoint: helpers.buildEndpointUrl('auth/register'),
    endpoint_short: '/auth/register',
    methods: ['POST'],
};

// LOGIN USER
Config.api.backend.auth.login = {
    endpoint: helpers.buildEndpointUrl('auth/login'),
    endpoint_short: '/auth/login',
    methods: ['POST'],
};

// LOGOUT USER
Config.api.backend.auth.logout = {
    endpoint: helpers.buildEndpointUrl('auth/logout'),
    endpoint_short: '/auth/logout',
    methods: ['GET'],
};

// GET CURRENT USER
Config.api.backend.auth.current = {
    endpoint: helpers.buildEndpointUrl('auth/current'),
    endpoint_short: '/auth/current',
    methods: ['GET'],
};

// GET CURRENT USER
Config.api.backend.auth.integrations = {
    endpoint: helpers.buildEndpointUrl('auth/integrations'),
    endpoint_short: '/auth/integrations',
    methods: ['GET'],
};

// ------------------------------------------------------------------ USERS

// GET ALL USERS
Config.api.backend.users.getAll = {
    endpoint: helpers.buildEndpointUrl('users'),
    endpoint_short: '/users',
    methods: ['GET'],
};

// GET USER
Config.api.backend.users.get = {
    endpoint: helpers.buildEndpointUrl('users/'),
    endpoint_short: '/users/',
    methods: ['GET'],
};

// SEARCH USERS -- DEPRECATED
Config.api.backend.users.search = {
    endpoint: helpers.buildEndpointUrl('users/search'),
    endpoint_short: '/users/search',
    methods: ['POST'],
};

// CREATE USER -- DEPRECATED
Config.api.backend.users.create = {
    endpoint: helpers.buildEndpointUrl('users'),
    endpoint_short: '/users',
    methods: ['POST'],
};

// UPDATE USER
Config.api.backend.users.update = {
    endpoint: helpers.buildEndpointUrl('users/'),
    endpoint_short: '/users/',
    methods: ['PUT'],
};

// DELETE USER
Config.api.backend.users.delete = {
    endpoint: helpers.buildEndpointUrl('users/'),
    endpoint_short: '/users/',
    methods: ['DELETE'],
};

// ---------------------------------------------------------------- ORDERS

// CREATE ORDER
Config.api.backend.orders.create = {
    endpoint: helpers.buildEndpointUrl('orders'),
    endpoint_short: '/orders',
    methods: ['POST'],
};

// GET ALL ORDERS
Config.api.backend.orders.getAll = {
    endpoint: helpers.buildEndpointUrl('orders'),
    endpoint_short: '/orders',
    methods: ['GET'],
};

// GET ORDER
Config.api.backend.orders.get = {
    endpoint: helpers.buildEndpointUrl('orders/'),
    endpoint_short: '/orders/',
    methods: ['GET'],
};

// UPDATE ORDER
Config.api.backend.orders.update = {
    endpoint: helpers.buildEndpointUrl('orders/'),
    endpoint_short: '/orders/',
    methods: ['PUT'],
};

// DELETE ORDER
Config.api.backend.orders.delete = {
    endpoint: helpers.buildEndpointUrl('orders/'),
    endpoint_short: '/orders/',
    methods: ['DELETE'],
};

// GET ALL PARTNER ORDERS
Config.api.backend.orders.getAllForUser = {
    endpoint: helpers.buildEndpointUrl('orders/partners/'),
    endpoint_short: '/orders/partners/',
    methods: ['GET'],
};

// GET ALL LOCATION ORDERS
Config.api.backend.orders.getAllForLocation = {
    endpoint: helpers.buildEndpointUrl('orders/locations/'),
    endpoint_short: '/orders/locations/',
    methods: ['GET'],
};

// ---------------------------------------------------------------- ORDER NOTES

// CREATE ORDER NOTE
Config.api.backend.orderNotes.create = {
    endpoint: helpers.buildEndpointUrl('order-notes'),
    endpoint_short: '/order-notes',
    methods: ['POST'],
};

// UPDATE ORDER NOTE
Config.api.backend.orderNotes.update = {
    endpoint: helpers.buildEndpointUrl('order-notes/'),
    endpoint_short: '/order-notes/',
    methods: ['PUT'],
};

// ---------------------------------------------------------------- ORDER PICKUPS

// PROCESS PICKUP ORDER CREATION
Config.api.backend.orderPickups.new = {
  endpoint: helpers.buildEndpointUrl("orders/{id}/process/pickup/new"),
  endpoint_short: "/orders/{id}/process/pickup/new",
  methods: ["POST"],
};

// PROCESS PICKUP ORDER COMPLETION
Config.api.backend.orderPickups.complete = {
  endpoint: helpers.buildEndpointUrl("orders/{id}/process/pickup/complete"),
  endpoint_short: "/orders/{id}/process/pickup/complete",
  methods: ["POST"],
};

// ---------------------------------------------------------------- DELIVERIES

// DELIVERY CREATION
Config.api.backend.deliveries.new = {
  endpoint: helpers.buildEndpointUrl("/deliveries/orders/{id}/process/new"),
  endpoint_short: "/deliveries/orders/{id}/process/new",
  methods: ["POST"],
};

// PROCESS DELIVERY CANCELLATION
Config.api.backend.deliveries.cancel = {
  endpoint: helpers.buildEndpointUrl("/deliveries/{id}/process/cancel"),
  endpoint_short: "/deliveries/{id}/process/cancel",
  methods: ["POST"],
};

// PROCESS DELIVERY COMPLETION
Config.api.backend.deliveries.complete = {
  endpoint: helpers.buildEndpointUrl("/deliveries/{id}/process/complete"),
  endpoint_short: "/deliveries/{id}/process/complete",
  methods: ["POST"],
};

// GET COLLECTION DELIVERIES BY LOCATION
Config.api.backend.deliveries.collectionByLocation = {
  endpoint: helpers.buildEndpointUrl("/deliveries/locations/{code}/collections"),
  endpoint_short: "/deliveries/locations/{code}/collections",
  methods: ["GET"],
};

// GET DROPOFF DELIVERIES BY LOCATION
Config.api.backend.deliveries.dropoffByLocation = {
  endpoint: helpers.buildEndpointUrl("/deliveries/locations/{code}/dropoffs"),
  endpoint_short: "/deliveries/locations/{code}/dropoffs",
  methods: ["GET"],
};


// GET DELIVERIES BY ORDER ID
Config.api.backend.deliveries.byOrder = {
    endpoint: helpers.buildEndpointUrl("/deliveries/orders/{id}"),
    endpoint_short: "/deliveries/orders/{id}",
    methods: ["GET"],
};

// GET DELIVERY LABELS
Config.api.backend.deliveries.labels = {
  endpoint: helpers.buildEndpointUrl("/deliveries/{id}/get/labels"),
  endpoint_short: "/deliveries/{id}/get/labels",
  methods: ["GET"],
};

// ---------------------------------------------------------------- DELIVERY PARCELS

// DELIVERY PARCELS
Config.api.backend.deliveryParcels.get = {
  endpoint: helpers.buildEndpointUrl("/delivery-parcels"),
  endpoint_short: "/delivery-parcels",
  methods: ["GET"],
};

// ---------------------------------------------------------------- LOCATIONS

// GET LOCATION
Config.api.backend.locations.get = {
    endpoint: helpers.buildEndpointUrl('locations/'),
    endpoint_short: '/locations/',
    methods: ['GET'],
};

// GET PARTNER LOCATIONs
Config.api.backend.locations.getPartnerLocations = {
    endpoint: helpers.buildEndpointUrl('locations/partners/'),
    endpoint_short: '/locations/partners/',
    methods: ['GET'],
};

// ---------------------------------------------------------------- REFUNDS

// CREATE REFUND
Config.api.backend.refunds.create = {
    endpoint: helpers.buildEndpointUrl('refunds'),
    endpoint_short: '/refunds',
    methods: ['POST'],
};

// PROCESS ORDER REFUND
Config.api.backend.refunds.processOrderRefund = {
    endpoint: helpers.buildEndpointUrl('refunds/orders/'),
    endpoint_short: '/refunds/orders/',
    methods: ['GET'],
};

// DELIVERY BATCH API

Config.api.backend.deliveryBatch = {
  endpoint: helpers.buildEndpointUrl("/delivery-batches/{id}"),
  endpoint_short: "/delivery-batches/{id}",
  methods: ["GET"],
};

Config.api.backend.deliveryBatches = {
  endpoint: helpers.buildEndpointUrl("/delivery-batches/locations/{code}"),
  endpoint_short: "/delivery-batches/locations/{code}",
  methods: ["GET"],
};

Config.api.backend.deliveryBatches.document = {
  endpoint: helpers.buildEndpointUrl("/delivery-batches/{id}/get/document"),
  endpoint_short: "/delivery-batches/{id}/get/document",
  methods: ["GET"],
};

// PROCESS COLLECTION DELIVERIES BY LOCATION
Config.api.backend.deliveryBatches.collectionByLocation = {
  endpoint: helpers.buildEndpointUrl("/delivery-batches/locations/{code}/process/collections"),
  endpoint_short: "/delivery-batches/locations/{code}/process/collections",
  methods: ["POST"],
};

// PROCESS DROPOFF DELIVERIES BY LOCATION
Config.api.backend.deliveryBatches.dropoffByLocation = {
  endpoint: helpers.buildEndpointUrl("/delivery-batches/locations/{code}/process/dropoffs"),
  endpoint_short: "/delivery-batches/locations/{code}/process/dropoffs",
  methods: ["POST"],
};

// GET OUTSTANDING MESSAGES
Config.api.backend.messages = {
  endpoint: helpers.buildEndpointUrl("/messages/get/outstanding"),
  endpoint_short: "/messages/get/outstanding",
  methods: ["GET"],
};

// PROCESS MESSAGES AS ACTIONED
Config.api.backend.messages.actioned = {
  endpoint: helpers.buildEndpointUrl("/messages/{id}/process/actioned"),
  endpoint_short: "/messages/{id}/process/actioned",
  methods: ["PUT"],
};

// -------------------------------------------------------- NOTIFICATIONS

Config.api.backend.notifications.send = {
  endpoint: helpers.buildEndpointUrl("/notifications/send"),
  endpoint_short: "/notifications/send",
  methods: ["POST"],
};

export { Config };
