import { IOrder, IOrders, OrderStatus, DeliveryModelCode, OrderDeliveryType, OrderUrgency } from "../types/IOrders";
import { ILocation, LocationCode } from "../types/ILocations";

import { Category, Subcategory } from "../types/DashboardCategories";
import { Timestamp } from "../types/Timestamp";
import { useDummyData } from "../context/DummyDataProvider";

interface IOrderTemp extends IOrder {
  parsedDate?: Date
}

/**
 * clamp a number between minimum and maximum values
 * 
 * @param num number
 * @param min number | undefined
 * @param max number | undefined
 * @returns number
 */
export function clamp(num: number, min: number | undefined, max: number | undefined): number {
  return Math.min(Math.max(num, min || num), max || num);
}

/**
 * generate a random number between minimum and maximum values
 * 
 * @param min number
 * @param max number
 * @returns number
 */
export function randomRange(min: number, max: number): number {
  return (Math.random() * (max - min) + min);
}

const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD'
});

/**
 * format a price in dollars as an en-AU string
 * 
 * @param price number
 * @returns string
 */
export function formatPrice(price: number | string): string {
  let priceNum = typeof price === 'number'
    ? price
    : parseFloat(price);
  return formatter.format(priceNum);
}

/**
 * format an Order's delivery or billing address
 * 
 * @param order IOrder
 * @param addressType 'delivery' | 'billing'
 * @returns string
 */
export function formatOrderAddress(order: IOrder, addressType: 'delivery' | 'billing' = 'delivery'): string {
  switch (addressType) {
    case 'delivery':
      return `${JSON.parse(order.delivery_street).join(' ')}, ${order.delivery_city}, ${order.delivery_state_code}, ${order.delivery_postcode}`;
    case 'billing':
      return `${JSON.parse(order.billing_street).join(' ')}, ${order.billing_city}, ${order.billing_state_code}, ${order.billing_postcode}`;
  }
}

/**
 * format a Location's address
 * 
 * @param location ILocation
 * @returns string
 */
export function formatLocationAddress(location: ILocation): string {
  return `${JSON.parse(location.address.street || '').join(' ')}, ${location.address.city}, ${location.address.region_code}, ${location.address.postcode}`;
}

/**
 * create a Date object from a Timestamp
 * 
 * @param timestamp Timestamp
 * @returns Date
 */
export function parseTimestamp(timestamp: Timestamp): Date {
  return new Date(Date.parse(timestamp + 'Z'));
}

/**
 * generate a correctly formatted Timestamp ISO string from a Date
 * 
 * @param date Date
 * @returns Timestamp
 */
export function formatTimestampISO(date: Date): Timestamp {
  const dateAsISO = new Date(date).toISOString();
  // return dateAsISO;

  // formatting fix for backend parsing bug
  // trim off millis and Z
  return dateAsISO.substring(0, 19);
}

/**
 * format a Timestamp string for display
 * 
 * @param timestamp Timestamp
 * @param mode 'date' | 'time' | 'time12' | 'datetime'
 * @returns string
 */
export function formatTimestamp(timestamp: Timestamp, mode: 'date' | 'time' | 'time12' | 'datetime' = 'date'): string {
  const date = parseTimestamp(timestamp);
  switch (mode) {
    case 'date':
      return date.toLocaleDateString('en-AU');
    case 'time':
      return date.toLocaleTimeString('en-AU', { hour12: false });
    case 'time12':
      return date.toLocaleTimeString('en-AU', { hour12: true, hour: 'numeric', minute:'2-digit' });
    case 'datetime':
      return date.toLocaleString('en-AU', { hour12: false }).replace(',', '');
  }
}

/**
 * generate a correctly formatted Timestamp string for the current time
 * 
 * @returns Timestamp
 */
export function getCurrentTimestamp(): Timestamp {
  return formatTimestampISO(new Date());
}

/**
 * return number of items in the Order
 * 
 * @param order IOrder
 * @returns Timestamp
 */
export function getNumberItems(order: IOrder): number {
  return order.items.reduce((acc, item) => {
    return acc + item.qty_ordered;
  }, 0);
}

/**
 * determine if an order has been fully refunded
 * 
 * @param order IOrder
 * @returns boolean
 */
export function isFullRefund(order: IOrder): boolean {
  if (order.status_code !== OrderStatus.Refunded) {
    console.warn(`Order ${order.id} was checked for full refund, but status_code !== ${OrderStatus.Refunded}`);
    return false;
  }

  // start with refund_adjustment and refund_delivery
  let refundTotal = parseFloat(order.refund_adjustment) + parseFloat(order.refund_delivery);

  // console.log(order);
  // console.log('refund_adjustment', order.refund_adjustment);
  // console.log('refund_delivery', order.refund_delivery);
  
  // add item quantities refunded
  order.items.forEach(item => {
    refundTotal += item.total * item.qty_refunded;
    // console.log('item.id', item.id);
    // console.log('total', item.total);
    // console.log('qty_ordered', item.qty_ordered);
    // console.log('qty_shipped', item.qty_shipped);
    // console.log('qty_refunded', item.qty_refunded);
  })
  
  // final comparison
  // console.log('order total', order.total);
  // console.log('refund total', refundTotal);
  return refundTotal === parseFloat(order.total);
}

/**
 * get courier location details
 * 
 * @returns any
 */
export function getCourierLocationByStore(locationCode: LocationCode): any {
  switch (locationCode) {
    case 'cw-broadway':
      return {
        name: 'Broadway Post Shop',
        phone: '(02) 9207 7989',
        address: 'The Broadway Shopping Centre, Shop 21g 1-21 Bay Street, Glebe, NSW, 2037',
      }
    case 'cw-clinicboolaroo':
      return {
        name: 'Boolaroo LPO',
        phone: '(02) 4028 2307',
        address: '29 Main Road, Boolaroo, NSW, 2284',
      }
    case 'cw-edmondsonpark':
      return {
        name: 'Ingleburn Post Shop',
        phone: '(02) 4632 9001',
        address: '34 Oxford Road, Ingleburn, NSW, 2565',
      }
    case 'cw-glendale':
      return {
        name: 'Glendale Post Shop',
        phone: '(02) 4028 2307',
        address: 'Glendale Shopping Centre, Shop 1 387 Lake Road, Glendale, NSW, 2285',
      }
    case 'cw-wetherillpark':
      return {
        name: 'Wetherill Park LPO',
        phone: '(02) 4729 8630',
        address: 'Stockland Shopping Centre, Shop 255 561-583 Polding Street, Prairiewood, NSW, 2176',
      }
    default:
      console.warn('Must provide a supported LocationCode.')
      break;
  }
}

/**
 * filter an array of orders by query from search bar
 * 
 * @param orders IOrders
 * @param query string
 * @returns IOrders
 */
// 
export function filterOrdersByQuery(orders: IOrders, query: string): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));

  const queryFragments = query.split(' ');
  mutatedOrders = mutatedOrders.filter(order => {
    const searchableFields = [
      order.platform_order_no,
      order.delivery_firstname,
      order.delivery_lastname,
      order.delivery_email,
      order.delivery_phone,
      order.billing_phone,
    ].join(' ').toLowerCase();
    return queryFragments.every(fragment => searchableFields.includes(fragment.toLowerCase()));
  });

  return mutatedOrders;
}

/**
 * filter an array of orders by category from dashboard
 * 
 * @param orders IOrders
 * @param category Category
 * @returns IOrders
 */
// 
export function filterOrdersByCategory(orders: IOrders, category: Category, useDummyData?: boolean): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));
  
  mutatedOrders = mutatedOrders.filter(order => {
    switch (category) {
      case Category.OpenOrders:
        return [
          OrderStatus.New,
          OrderStatus.Fraud,
          OrderStatus.AwaitingPayment
        ].includes(order.status_code);

      case Category.RequiresManifest:
        return [
          OrderStatus.AwaitingProcessing
        ].includes(order.status_code);

      case Category.DispatchCollection:
        if (useDummyData) {
          return [
            OrderStatus.AwaitingCourier,
            OrderStatus.AwaitingDropoff,
            OrderStatus.AwaitingPickup,
          ].includes(order.status_code)
        } else {
          // temp fix until scheduled manifest sync is implemented
          return [
            OrderStatus.AwaitingPickup
          ].includes(order.status_code) ||
          ([
            OrderDeliveryType.Custom,
          ].includes(order.delivery_type_code) && order.status_code === OrderStatus.AwaitingCourier);
        }

      case Category.ClosedOrders:
        if (useDummyData) {
          return [
            OrderStatus.Complete,
            OrderStatus.Cancelled,
            OrderStatus.Refunded,
            OrderStatus.Archived
          ].includes(order.status_code)
        } else {
          // temp fix until scheduled manifest sync is implemented
          return [
            OrderStatus.Complete,
            OrderStatus.Cancelled,
            OrderStatus.Refunded,
            OrderStatus.Archived,
            OrderStatus.AwaitingDropoff,
          ].includes(order.status_code) ||
          ([
            OrderDeliveryType.Custom,
          ].includes(order.delivery_type_code) === false && order.status_code === OrderStatus.AwaitingCourier);
        }

      default:
        return false
    }
  });

  return mutatedOrders;
}

/**
 * get dashboard category for order
 * 
 * @param order IOrder
 * @returns Category
 */
// 
export function getOrderCategory(order: IOrder, useDummyData?: boolean): Category {
  let result = Category.OpenOrders;

  if ([OrderStatus.New, OrderStatus.Fraud, OrderStatus.AwaitingPayment].includes(order.status_code)) {
    return Category.OpenOrders;
  }
  
  if ([OrderStatus.AwaitingProcessing].includes(order.status_code)) {
    return Category.RequiresManifest;
  }

  if (useDummyData) {
    if ([OrderStatus.AwaitingCourier, OrderStatus.AwaitingDropoff, OrderStatus.AwaitingPickup].includes(order.status_code)) {
      return Category.DispatchCollection;
    }
  } else {
    // temp fix until scheduled manifest sync is implemented
    if (
      [OrderStatus.AwaitingPickup].includes(order.status_code) ||
      ([OrderDeliveryType.Custom].includes(order.delivery_type_code) && order.status_code === OrderStatus.AwaitingCourier)
    ) {
      return Category.DispatchCollection;
    }
  }

  if (useDummyData) {
    if ([OrderStatus.Complete, OrderStatus.Cancelled, OrderStatus.Refunded, OrderStatus.Archived].includes(order.status_code)) {
      return Category.ClosedOrders;
    }
  } else {
    // temp fix until scheduled manifest sync is implemented
    if (
      [
        OrderStatus.Complete,
        OrderStatus.Cancelled,
        OrderStatus.Refunded,
        OrderStatus.Archived,
        OrderStatus.AwaitingCourier,
        OrderStatus.AwaitingDropoff
      ].includes(order.status_code) &&
      ([OrderDeliveryType.Custom].includes(order.delivery_type_code) === false && order.status_code === OrderStatus.AwaitingCourier)
    ) {
      return Category.ClosedOrders;
    }
  }

  return result;
}

/**
 * filter an array of orders by subcategory
 * 
 * @param orders IOrders
 * @param subcategory Subcategory
 * @returns IOrders
 */
// 
export function filterOrdersBySubcategory(orders: IOrders, subcategory: Subcategory): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));
  mutatedOrders = mutatedOrders.filter(order => {
    switch (subcategory) {
      // open
      case Subcategory.IncomingOrders:
        return order.status_code === OrderStatus.New;
      case Subcategory.FraudOrders:
        return order.status_code === OrderStatus.Fraud; 
      case Subcategory.PendingPayment:
        return order.status_code === OrderStatus.AwaitingPayment; 
      // requires manifest
      case Subcategory.ManifestPickup:
        return order.status_code === OrderStatus.AwaitingProcessing && order.delivery_model_code === DeliveryModelCode.Pickup; 
      case Subcategory.ManifestDropoff:
        return order.status_code === OrderStatus.AwaitingProcessing && order.delivery_model_code === DeliveryModelCode.Dropoff;
      case Subcategory.DispatchPickup:
        return order.status_code === OrderStatus.AwaitingCourier; 
      case Subcategory.DispatchDropoff:
        return order.status_code === OrderStatus.AwaitingDropoff; 
      case Subcategory.DispatchCollection:
        return order.status_code === OrderStatus.AwaitingPickup; 
      // closed
      case Subcategory.CompletedOrders:
        // return order.status_code === OrderStatus.Complete; 
        return [
          OrderStatus.Complete,
          OrderStatus.Archived,
          OrderStatus.AwaitingCourier,
          OrderStatus.AwaitingDropoff
        ].includes(order.status_code);
      case Subcategory.CancelledOrders:
        return order.status_code === OrderStatus.Cancelled; 
      case Subcategory.PartialRefundOrders:
        return order.status_code === OrderStatus.Refunded && isFullRefund(order) === false; 
      case Subcategory.RefundOrders:
        return order.status_code === OrderStatus.Refunded && isFullRefund(order); 

      // case Subcategory.RequiresAction:
      //   return order.status_code === OrderStatus.AwaitingProcessing;
      // case Subcategory.DispatchOrganised:
      //   return order.status_code === OrderStatus.AwaitingDropoff
      //     || order.status_code === OrderStatus.AwaitingCourier;
      
          default:
        return false
    }
  });

  return mutatedOrders;
}

/**
 * get dashboard subcategory for order
 * 
 * @param order IOrder
 * @returns Subcategory
 */
// 
export function getOrderSubcategory(order: IOrder): Subcategory | null {
  let result = null;

  // open
  if (order.status_code === OrderStatus.New) {
    result = Subcategory.IncomingOrders;
  } else if (order.status_code === OrderStatus.Fraud) {
    result = Subcategory.FraudOrders;
  } else if (order.status_code === OrderStatus.AwaitingPayment) {
    result = Subcategory.PendingPayment;

  // requires manifest
  } else if (order.status_code === OrderStatus.AwaitingProcessing && order.delivery_model_code === DeliveryModelCode.Pickup) {
    result = Subcategory.ManifestPickup;
  } else if (order.status_code === OrderStatus.AwaitingProcessing && order.delivery_model_code === DeliveryModelCode.Dropoff) {
    result = Subcategory.ManifestDropoff;

  // dispatch & collection
  } else if (order.status_code === OrderStatus.AwaitingCourier) {
    result = Subcategory.DispatchPickup;
  } else if (order.status_code === OrderStatus.AwaitingDropoff) {
    result = Subcategory.DispatchDropoff;
  } else if (order.status_code === OrderStatus.AwaitingPickup) {
    result = Subcategory.DispatchCollection;

  // closed
  } else if (
    // order.status_code === OrderStatus.Complete; 
    [
      OrderStatus.Complete,
      OrderStatus.Archived,
      OrderStatus.AwaitingCourier,
      OrderStatus.AwaitingDropoff
    ].includes(order.status_code)
  ) {
    result = Subcategory.CompletedOrders;
  } else if (order.status_code === OrderStatus.Cancelled) {
    result = Subcategory.CancelledOrders;
  } else if (order.status_code === OrderStatus.Refunded && isFullRefund(order) === false) {
    result = Subcategory.PartialRefundOrders;
  } else if (order.status_code === OrderStatus.Refunded && isFullRefund(order)) {
    result = Subcategory.RefundOrders;
  }

  return result;
}

/**
 * filter an array of orders by query from search bar and category from dashboard
 * 
 * @param orders IOrders
 * @param category Category | undefined
 * @param query string | undefined
 * @param subcategory Subcategory | undefined
 * @returns IOrders
 */
// 
export function filterOrders(orders: IOrders, category: Category | undefined, query: string | undefined, subcategory?: Subcategory, useDummyData?: boolean): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));
  mutatedOrders = mutatedOrders.filter(order => order.is_visible !== false);
  if (category) mutatedOrders = filterOrdersByCategory(mutatedOrders, category, useDummyData);
  if (subcategory) mutatedOrders = filterOrdersBySubcategory(mutatedOrders, subcategory);
  if (query) mutatedOrders = filterOrdersByQuery(mutatedOrders, query);
  return mutatedOrders;
}

/**
 * sort an array of orders by specified fields
 * currently supports 'deliveryType', 'created', 'ordered', 'updated', 'closed', 'urgent' fields and 'reverse' modifier
 * eg. ['deliveryType', 'created'] or ['updated-reverse']
 * 
 * @param orders IOrders
 * @param sortBy Array<string>
 * @returns IOrders
 */
// 
export function sortOrders(orders: IOrders, sortBy: Array<string>, closeTimestamp?: number): IOrders {
  let mutatedOrders: Array<IOrderTemp> = JSON.parse(JSON.stringify(orders));
  
  sortBy.reverse().forEach(sortType => {
    // sort by specified type
    if (sortType.includes('created')) {
      // sort by date created (default oldest first)
      mutatedOrders.forEach(order => {
        order.parsedDate = parseTimestamp(order.created_at);
      })
      mutatedOrders = mutatedOrders.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    } else if (sortType.includes('ordered')) {
      mutatedOrders.forEach(order => {
        order.parsedDate = parseTimestamp(order.created_at);
      })
      mutatedOrders = mutatedOrders.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    } else if (sortType.includes('updated')) {
      // sort by date updated (default oldest first) -- (falls back to date created)
      mutatedOrders.forEach(order => {
        if (order.updated_at) {
          order.parsedDate = parseTimestamp(order.updated_at);
        } else {
          order.parsedDate = parseTimestamp(order.created_at);
        }
      })
      mutatedOrders = mutatedOrders.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    } else if (sortType.includes('completed')) {
      // sort by date completed (default oldest first) -- (falls back to date created)
      mutatedOrders.forEach(order => {
        if (order.completed_at) {
          order.parsedDate = parseTimestamp(order.completed_at);
        } else {
          order.parsedDate = parseTimestamp(order.created_at);
        }
      })
      mutatedOrders = mutatedOrders.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    } else if (sortType.includes('deliveryType')) {
      // sort by delivery type (default order below)
      const types = ['express', 'same_day', 'pickup', 'standard'];
      mutatedOrders = mutatedOrders.sort((a, b) => {
        if (types.indexOf(a.delivery_type_code) < types.indexOf(b.delivery_type_code)) {
          return -1;
        }
        if (types.indexOf(a.delivery_type_code) > types.indexOf(b.delivery_type_code)) {
          return 1;
        }
        return 0;
      })
    } else if (sortType.includes('sameday')) {
      // sort sameday (doordash) orders to top
      mutatedOrders = mutatedOrders.sort((a, b) => {
        if (a.delivery_type_code === OrderDeliveryType.SameDay && b.delivery_type_code !== OrderDeliveryType.SameDay) {
          return -1;
        }
        if (a.delivery_type_code !== OrderDeliveryType.SameDay && b.delivery_type_code === OrderDeliveryType.SameDay) {
          return 1;
        }
        return 0;
      })
    } else if (sortType.includes('overdue')) {
      // sort by overdue urgency
      const types = [OrderUrgency.Overdue, OrderUrgency.Standard];
      mutatedOrders = mutatedOrders.sort((a, b) => {
        let urgencyA = getOrderUrgency(a, closeTimestamp);
        let urgencyB = getOrderUrgency(b, closeTimestamp);
        if (urgencyA === OrderUrgency.Warning) urgencyA = OrderUrgency.Standard;
        if (urgencyB === OrderUrgency.Warning) urgencyB = OrderUrgency.Standard;

        if (types.indexOf(urgencyA) < types.indexOf(urgencyB)) {
          return -1;
        }
        if (types.indexOf(urgencyA) > types.indexOf(urgencyB)) {
          return 1;
        }
        return 0;
      })
    }

    // reverse if necessary
    if (sortType.includes('reverse')) {
      mutatedOrders.reverse();
    }
  })

  return mutatedOrders as IOrders;
}

/**
 * filter an array of orders by query from search bar and category from dashboard
 * and sort by date (oldest first) and delivery type
 * 
 * @param orders IOrders
 * @param category Category | undefined
 * @param query string | undefined
 * @param subcategory Subcategory | undefined
 * @returns IOrders
 */
// 
export function filteredSortedOrders(orders: IOrders, category: Category | undefined, query: string | undefined, subcategory?: Subcategory, closeTimestamp?: number, useDummyData?: boolean): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));
  mutatedOrders = filterOrders(mutatedOrders, category, query, subcategory, useDummyData);
  // unique sort order for some categories
  let sortBy = ['sameday', 'overdue', 'ordered'];
  if (category === Category.ClosedOrders) sortBy = ['completed-reverse'];
  if (category === Category.DispatchCollection) sortBy = ['updated-reverse'];
  if (category === Category.RequiresManifest) sortBy = ['updated-reverse'];
  mutatedOrders = sortOrders(mutatedOrders, sortBy, closeTimestamp);
  return mutatedOrders;
}

/**
 * return urgency status of an order based on delivery type
 * 
 * @param order IOrder
 * @returns OrderUrgency
 */
// 
const hour = 1000 * 60 * 60;
export function getOrderUrgency(order: IOrder, closeTimestamp?: number): OrderUrgency {
  const timeAgoOrdered = new Date().getTime() - parseTimestamp(order.created_at).getTime();
  const timeUntilClose = closeTimestamp ? closeTimestamp - new Date().getTime() : null;
  
  // orders that have already been processed are always Standard urgency
  if (
    [
      OrderStatus.New,
      OrderStatus.AwaitingProcessing,
      OrderStatus.AwaitingPickup,
      // OrderStatus.AwaitingCourier,
      // OrderStatus.AwaitingProcessing,
    ].includes(order.status_code) === false
  ) {
    return OrderUrgency.Standard;
  }
  
  // Standard delivery
  if (order.delivery_type_code === OrderDeliveryType.Standard) {
    if (timeAgoOrdered > (48 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (36 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }
  
  // Express delivery
  if (order.delivery_type_code === OrderDeliveryType.Express) {
    if (timeAgoOrdered > (24 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (8 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }
  
  // Same-Day delivery
  if (order.delivery_type_code === OrderDeliveryType.SameDay) {
    if (timeAgoOrdered > (0.75 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (0.33 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }

  // Click & Collect
  if (order.delivery_type_code === OrderDeliveryType.Pickup) {
    if (timeAgoOrdered > (24 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (4 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }

  // Custom courier
  if (order.delivery_type_code === OrderDeliveryType.Custom) {
    if (timeAgoOrdered > (48 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (24 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }

  // default case
  return OrderUrgency.Standard;
}