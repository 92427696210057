import { FunctionComponent } from 'react';

import { IOrder } from '../../types/IOrders';
// import { Category } from '../../types/DashboardCategories';
import { useDummyData } from '../../context/DummyDataProvider';
import { getOrderCategory } from '../../helpers/utils';

import { TextTag } from '../TextTag/TextTag';


type CategoryTagProps = {
  order: IOrder
} 

const categoryLabel = {
  // 'open': 'Open Orders',
  // 'overdue': 'Overdue Orders',
  // 'awaiting_delivery': 'Awaiting Courier Pickup',
  // 'awaiting_pickup': 'Awaiting Click & Collect',
  // 'awaiting_payment': 'Awaiting Payment',
  // 'complete': 'Completed Orders',
  // 'cancelled': 'Cancelled/Refunded Orders',
  // 'refunded': 'Cancelled/Refunded Orders',
  'open': 'Open Orders',
  'manifest': 'Requires Manifest',
  'dispatch': 'Dispatch & Collection',
  'closed': 'Closed Orders',
};

export const CategoryTag: FunctionComponent<CategoryTagProps> = ({ order }) => {
  const dummyData = useDummyData();
  return (
    <TextTag text={`In ${categoryLabel[getOrderCategory(order, dummyData.state.useDummyData) as keyof typeof categoryLabel]}`} />
  );
}