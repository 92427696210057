import { FunctionComponent } from 'react';
import { Location, Link } from 'react-router-dom'
import styled from 'styled-components';
import { boxShadows, transitions } from '../../assets/css/variables';

import helpIcon from '../../assets/images/icons/Help.svg';

type HelpButtonProps = {
  text?: string
  location: Location
}

export const HelpButton: FunctionComponent<HelpButtonProps> = ({ text, location }) => {
  const handleClick = (): void => {
    console.log(`todo: link to help for ${location.pathname}`);
  }

  return (
    <StyledHelpButton className="HelpButton_container">
      <div className="HelpButton_button" onClick={handleClick}>
        <a className='bold' href="https://apt-support.freshdesk.com/support/tickets/new" rel="noreferrer" target="_blank">
          <div className= "Help_button_holder">
              <img src={helpIcon} alt="Request Help" />
              <p className="bold ">{text}</p>
          </div>
        </a>
      </div>
    </StyledHelpButton>
  );
}

HelpButton.defaultProps = {
  text: 'Help'
}

const StyledHelpButton = styled.div`
  position: sticky;
  bottom: 30px;

  .HelpButton_button {
    margin: -42px 37px 0 auto;
    
    width: fit-content;
    height: 42px;

    display: flex;
    align-items: center;
    gap: 11px;

    background: black;
    border-radius: 29px 6px 20px 29px;
    padding: 11px 17px 11px 11px;
    ${boxShadows.default}
    cursor: pointer;
    user-select: none;

    transition:
      box-shadow ${transitions.default},
      transform ${transitions.default},
      opacity ${transitions.default};

    &:hover {
      ${boxShadows.hover}
    }

    &:active:not(:disabled) {
      transform: scale(0.975);
    }

    img {
      filter: invert();
    }

    p {
      color: white;
      margin: 0;
    }
    .Help_button_holder {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Help_button_holder > p {
      margin-left: 10px;
    }
  }
`