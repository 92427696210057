import { ChangeEventHandler, FunctionComponent } from 'react';
import { boxShadows, transitions } from '../../assets/css/variables';
import styled from 'styled-components';

import { SelectionGroupInputType } from '../SelectionGroup/SelectionGroup';

import tickIcon from '../../assets/images/icons/TickWhite.svg';


type SelectionButtonProps = {
  type: SelectionGroupInputType
  selected: boolean
  id: string
  value: string
  label?: string
  subtitle?: string
  price?: string
  compressed?: boolean
  deliveryList?: boolean
  checkbox?: boolean
  disabled?: boolean
  imageSrc?: string
  iconSrc?: string
  colour?: string
  largeLabels?: boolean
  onChange?: Function
}

export const SelectionButton: FunctionComponent<SelectionButtonProps> = ({ type, selected, id, value, label, subtitle, price, compressed, deliveryList, checkbox, disabled, imageSrc, iconSrc, colour, largeLabels, onChange }) => {
  const handleClick = (): void => {
    if (onChange) onChange(value);
  }

  return (
    <StyledSelectionButton
      className={`SelectionButton ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''} ${compressed ? 'compressed' : ''} ${checkbox ? 'checkbox' : ''} ${deliveryList ? 'deliveryList' : ''} ${largeLabels ? 'largeLabels' : ''}`}
      style={{ backgroundImage: `url(${imageSrc})` }}
      colour={colour}
      onClick={handleClick}
    >
      <input type={type} id={id} name={id} value={value} checked={selected} onChange={onChange as ChangeEventHandler} style={{ backgroundImage: `url(${tickIcon})` }}/>
      {iconSrc && <img src={iconSrc} alt="" draggable="false" />}
      <div className="SelectionButton_labelContainer">
        <label className="bold" htmlFor={id}>{label}</label>
        {subtitle && <p className="SelectionButton_subtitle bold">{subtitle}</p>}
      </div>
      {deliveryList && <p className="SelectionButton_price bold">{price}</p>}
    </StyledSelectionButton>
  );
}

const StyledSelectionButton = styled.div<{ colour: string | undefined }>`
  position: relative;
  width: 100%;
  height: 128px;
  border-radius: 6px;
  border: 1px solid #9B9B9B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
  ${boxShadows.default}
  transition: box-shadow ${transitions.default};

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  * {
    cursor: pointer;
  }

  &.compressed {
    height: unset;
    min-height: 55px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    .SelectionButton_labelContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    label {
      line-height: 103%;
      margin-bottom: 2px;
    }

    p.SelectionButton_subtitle {
      margin: 0;
    }

    img {
      top: 15px;
      left: unset;
      right: 23px;
    }

    input {
      position: relative;
      top: unset;
      right: unset;
      margin-left: 14px;
      margin-right: 12px;
      min-width: 18px;
    }

    &.selected input {
      margin-left: 13px;
      margin-right: 12px;
    }

    &:active input {
      margin-left: 13px;
      margin-right: 12px;
    }
  }

  &.deliveryList {
    height: 71px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    gap: 18px;
    border: none !important;
    ${props => handleColour(props.colour)};
    box-shadow: 0 1px 5px 0 rgba(180, 180, 180, 0.25);
    
    &:hover {
      box-shadow: 0 4px 5px 0 rgba(180, 180, 180, 0.25);
    }

    input {
      position: relative;
      top: unset;
      right: unset;
      margin-left: 14px !important;
      margin-right: 0;
      min-width: 18px;
    }

    img {
      position: relative;
      margin: auto 0;
      width: 93px;
      height: auto;
      max-height: 20px;
    }

    label {
      line-height: 125%;
      font-size: 0.75rem; // 12px
    }

    p.SelectionButton_subtitle {
      line-height: 125%;
      font-size: 0.75rem; // 12px
      font-family: "Mulish Medium" !important;
    }

    p.SelectionButton_price {
      line-height: 125%;
      font-size: 1rem; // 16px
      margin-left: auto;
      margin-right: 20px;
    }
  }
  
  &.checkbox {
    height: 48px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    border: none !important;
    box-shadow: none !important;

    label {
      line-height: 103%;
      font-size: 0.75rem; // 12px
      font-family: "Mulish Medium";
    }

    img {
      top: 15px;
      left: unset;
      right: 23px;
    }

    input {
      position: relative;
      top: unset;
      right: unset;
      margin-left: 14px;
      margin-right: 12px;
      min-width: 18px;
    }

    &.selected input {
      margin-left: 14px;
      margin-right: 12px;
    }

    &:active input {
      margin-left: 14px;
      margin-right: 12px;
    }
  }
  
  label {
    font-size: 1rem; // 16px
    line-height: 164%;
    pointer-events: none;
    white-space: pre-wrap;
    display: inline-block;
  }

  p {
    font-size: 0.75rem; // 12px
    line-height: 164%;
    margin: 3px 0 5px 0;
    white-space: pre-wrap;
  }

  img {
    /* margin-bottom: 18px; */
    margin-bottom: 5px;
  }

  input {
    position: absolute;
    top: 13px;
    right: 12px;
    width: 18px;
    height: 18px;
    margin: 1px;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color ${transitions.fast}, border-color ${transitions.default};
    pointer-events: none;
  }

  input[type=checkbox] {
    appearance: none;
    border: 1px solid #9B9B9B;
    border-radius: 4px;
  }

  input[type=radio] {
    appearance: none;
    border: 1px solid #9B9B9B;
    border-radius: 100%;
  }

  &:hover {
    ${boxShadows.hover}

    input {
      border: 1px solid black;
    }
  }

  &:active {
    border: 2px solid black;
    outline: none;

    input {
      border: 1px solid black;
      margin: 0;
    }
  }
  
  &.selected {
    border: 2px solid black;
    outline: none;

    input {
      border: 1px solid black;
      background-color: black;
      margin: 0;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.largeLabels {
    label {
      font-size: 1.375rem; // 22px
    }
  }
`

const handleColour = (colour: string | undefined): string | null => {
  if (colour && colour !== '') {
    return `border-left: 10px solid ${colour} !important`;
  } else {
    return null;
  }
};